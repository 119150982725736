import { LocalStorageService } from "../services/local-storage.service";


// TODO(shashank): move its dependent method into angular service
const localStorageService = new LocalStorageService();

export const showAllComics = (): boolean => {
  const flag = localStorageService.getItem('showAllComics');
  if (flag == null) {
    return true;
  }
  if (typeof flag === 'string') {
    return flag === 'true';
  }
  return flag;
}

// TODO(shashank): need to move this to angular service, so no errors in SSR
export const isAnonymousUser = (): boolean => {
  return !(localStorageService.getItem('isAnonymousUser') == false);
}
// convert 'the-daily-show' => 'The Daily Show'
export function convertToTitleCase(input: string): string {
  // Split the input string by dashes
  const words = input && input.split('-');

  // Capitalize each word
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words with spaces
  const result = capitalizedWords.join(' ');

  return result;
}

export function creatorViewTable(data) {
  return {
    col1: {
      displayValue: data.date && data.date.displayValue
    },
    col2: {
      displayValue: data.seriesPageViews && data.seriesPageViews.total.displayValue
    },
    col3: {
      displayValue: data.seriesPageViews && data.seriesPageViews.web.displayValue
    },
    col4: {
      displayValue: data.seriesPageViews && data.seriesPageViews.ios.displayValue
    },
    col5: {
      displayValue: data.seriesPageViews && data.seriesPageViews.android.displayValue
    },
    col6: {
      displayValue: data.seriesPercentage && data.seriesPercentage.displayValue
    },
    col7: {
      displayValue: data.pageViewsRevenue && data.pageViewsRevenue.displayValue
    },
    col8: {
      displayValue: data.virtualGifts && data.virtualGifts.displayValue
    },
    col9: {
      displayValue: data.seriesRevenue && data.seriesRevenue.displayValue
    },
    virtualGifts: data.virtualGifts,
  }
}

export function referalViewTable(data, isTotal = false) {
  if (data.referralAmt.value === 0 && !isTotal) return;
  return {
    col1: {
      displayValue: data.date && data.date.displayValue
    },
    col2: {
      displayValue: data.referralCount.displayValue
    },
    col3: {
      displayValue: data.referralAmt.displayValue
    }
  }
}

export function topReferrerViewTable(data, isTotal = false) {
  if (data.referralAmt.value === 0 && !isTotal) return;
  return {
    col1: {
      displayValue: data.referrerName
    },
    col6: {
      displayValue: data.referralCount.displayValue
    },
    col7: {
      displayValue: data.referralAmt.displayValue
    }
  }
}

export function totalsDashboardViewTable(data) {
  return {
    col1: {
      displayValue: data.matrixName
    },
    col6: {
      displayValue: data.total.displayValue
    }
  }
}

export function maskEmail(email: string): string {
  const parts = email.split('@');
  const username = parts[0];
  const domain = parts[1];
  let maskedUsername: string;

  switch (username.length) {
    case 3:
      maskedUsername = username.charAt(0) + '**';
      break;
    case 2:
      maskedUsername = username.charAt(0) + '*';
      break;
    case 1:
      maskedUsername = '*';
      break;
    default:
      maskedUsername = username.charAt(0) + '**' + username.charAt(username.length - 1);
      break;
  }

  return maskedUsername + '@' + domain;
}

export function camelToHyphen(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function isComicTimeRecent(comicTime, freeHours) {
  const currentTime = new Date();
  const comicDate = new Date(comicTime);
  const timeDifference = Number(currentTime) - Number(comicDate);

  // Convert freeHours to milliseconds
  const freeHoursInMs = freeHours * 60 * 60 * 1000;

  return timeDifference <= freeHoursInMs && timeDifference >= 0;
}