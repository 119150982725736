<div class="container">
  <div class="row">
    <div class="col-6">
      <img [src]="getImagePath('COMIC_HEADER')"/>
    </div>
    <div class="col-6">
      <img [src]="getImagePath('CONTENT_PLACEHOLDER')"/>
    </div>
  </div>
</div>
