<!-- OPEN IN APP -->
<div class="open-in-app p-2 justify-content-between" *ngIf="topMessage">
    <span class="toast-text body-mini">{{topMessage}}</span>
    <a (click)="topMessage=''"><img class="toast-text-png" [src]="getImagePath('ICONS8_DELETE')"
            alt="x" width="12px" height="12px"></a>
</div>
<div class="full-height-mobile">
    <!-- Conditionally designed block to handle SIGNIN/SIGNUP workflows -->
    <div class="tv-card">
        <div>
            <!-- This redirectionString is used to tell user to signin to prevent subscription loss purchased in GU mode -->
            <div *ngIf="redirectionString" class="redirection-text">{{ redirectionString }} </div>

            <!-- This is a conditional title block depending on SIGNIN/Phone Verification flow -->
            <h1 class="mb-4">{{ currentConfig.screen.title }}</h1>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
