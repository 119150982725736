import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../services/session-storage.service';
import { influencePointsURL, MANAGE_ALERTS, signInPhoneURL, signUpURL, subscribeURL } from '../constants/common.constants';
import { ModalConfig } from '../interfaces/user.interface';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FollowModalComponent } from '../components/follow-modal/follow-modal.component';
import { getImage } from '../constants/images.constants';

@Component({
  selector: 'app-action-sheet-modal',
  templateUrl: './action-sheet-modal.component.html',
  styleUrls: ['./action-sheet-modal.component.scss']
})
export class ActionSheetModalComponent implements OnInit {
  @Input() modalConfig: ModalConfig;
  @Output() close = new EventEmitter();

  public isComicUnlocking: boolean;
  public currentUrl: string;
  public influencePointsBalance: any;
  modalRef: any;

  constructor(
    private comicService: ComicService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
  }

  public action(action: string) {
    switch (action) {
      case 'subscribe':
        this.navigateAndCloseModal(subscribeURL);
        break;
      case 'earnInfluencePoints':
        this.navigateAndCloseModal(influencePointsURL);
        break;
      case 'redirectToSignUp':
        this.navigateAndCloseModal(signUpURL);
        break;
      case 'redirectToSignIn':
        this.navigateAndCloseModal(signInPhoneURL);
        break;
      case 'useInfluencePoints':
        this.unlockComic(action);
        break;
      case 'follow':
        this.followCurrentSeries(action);
        break;
      case 'restoreSubscription':
        // Waiting on BE for this functionality
        break;
      case 'alerts-setting':
        this.navigateAndCloseModal(MANAGE_ALERTS);
        break;
      case 'closeModal':
        this.navigateAndCloseModal();
        break;
    }
  }

  private changeBtnLoaderState(action: string, state: boolean): void {
    this.modalConfig.buttons = this.modalConfig.buttons.map(btn => {
      if (btn.action === action) {
        btn.loading = state;
      }
      return btn;
    });
  }

  private navigateAndCloseModal(path?: string): void {
    if (path) {
      let queryParams = path === signInPhoneURL ? { comic: this.currentUrl } : {}
      if (path === MANAGE_ALERTS) {
        queryParams['series'] = JSON.stringify(this.modalConfig.series);
      }
      this.router.navigate([path], { queryParams });
    }
    this.close.emit();
  }

  async unlockComic(action?: string, actionType?: string) {
    if (!this.modalConfig.modalData.influencePointsBalance) return;

    let pointsToBeCharged = 1; // Defaulting to 1, i.e. bonus panel unlock points
    if (!this.modalConfig.unlockBonusPanel) {
      pointsToBeCharged = 5;
    }

    this.changeBtnLoaderState(action, true);
    await this.comicService.unlockComic(`${this.currentUrl}/index.json`, pointsToBeCharged);
    if (this.modalConfig.unlockBonusPanel) {
      this.toastr.success(`You've used 1 influence point to unlock this comic.`);
    } else {
      this.toastr.success(`You’ve used 5 influence points to unlock this comic.`);
    }
    const urls = this.sessionStorageService.getItem('unlockedURLs') || [];
    urls.push(`${this.currentUrl}/index.json`);
    this.sessionStorageService.setItem('unlockedURLs', urls);
    setTimeout(() => {
      this.navigateAndCloseModal();
      this.changeBtnLoaderState(action, false);
      window.location.reload();
    }, 4000);
  }

  async followCurrentSeries(action) {
    this.changeBtnLoaderState(action, true);
    const followResult = await this.comicService.addRemoveLike(`${this.modalConfig.series.action}`, true, false, { showToast: false, series: this.modalConfig.series });
    this.changeBtnLoaderState(action, false);
    this.close.emit();
    if (followResult.success) {
      this.toastr.success(`Thanks for following ${this.modalConfig.series.title}! You can read bonus panels of ${this.modalConfig.series.title} now.`);
      setTimeout(() => {
        this.navigateAndCloseModal();
        this.changeBtnLoaderState(action, false);
        window.location.reload();
      }, 3000);
    } else if (followResult.openFollowModal) {
      this.openFollowModal();
    }
  }

  openFollowModal() {
    if (this.modalRef) this.modalRef.hide();

    const initialState = {
      tinyviewPage: false,
      isFollowingAlready: false,
      series: this.modalConfig.series,
      hasBonusPanel: true
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
