import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, Inject, PLATFORM_ID, TemplateRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { signInPhoneURL, signUpURL, subscribeURL } from '../constants/common.constants';
import { AuthService } from '../services/auth.service';
import { ComicService } from '../services/comic.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as firebase from 'firebase';
import { getImage } from '../constants/images.constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  @Output() toggleMenu = new EventEmitter<MouseEvent>();
  @Output() openInviteFriends = new EventEmitter<MouseEvent>();
  @Input() userName: string;
  @Input() isIncompleteProfile: string;
  @Input() userBadges: string;
  @Input() userImage: string;
  @Input() userPhoneNumber: string;
  @Input() subDetails: any;
  @Input() isSubscribed: any;
  modalRef: BsModalRef;
  showSeries: boolean = false;
  urlSubscription: Subscription
  loggedIn: boolean = false;
  canEdit: boolean = true;
  canAdd: boolean = this.comicService.getCurrentUrl() != '/' && this.comicService.getCurrentUrl() != '/tinyview/comic-series-directory' ? true : false;
  userPhoneNum: string;
  admins: any = [];
  isAdmin: boolean;
  comicTitle: any = [];
  tinyviewAdmin: boolean = false;
  isAnalysts: boolean = false;
  currentDevice: string;
  UID: string;
  fetchDone: boolean = true;
  currentUrl: string;
  enableHome: string;
  hasLatestComic: boolean = false;
  showCreateList: boolean = false;
  showLegalList: boolean = false;
  isCover: any;
  modalLinkRef: BsModalRef;
  showProfileList: boolean = false;
  subscribeURL = subscribeURL;
  showFriendMenu: any;
  userEmailName: string;
  showSettingsMenu: boolean;
  showAlerts: boolean;
  isSeriesCreator: boolean;
  seriesName: string;
  phoneNumber: string;
  manageSubscriptionObj: any;

  constructor(
    private comicService: ComicService,
    private afAuth: AngularFireAuth,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platform: object,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isCover = this.comicService.isCoverPage();

    const user = firebase.auth().currentUser;
    this.UID = user ? user.uid : null;
    // check for signin
    const isSignedInUser = this.authService.isSignedInUser(user);
    if (isSignedInUser) {
      this.loggedIn = true;
      this.phoneNumber = user.phoneNumber;
      this.userPhoneNum = user.phoneNumber ? user.phoneNumber.substring(user.phoneNumber.length - 4) : null;
      this.userEmailName = user.email;
    } else {
      this.loggedIn = false;
    }

    this.isUserPermitted();
    this.urlSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // TODO: Check the user access here.
        this.canAdd = this.comicService.getCurrentUrl() != '/' && this.comicService.getCurrentUrl() != '/tinyview/comic-series-directory' ? true : false;
      }
    });
  }

  public goToHome() {
    this.router.navigate(['']);
  }

  onImageError(event: Event) {
    (event.target as HTMLImageElement).src = getImage('MALE_USER');
  }

  public isUserPermitted() {
    if (isPlatformBrowser(this.platform)) {
      this.comicService.getComicTitle().subscribe(res => {
        for (var key in res) {
          if (res[key].roles) {
            res[key].roles.map((i) => {
              if (i.admins && i) {
                i.admins.map((admin) => {
                  this.admins.push({ 'admin': admin, 'title': key })
                })
              }
            });
          }
        }
        if (this.admins.length > 0) {
          const user = firebase.auth().currentUser;
          for (let value of res.tinyview.roles) {
            if (value.analysts && value.analysts.length > 0) {
              for (let item = 0; item < value.analysts.length; item++) {
                if (user) {
                  if (user.phoneNumber == value.analysts[item]) {
                    this.isAnalysts = true;
                    this.localStorageService.setItem('isTinyviewAnalyst', JSON.stringify(this.isAnalysts));
                    break;
                  }
                }
              };
            }
          }
          for (var data = 0; data < this.admins.length; data++) {
            if (user && this.admins[data]) {
              if (user.phoneNumber == this.admins[data].admin) {
                this.comicTitle.push(this.admins[data].title)
                if (this.admins[data].title !== 'tinyview') {
                  this.isSeriesCreator = true;
                  this.localStorageService.setItem('isSeriesCreator', JSON.stringify(this.isSeriesCreator));
                  if (this.isAdmin) continue;
                  if ((location.pathname.split('/').slice(0, 2).join('/').replace(/\//g, '') == this.admins[data].title || location.pathname.replace(/\//g, '') == this.admins[data].title || (this.seriesName == this.admins[data].title))) {
                    this.isAdmin = true;
                    this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                  } else {
                    this.isAdmin = false;
                    this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                  }
                } else if (location.pathname.split('/').slice(0, 2).join('/').replace(/\//g, "") == this.admins[data].title || location.pathname.replace(/\//g, "") == this.admins[data].title) {
                  this.tinyviewAdmin = false;
                  this.isAnalysts = false;
                  this.isAdmin = true;
                  this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                  break;
                } else {
                  this.isAdmin = false;
                  this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                }
              }
              else {
                this.isAdmin = false;
                this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
              }
            }
          }
        }
      });
    }
  }

  public toggleSeries(): void {
    this.showSeries = !this.showSeries;
  }

  async onEdit(newComic: boolean = false, showModal) {
    let comic = this.comicService.getCurrentUrl();
    if (newComic) {
      this.comicService.getComicChapters(this.currentUrl, '')
        .subscribe(async (resp) => {
          resp.comics.panels.map(data => {
            if (data.title == "Latest Comics") {
              this.hasLatestComic = true;
              if (comic != '/tinyview/comic-series-directory') {
                this.router.navigate(['/edit', { comic: comic }], { queryParams: { new_comic: newComic } });
              }
            }
          });
          if (!this.hasLatestComic) {
            this.hasLatestComic = false;
            this.openModal(showModal)
          }
        })
    } else {
      if (comic != '/tinyview/comic-series-directory') {
        this.router.navigate(['/edit', { comic: comic }], { queryParams: { new_comic: newComic } });
      }
    }
  }

  public async manageSubscription() {
    switch (this.subDetails && this.subDetails.store.toLowerCase()) {
      case "stripe":
        try {
          const url = await this.comicService.createPortalLink();
          if (url) {
            this.manageSubscriptionObj = { url: url, target: "_blank" };
          }
        } catch (error) {
          console.log(error);  
        }
        break;
      case "apple":
      case "ios":
        this.manageSubscriptionObj = { url: "https://apps.apple.com/account/subscriptions", target: "_blank" };
        break;
      case "android":
      case "google":
        this.manageSubscriptionObj = { url: "https://play.google.com/store/account/subscriptions", target: "_blank" };
        break;
      default:
        this.manageSubscriptionObj = { url: "tinyview/subscribe", target: "_self", internalRedirection: true };
        this.isSubscribed = false;
        break;
    }
    if (this.manageSubscriptionObj && this.manageSubscriptionObj.url && this.manageSubscriptionObj.target) {
      if (this.manageSubscriptionObj.internalRedirection) {
        this.router.navigate([this.manageSubscriptionObj.url]);
        return;
      }
      window.open(this.manageSubscriptionObj.url, this.manageSubscriptionObj.target);
    }
  }

  public showCreateSection() {
    this.showCreateList = !this.showCreateList;
  }

  public showLegalSection() {
    this.showLegalList = !this.showLegalList;
  }

  public showFriendSection() {
    this.showFriendMenu = !this.showFriendMenu;
  }

  public showProfileSection() {
    this.showProfileList = !this.showProfileList;
  }

  public showSettingsSection() {
    this.showSettingsMenu = !this.showSettingsMenu;
  }

  public showManageAlerts() {
    this.router.navigate(['/manage-alerts']);
  }

  signIn(): void {
    let comic = this.comicService.getCurrentUrl();
    this.comicService.cacheComic[""] = null;
    if (comic.indexOf('signin') > -1) {
      comic = '';
    }
    this.router.navigate([signInPhoneURL], { queryParams: { comic: comic } });
  }

  public async signOut() {
    this.comicService.clearUserData();
    await this.authService.signOut();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }

  openLinkModal(template: TemplateRef<any>) {
    this.modalLinkRef = this.modalService.show(template, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }


  public getBadgeURL(badgeName: string) {
    return this.comicService.mapUserBadge(badgeName, 50);
  }

  ngOnDestroy() {
    this.urlSubscription.unsubscribe();
  }

  public openInviteFriendsModal() {
    this.openInviteFriends.emit();
  }

  public navigateToProfileCompletion() {
    this.router.navigate(['/edit-profile']);
    return;
  }

  signUp(): void {
    let comic = this.comicService.getCurrentUrl();
    this.comicService.cacheComic[""] = null;
    this.router.navigate([signUpURL], { queryParams: { comic: comic } });
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  onImgError(e) {
    e.target.src = this.getImagePath('MALE_USER');
  }
}
