import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PostActions from '../redux/action';
import { Post } from '../redux/model';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ComicService } from '../services/comic.service';

interface AppState {
  post: Post;
}

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss', '../comic/comic.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  currentDevice: string;
  isIpad: boolean;

  constructor(
    private store: Store<AppState>,
    private comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private meta: Meta) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (width == 1024) {
        this.isIpad = true;
      }
    }
    this.currentDevice = this.comicService.getOperatingSystem();
  }

}
