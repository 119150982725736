import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_KEYS } from '../constants/common.constants';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	constructor(
		private apiService: ApiService
	) { }

	async logEvent(category: string, label: string, data?: any, uniqueFlowID?: number) {
		try {
			const eventData: any = {
				uniqueFlowID,
				eventCategory: category,
				eventLabel: label,
				data: this.trimArrayFields(data) || {},
				timestamp: new Date().toISOString(),  // Adding a timestamp for uniqueness
			};

			if (navigator) {
				eventData.userAgent = navigator.userAgent || navigator.vendor;
			}

			console.log('Event Data:', eventData);

			const addLog = await this.apiService.send(API_KEYS.ADD_LOG);
			await addLog(eventData);
			return true;
		} catch (error) {
			console.log('loggerService - error:', error);
			return false;
		}
	}

	// Function to recursively trim arrays and return the updated object
	trimArrayFields(obj: any): any {
		// Check if the input is an array
		if (Array.isArray(obj)) {
			// If the array has more than 10 elements, trim it
			return obj.length > 10 ? obj.slice(0, 10) : obj;
		}

		// If the input is an object, recursively process its fields
		if (typeof obj === 'object' && obj !== null) {
			const result = {};
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					result[key] = this.trimArrayFields(obj[key]);
				}
			}
			return result;
		}

		// If it's neither an array nor an object, return it as is
		return obj;
	}
}