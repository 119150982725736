<ng-container *ngIf="!errorMsg">
  <div class="section-container" [ngClass]="{ 'story-page-height': storyPage && isMobileView }">

    <div class="tv-columns" *ngIf="!isLoadingHeader">
          <!-- OPEN IN APP -->
    <!-- Encourage to Subscribe -->
    <app-top-banner *ngIf="currentSeriesDetails.action && currentSeriesDetails.title" [followBtnText]="followBtnText" [hasFollowBtn]="hasFollowBtn" [hasBonusPanel]="hasBonusPanel" [series]="currentSeriesDetails" (onPropertyChange)="onBannerChange($event)" [isTopBanner]="true" [showTo]="resp?.comics && resp?.comics['show-to']?.key"></app-top-banner>

    <!-- Follow Series Banner-->
    <!-- <app-follow-banner [isTopBanner]="true" [hasAnyChannel]="hasAnyChannel" [seriesName]="seriesName" [seriesTitle]="seriesTitle" *ngIf="!showNotifyForSubscribe && !(this.isMobileView  && isOpenApp)" [likeCount]="likeCount" [followBtnText]="followBtnText" (onPropertyChange)="onBannerChange($event)"></app-follow-banner> -->
      <!-- For Header of Home, Following, Series, Comic -->
      <div *ngIf="!storyPage" class="comic-desc-container" [ngClass]="{'comic-desc-comic-page': isComicPage}">
        <img [src]="panelService.getImgUrl(resp && resp.comics && resp.comics.ogImage, currentUrl)" onError="getImagePath('PREVIEW_IMAGE_NOT_AVAILABLE')">
        <!-- NEW CARD -->
        <div class="tv-card pt-4" *ngIf="resp && !isComicPage">
          <!-- SERIES, COMIC IMAGE -->
          <img class="series-profile" [class.cursor-pointer]="currentUrl !== '/tinyview'" src="{{this.seriesImage || 'https://cdn.tinyview.com/tinyview/tinyview-profile.jpg'}}" alt="Loading..."
            (click)="(currentUrl !== '/tinyview' && !isSeriesPage) ? navigateTo('/tinyview') : null">
          <!-- TITLE -->
          <div class="d-flex justify-content-between" [class.mb-3]="isHomePage">
            <a>
              <h1 *ngIf="resp.comics && resp.comics.title && !storyPage" class="my-auto" (click)="isDirectoryPage ? navigateTo('/tinyview') : null">
                {{ !isDirectoryPage && !isSubscribePage  ? resp.comics && resp.comics.title : 'Tinyview'}}
              </h1>
            </a>
            <span (click)="addRemoveLike()" class="my-auto">
              <a *ngIf="followBtnText" class="d-flex justify-content-start align-items-center shadow-on-hover btn" [ngClass]="{'tv-btn-light': followBtnText === 'Following', 'tv-btn-red': followBtnText === 'Follow'}">
                <div class="loader-bar-btn mx-4" *ngIf="showLoader"></div>
                <img width="16px" *ngIf="!showLoader" height="16px" class="mr-1" src="{{ followBtnText === 'Following' ? getImagePath('ICONS8_CHECKMARK_60') : getImagePath('ICONS8_PLUS_MATH_50') }}">
                <p *ngIf="!showLoader" [ngClass]="{'text-white': followBtnText === 'Follow'}">{{followBtnText}}</p>
              </a>
            </span>
          </div>
          <h2 class="mb-2 mt-3" *ngIf="resp.comics && resp.comics.title && !storyPage && (isDirectoryPage || isSubscribePage)">
            {{ resp.comics && resp.comics.title }}
            <p class="my-2 body-mini">{{comicDate}}</p>
            <!-- <img [src]="getImagePath('ICONS8_QUOTE')" class="quotes" alt="Quotes"> -->
            <p class="comments mt-2 mb-3" [innerHTML]="comicComments | stripTags: 'br' :'p' :'i' :'b'"></p>
          </h2>
          <h1 *ngIf="resp?.data?.data?.title && storyPage">
            {{resp.data.data.title}}
          </h1>
          <!-- VIEWS & LIKES -->
          <div class="like-view-wrapper-mobile mt-3" *ngIf="!isDirectoryPage && !isSubscribePage">
            <!-- VIEWS -->
            <div class="d-flex p-bottom">
              <ng-container *ngIf="pageInfo?.data?.viewCount || viewCount">
                <span class="p-bold" *ngIf="pageInfo?.data || viewCount">{{(pageInfo?.data?.viewCount || viewCount) | numberFormat}}&nbsp;</span>
                <p> View<span *ngIf="pageInfo?.data?.viewCount != 1">s</span> </p>
              </ng-container>
              <ng-container *ngIf="pageInfo || likeCount">
                <span class="d-flex ml-3" *ngIf="(pageInfo && (pageInfo?.data?.likeCount || pageInfo?.data?.likeCount == 0)) || likeCount">
                  <span class="p-bold" *ngIf="(pageInfo && pageInfo?.data?.likeCount) && currentUrl != '' && currentUrl != '/'">
                    {{ pageInfo?.data?.likeCount | numberFormat}}
                  </span>
                  <span class="p-bold" *ngIf="likeCount && (currentUrl == '' || currentUrl == '/' || storyPage)">
                    {{likeCount | numberFormat}}
                  </span>
                  <span class="p-bold" *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 0))">0</span>
                  &nbsp;
                  <p *ngIf="isCover">Follower</p>
                  <p *ngIf="!isCover"> Like</p>
                  <p *ngIf="pageInfo?.data?.likeCount != 1">s</p>
                </span>
              </ng-container>
            </div>

            <!-- FOLLOW COMMENT SHARE -->
            <!-- <div class="header-action" *ngIf="!isSeriesPage">
              <span (click)="addRemoveLike()" >
                <div class="loader-bar-btn" *ngIf="showLoader"></div>
                <a *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                  <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? getImagePath('TICKBOX') : getImagePath('ICONS8_ADD') }}">
                  <p>{{followBtnText}}</p>
                </a>
              </span>
              <a class="d-flex justify-content-center align-items-center">
                <img class="icon-20 mr-1" [src]="getImagePath('ICONS8_COMMENT')">
                <p>Comment</p>
              </a>
            </div> -->

            <!-- DESCRIPTION -->
            <p class="pt-3 pb-1" [class.pb-3]="isSeriesPage" *ngIf="isCover"
              [innerHTML]="resp.comics && resp.comics.description | stripTags: 'br' :'p' :'i' :'b'">
            </p>

            <a (click)="openShareModal(shareModal)" *ngIf="!isSeriesPage" class="d-flex justify-content-center mt-3 py-2 align-items-center btn tv-btn-light">
              <img class="icon-20 mr-1" [src]="getImagePath('ICONS8_UPLOAD_150')">
              <p>Share</p>
            </a>
          </div>
          <app-action-bar *ngIf="(isDirectoryPage || isSubscribePage) && !storyPage && pageInfo" [comicAction]="''" [storyData]="''" [record]="record"
            [isLike]="(pageInfo.data && pageInfo.data.isLiked) || null" [commentCount]="(pageInfo.data && pageInfo.data.commentCount) || 0" [repostCount]="(pageInfo.data && pageInfo.data.repostCount) || 0"
            [viewCount]="(pageInfo.data && pageInfo.data.viewCount) || 0" [likeCount]="(pageInfo.data && pageInfo.data.likeCount) || 0" [storyID]="(pageInfo.data && pageInfo.data.storyId) || ''"
            [giftItemCount]="(pageInfo.data && pageInfo.data.giftsCount) || {}" [giftedItems]="(pageInfo.data && pageInfo.data.giftedItems) || ''" [actionType]="''" [hideCommentSection]="true"
            [desc]="comicComments || resp.comics && resp.comics.description" [img]="resp.comics && resp.comics.ogImage" [renderOnTop]="isDirectoryPage"
            [title]="(resp.comics && resp.comics.title) || (resp.data && resp.data.data && resp.data.data.title)">
          </app-action-bar>

          <!-- SOCIAL MEDIA LINKS -->
          <ng-container *ngIf="resp.comics && resp.comics.links">
            <div class="links-wrapper">
              <a [attr.href]="resp.comics && resp.comics.links && resp.comics.links.website" *ngIf="resp.comics && resp.comics.links && resp.comics.links.website">
                <button class="btn tv-btn-light col-12">
                  <p>
                    <img class="icon-20 mr-1" [src]="getImagePath('ICONS8_LINK')" alt="icon">
                    {{ webUrl }}
                  </p>
                </button>
              </a>
              <div class="social-container mt-3" *ngIf="resp.comics && resp.comics.links">
                <div *ngIf="resp.comics.links.instagram">
                  <a [attr.href]="resp.comics.links.instagram" [title]="resp.comics.links.instagram">
                    <img class="icon-25" [src]="getImagePath('ICONS8_INSTAGRAM_100_2')" alt="icon">
                  </a>
                </div>
                <div *ngIf="resp.comics.links.facebook">
                  <a [attr.href]="resp.comics.links.facebook" [title]="resp.comics.links.facebook">
                    <img class="icon-25" [src]="getImagePath('ICONS8_FACEBOOK')" alt="icon">
                  </a>
                </div>
                <div *ngIf="resp.comics.links.twitter">
                  <a [attr.href]="resp.comics.links.twitter" [title]="resp.comics.links.twitter">
                    <img class="icon-25" [src]="getImagePath('ICONS8_TWITTERX')" alt="icon">
                  </a>
                </div>
              </div>
            </div>
          </ng-container>

        </div>

        <!-- COMIC PAGE -->
        <ng-container *ngIf="fetchDone">
          <div *ngIf="isComicPage" class="tv-card">
            <div class="series-sub-block" *ngIf="seriesImage || seriesTitle">
              <a [routerLink]="seriesHomeNavigation">
                <!-- SERIES IMAGE -->
                <img class="series-profile"  *ngIf="seriesImage" [src]="seriesImage">
                <!-- <p *ngIf="!seriesImage && seriesTitle">{{getFirstLetters(seriesTitle)}}</p> -->
              </a>
            </div>

            <!-- SERIES & FOLLOW BUTTON -->
            <div class="d-flex justify-content-between">
              <h1 *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation" class="my-auto cursor-pointer">
                {{seriesTitle}}
              </h1>

              <!-- [ngClass]="followBtnText != 'Following' ? 'comic-page-btn':'follow-comic-app-btn'" -->

              <span class="my-auto"
                *ngIf="(!isCover && (currentUrl != '/tinyview/comic-series-directory')) && seriesTitle && (storyPage ? actionForStory : true)">
                <a aria-label="Open episodes" class="tv-btn-light btn shadow-on-hover"  *ngIf="!isSubscribePage && (followBtnText === 'Following')" [routerLink]="seriesHomeNavigation"> 
                  <img alt="list" width="16px" height="16px" class="mr-1 margin-bottom-2px" [src]="getImagePath('ICONS8_LIST_96')">
                 <span>Episodes</span> 
                </a>
                <span (click)="addRemoveLike()" *ngIf="isSubscribePage || (isComicPage && followBtnText === 'Follow')" class="my-auto">
                  <a *ngIf="followBtnText" class="d-flex justify-content-start align-items-center btn shadow-on-hover" [ngClass]="{'tv-btn-light': followBtnText === 'Following', 'tv-btn-red': followBtnText === 'Follow'}">
                    <div class="loader-bar-btn mx-4" *ngIf="showLoader"></div>
                    <img width="16px" *ngIf="!showLoader" height="16px" class="mr-1" src="{{ followBtnText === 'Following' ? getImagePath('ICONS8_CHECKMARK_60') : getImagePath('ICONS8_PLUS_MATH_50') }}">
                    <p *ngIf="!showLoader" [ngClass]="{'text-white': followBtnText === 'Follow'}">{{followBtnText}}</p>
                  </a>
                </span>
              </span>
            </div>
            <div class="mt-2" *ngIf="resp.comics && resp.comics.isRatingDataReady">
              <app-comic-rating [comic]="resp.comics" (showRead)="showRead($event)"></app-comic-rating>
            </div>
            <!-- TITLE -->
             <div class="d-flex justify-content-between" [class.mt-3]="!storyPage && seriesImage">
              <h2 *ngIf="resp.comics && resp.comics.title && !storyPage" class="w-90">
                {{resp.comics.title}}
              </h2>
              <h2 *ngIf="resp.data && resp.data.data && resp.data.data.title && storyPage" class="w-90">
                {{resp.data.data.title}}
              </h2>
              <div *ngIf="showDoubleTick">
                <img [src]="getImagePath('ICONS8_DOUBLE_TICK_100')" width="20px" height="20px" alt="Read">
              </div>
             </div>

            <p *ngIf="comicDate && seriesImage && !isCover" class="my-2 body-mini">{{comicDate}}</p>

            <ng-container *ngIf="isCover">
              <div class='like-view-wrapper-mobile'>
                <div *ngIf="pageInfo?.data?.viewCount && pageInfo?.data">
                  <span class="view-number" *ngIf="pageInfo?.data">{{pageInfo?.data?.viewCount |
                    numberFormat}}&nbsp;</span><br />
                  <ng-container *ngIf="pageInfo?.data?.viewCount && pageInfo?.data?.viewCount == 1 "> <span
                      class="view-text">
                      View </span>
                  </ng-container>
                  <ng-container *ngIf="pageInfo?.data?.viewCount > 1 || pageInfo?.data?.viewCount == 0"><span
                      class="view-text">
                      Views</span>
                  </ng-container>
                </div>
                <div *ngIf="pageInfo|| likeCount">
                  <div *ngIf="(pageInfo && (pageInfo?.data?.likeCount || pageInfo?.data?.likeCount == 0)) || likeCount">
                    <span class="like-number" *ngIf="(pageInfo && pageInfo?.data?.likeCount) && currentUrl != '' && currentUrl != '/'">{{
                      pageInfo?.data?.likeCount |
                      numberFormat}}</span>
                    <span class="like-number" *ngIf="likeCount && (currentUrl == '' || currentUrl == '/')">{{likeCount |
                      numberFormat}}</span>
                    <span class="like-number" *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 0))">0</span><br />
                    <ng-container *ngIf="(pageInfo && (pageInfo?.data?.likeCount == 1))">
                      <span class="follower-text" *ngIf="isCover"> Follower</span>
                      <span class="like-text" *ngIf="!isCover"> Like</span>
                    </ng-container>
                    <ng-container
                      *ngIf="(pageInfo && pageInfo?.data?.likeCount == 0)|| (pageInfo && pageInfo?.data?.likeCount > 1)">
                      <span class="like-text" *ngIf="!isCover">&nbsp; Likes</span>
                      <span class="follower-text" *ngIf="isCover"> Followers</span>
                    </ng-container>
                  </div>
                </div>
                <div>
                  <button [ngClass]="followBtnText != 'Following'?'comic-btn':'follow-app-btn'"
                    (click)="addRemoveLike()">
                  <div class="loader-bar-btn" *ngIf="showLoader"></div>
                    <span *ngIf="!showLoader">{{followBtnText}}</span>
                  </button>
                </div>
              </div>
            </ng-container>
            <div class="comments" *ngIf="resp?.data?.data?.comment && storyPage"
              [innerHTML]="resp?.data?.data?.comment | stripTags: 'br' :'p' :'i' :'b'">
            </div>
            <!-- <img *ngIf="resp?.comics?.comments && !storyPage" [src]="getImagePath('ICONS8_QUOTE')" class="quotes" alt="Quotes"> -->
            <div class="comments mt-3" [class.mb-3]="!isComicPage" *ngIf="resp.comics && resp.comics.comments && !storyPage"
              [innerHTML]="resp.comics.comments | stripTags: 'br' :'p' :'i' :'b'">
            </div>
            <app-panel-nav *ngIf="!isCover && !['/tinyview/comic-series-directory'].includes(currentUrl) && !storyPage && storyID && (!isComicPage || isSubscribePage)"
              (doLike)="doLikefromComicPage($event)" [isLiked]="isLiked2" [commentCount]="commentCount"
              [viewCount]="pageInfo?.data?.viewCount" [likeCount]="likeCount" [storyID]="storyID" [renderOnTop]="isSubscribePage"
              [giftItemCount]="giftsCounts" [giftedItems]="giftedItems" [actionType]="actionType" [hideBottomSection]="true">
            </app-panel-nav>

            <div class="page-views" *ngIf="currentUrl == '/tinyview/comic-series-directory'">
              <span class="view-number" *ngIf="pageInfo && pageInfo?.data">{{pageInfo?.data?.viewCount |
                numberFormat}}&nbsp;</span>
              <ng-container *ngIf="pageInfo?.data?.viewCount && pageInfo?.data?.viewCount == 1 "> <span
                  class="view-text">
                  View </span>
              </ng-container>
              <ng-container *ngIf="pageInfo?.data?.viewCount > 1 || pageInfo?.data?.viewCount == 0"><span
                  class="view-text">
                  Views</span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- For Body -->
      <div class="comic-panel-container" [class.pb-58]="isMobileView && !storyPage"
        [class.pb-100]="(isMobileView && (isComicPage && !isSubscribePage)) || (storyPage && isMobileView) || isHomePage" 
        [class.pb-130]="!isMobileView && isComicPage"
        [class.pb-280]="!isMobileView && storyPage"
        [ngClass]="{'comic-panel-comic-page': isComicPage, 'remove-bg-color': (isComicPage && !isSubscribePage) || isDirectoryPage}">
                <!-- 1. Subscribe Panel, for all pages except (Story Page and Subscribe Page) -->

        <!-- Progress Bar (this will call header.json and show progress from there conditionally) -->
        <ng-container *ngIf="!storyPage">
          <app-progress-bar></app-progress-bar>
        </ng-container>
        <!-- Panels Loading Indicator -->
        <div class="placeholder-image">
          <img *ngIf="!fetchDone && !storyPage && !isSeriesPage" [src]="getImagePath('CONTENT_PLACEHOLDER')" />
        </div>
        <!-- 2. All other Panels, Note: Panels are shown based on the data prepared in chapterList Array -->
        <ng-container>
          <ng-container *ngFor="let list of chapterList; let indexOfelement=index;">
            <h1 *ngIf="list.title === 'Subscribe' && !isSubscribed && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <h1 *ngIf="list.title === 'Change Subscription' && isSubscribed && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <h1 *ngIf="list.title === 'Terms and Conditions' && isSubscribePage" class="m-20 mt-40">
              {{ list.title }}
            </h1>

            <!-- Progress Bar (this will show progress bar from comic chapters if template subscribers is there conditionally)-->
            <ng-container *ngIf="list.template === 'subscribers'">
              <app-progress-bar [progressBarData]="list"></app-progress-bar>
            </ng-container>

            <ng-container *ngIf="list.template === 'card'">
              <app-template-card [data]="list"></app-template-card>
            </ng-container>

            <ng-container *ngIf="list && list.action != 'influencePanel' ">
            <!-- 3. (a) Latest Comics, only title on Home, and Following Advertisement -->
            <!-- 3. (b) New Comic Series, only title, for Series Page -->
            <!-- 3. (c) Cover Image, for Series Page, Comic page, Subscribe Page -->
            <div class="image-container mt-3"
            *ngIf="(!list.template || [templateTypes.stories, templateTypes.newsfeed, templateTypes.bonus, templateTypes.premium, templateTypes.comicLimit].includes(list.template)) && list.title !== 'You May Also Like'">
              <ng-container *ngIf="list.title != 'Latest Comics'">
                <app-image [indexOfelement]='indexOfelement' [comicData]="list" (modal)="openModal(modal)"
                (clicked)="fromPanelClick = true" [productIds]="productIds" [series]="currentSeriesDetails">
              </app-image>
              </ng-container>
              <!-- This block will be rendered on subscription page only -->
              <ng-container *ngIf="(currentUrl == subscribeURL) && (list.action == 'in-app-purchase')">
                <app-payments [subscriptionData]="list"></app-payments>
              </ng-container>
              <ng-container *ngIf="list.title == 'Latest Comics' && !showListViewGIF">
                <div class="d-flex mx-3 mb-3 px-1 mt-1 pt-3">
                  <h1>{{list.title}}</h1>
                  <div class="ml-auto pb-1 mb-1 d-flex">
                    <a class="mx-3" [ngClass]="{'selected-tab': comicSeriesViewTabs.all}" (click)="showAll()"><p class="mx-2" [class.p-bold]="comicSeriesViewTabs.all" alt="all comics">All</p></a>
                    <a class="px-2" [ngClass]="{'selected-tab': comicSeriesViewTabs.unread}" (click)="showUnread()"><p alt="only unread comics" [class.p-bold]="comicSeriesViewTabs.unread">Unread</p></a>
                  </div>
                </div>
                <p class="mx-3 pl-1" *ngIf="continueReading && !continueReading.isContinueReading">{{ list.description }}</p>
                <div *ngIf="continueReading && continueReading.isContinueReading" class="comic-list-section">
                  <app-list-view [listItemData]="continueReading"></app-list-view>
                </div>
                <p *ngIf="continueReading && !continueReading.isContinueReading && isSeriesPage" class="ml-1 pl-3" [ngClass]="{'mb-5 pb-5': this.chapterList.length < 3}">You are up-to-date! No new episodes.</p>

              </ng-container>
              <div
                *ngIf="(list.title == 'My Comics' && ((currentUrl == '/' || currentUrl == ''))
                && !isFollowComic && !showAllComics)
                && localStorageService.getItem('selectedMenuItem') === 'following'"
                class="message-block">
                <p>When you follow one or more series, we will show you their latest episodes here.</p>
                <br /><button class="btn tv-btn-red" [routerLink]="['/tinyview/comic-series-directory']">All Series</button>
              </div>

              <!-- COMIC PAGE EXTRA DETAILS -->
              <!-- <h1 class="m-20 mt-3" *ngIf="list.comicDescription && currentUrl !== '/tinyview'">Like, Comment, and Share</h1> -->
            <div [ngClass]="{'comic-bg-color pt-2 pb-1': list.record && isComicPage}">
              <div class="tv-card py-2 px-2"
              *ngIf="list.comicDescription && !currentUrl.includes('/tinyview')">
              <!-- <div class="series-data">
                <div class="series-sub-block">
                  <a *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation">
                    <img [src]="seriesImage" *ngIf="seriesImage" class="series-image" alt="user's image" />
                    <p *ngIf="!seriesImage && seriesTitle">{{getFirstLetters(seriesTitle)}}</p>
                  </a>
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <a *ngIf="seriesTitle" [routerLink]="seriesHomeNavigation" class="d-flex align-items-center">
                    <h2 *ngIf="!isCover" class="mx-2">{{seriesTitle}}</h2>
                  </a>
                  <span (click)="doFollowForComicWriters()"
                    *ngIf="!isCover && seriesTitle" class="d-flex align-items-center">
                    <div class="loader-bar-btn" *ngIf="showLoader"></div>
                    <p *ngIf="!showLoader && followBtnText" class="d-flex justify-content-start align-items-center cursor-pointer">
                      <img class="icon-20 mr-1" src="{{ followBtnText === 'Following' ? getImagePath('TICKBOX') : getImagePath('ICONS8_ADD_100') }}">
                      {{followBtnText}}
                    </p>
                  </span>
                </div>
              </div>
              <div class="title-btn-wrapper mt-2">
                <span class="p-bold" *ngIf="list?.comicTitle && !storyPage">
                  {{list.comicTitle}}
                </span>
              </div> -->
              <!-- <p class="my-2" *ngIf="comicDate && seriesImage && !isCover">{{comicDate}}</p> -->

              <!-- <img *ngIf="list?.comments && !storyPage" [src]="getImagePath('ICONS8_QUOTE')" class="quotes" alt="Quotes"> -->

              <!-- <p class="comments" *ngIf="list?.comments && !storyPage"
                [innerHTML]="list.comments | stripTags: 'br' :'p' :'i' :'b'">
              </p> -->
              <!-- <app-panel-nav *ngIf="!isCover && !storyPage && storyID && list.comicDescription"
              (doLike)="doLikefromComicPage($event)" [isLiked]="isLiked2" [commentCount]="commentCount"
              [viewCount]="pageInfo?.data?.viewCount" [likeCount]="likeCount" [storyID]="storyID"  [giftedItems]="giftedItems"
              [giftItemCount]="giftsCounts" [isBottomCard]="true" [storyData]="list"></app-panel-nav> -->

              <!-- COMMENTS -->
               <div class="d-flex mt-2 mx-2">
                <div (click)="showAllComments(storyID, signinModal, commentModal, true)" class="cursor-pointer">
                  <h2 class="p-bold">{{commentCount || 0 }} Comment{{commentCount === 1 ? '': 's'}}</h2>
                  <p *ngIf="!commentCount" class="mt-2">Be the first one to comment</p>
                </div>
                <div class="ml-auto">
                  <a aria-label="add comments" (click)="showAllComments(storyID, signinModal, commentModal, false)" class="tv-btn-light btn shadow-on-hover body-mini"> 
                    Post a comment 
                  </a>
                </div>
               </div>
              <ng-container *ngIf="list.comicDescription && (isComicPage || currentUrl == '/tinyview/comic-series-directory') && storyID && commentCount">
                <app-comments [showComment]="true" [anonymousUser]="anonymousUser" [record]="record || list.record"
                  [storyData]="{storyID: storyID, commentCount: commentCount, refType: refType}">
                </app-comments>
              </ng-container>
            </div>
            </div>
            </div>
            <div class="image-container" *ngIf="list?.template == templateTypes.story || (list.template == templateTypes.toc && list.actionType !== 'website' && !isSeriesPage && !isComicPage)">
              <app-comic-story [indexOfelement]='indexOfelement' [record]="record" [comicData]="list" (clicked)="fromPanelClick = true">
              </app-comic-story>
            </div>
            <div class="image-container" *ngIf="list.template == templateTypes.toc && (currentUrl != '/tinyview' && !storyPage || list.actionType === 'website')">

              <app-comic-toc [indexOfelement]='indexOfelement' [comicData]="list" (modal)="openModal(modal)"
                (clicked)="fromPanelClick = true">
              </app-comic-toc>
            </div>
            <img *ngIf="showListViewGIF" [src]="getImagePath('LISTVIEW_PLACEHOLDER')" />
            <div class="image-container" *ngIf="list.template == templateTypes.month && currentUrl != '/tinyview' && !showListViewGIF" [ngClass]="{'mb-5 pb-5': indexOfelement === (chapterList.length - 1)}">
              <p class="body-mini-bold mx-3 pl-1 mt-4 mb-2 pb-1">{{list.monthToDisplay}}</p>
              <div class="comic-list-section">
                <div *ngFor="let comic of list.comicsForCurrentMonth">
                  <app-list-view [listItemData]="comic"></app-list-view>
                  </div>
              </div>
            </div>
            <div class="image-container" *ngIf="(list.template == templateTypes.directory) && !isComicPage">
              <app-comic-directory
                [indexOfelement]='indexOfelement' [comicData]="list"
                (modal)="openModal(modal)" (clicked)="fromPanelClick = true">
              </app-comic-directory>
            </div>
            <div class="image-container" *ngIf="list.template == templateTypes.episodes && !isCover ">
              <app-comic-episodes [indexOfelement]='indexOfelement' [comicData]="list" [currentComicImage]="resp.comics && resp.comics.ogImage">
              </app-comic-episodes>
            </div>

            <div class="image-container" *ngIf="list && list.template == templateTypes.carousel && list.list && list.list.length !== 0">
              <app-scroller [panelConfigData]="list"></app-scroller>
            </div>
           </ng-container>

           <!-- comic navigation panel -->
           <!-- <ng-container *ngIf="list && list.action == 'comicNavigation' && !isComicNavLoading && (isComicPage || storyPage) && !isSubscribePage && !isNewUserPage">
            <app-comic-navigation [seriesEpisodes]="seriesEpisodes" [series]="seriesForNavigation" [action]="extActionURL" [followBtnText]="followBtnText" [hasFollowBtn]="hasFollowBtn" [hasBonusPanel]="hasBonusPanel" [currentSeriesDetails]="currentSeriesDetails" (onPropertyChange)="onBannerChange($event)"></app-comic-navigation>
           </ng-container>
           <div class="tv-card text-center" *ngIf="list && list.action == 'comicNavigation' && isComicNavLoading && (isComicPage || storyPage) && !isSubscribePage && !isNewUserPage">
            <img [src]="getImagePath('ICONS8_LOADING_V2')" alt="loader">
           </div> -->

           <!-- commented for new functionality in COMP-1028 -->
           <!-- <ng-container  *ngIf="(list && list.action == 'influencePanelForComicLimit') || (list && list.action == 'influencePanel') ">
            <div class="tv-card payment-container card-border">
                <div class="inner-block">
                  <img class="icon-32" [src]="getImagePath('BADGE_COFFEE_100')">
                  <div class="desc-block ml-3">
                    <span class="p-bold">Subscribe for the price of a cup of coffee a month!</span>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Support creators </p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Unlock Bonus pannels</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Read Unlimited Comics</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Participate in the discussion</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Get subscriber badge</p>
                    <p class="my-3"><span class="mr-2 text-dark">&#10004;</span>Cancel at any time</p>
                    <div class="subc-block mt-3">
                      <button (click)="router.navigate(['/tinyview/subscribe'])" class="btn tv-btn-red">
                        <p class="text-white">Subscribe</p>
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="list.action == 'influencePanelForComicLimit'">
              <div class="inner-block">
                  <img class="icon-32" [src]="getImagePath('ADD_USER_GROUP_MAN_100')" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Invite Friends
                  </div>
                  <p class="my-3">
                    <span>
                      Once you have 5 friends, you can access unlimited comics.
                    </span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="openInviteFriendsModal(inviteFriendsModal)" class="btn tv-btn-red text-center">
                      <span>Invite Friends</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="influencePoints.balance !== 0">
              <div class="inner-block">
                  <img class="icon-32" [src]="getImagePath('TINYVIEW_INFLUENCE_POINTS_OUTLINE_100')" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Use Influence Points
                  </div>
                  <span class="d-flex mt-2">
                    {{influencePoints.balance}}&nbsp;
                    <span><p>Current Balance</p></span>
                  </span>
                  <p class="my-3">
                    <span>
                      Don't worry - you can always earn more by sharing comics or inviting friends.
                    </span>
                    //<span *ngIf="influencePoints.balance">
                    //  Use 1 influence point to read
                    //  <span *ngIf="this.isBonusPanelComic"> the bonus panel. </span>
                    //  <span *ngIf="!this.isBonusPanelComic"> this premium comic. </span>
                    //</span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="unlockComic()" class="btn tv-btn-red text-center">
                      <p *ngIf="!isComicUnlocking" class="text-white">
                        <span>Use 1 Influence Point</span>
                      </p>
                      <div class="loader-bar-btn" *ngIf="isComicUnlocking"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tv-card payment-container card-border" *ngIf="list.action !== 'influencePanelForComicLimit'">
              <div class="inner-block">
                  // <span *ngIf="influencePoints.balance"> {{influencePoints.balance}} </span> 
                  <img class="icon-32" [src]="getImagePath('TINYVIEW_INFLUENCE_POINTS_OUTLINE_100')" alt="influence-img">
                <div class="desc-block p-bold ml-3">
                  <div class="p-bold">
                    Earn Influence Points
                  </div>
                  <span class="d-flex mt-2">
                    {{influencePoints.balance}}&nbsp;
                    <span><p>Current Balance</p></span>
                  </span>
                  <p class="my-3">
                    <span>
                      You can earn influence points by signing up, installing the app, sharing comics and adding friends.
                    </span>
                    //<span *ngIf="influencePoints.balance">
                    //  Use 1 influence point to read
                    //  <span *ngIf="this.isBonusPanelComic"> the bonus panel. </span>
                    //  <span *ngIf="!this.isBonusPanelComic"> this premium comic. </span>
                    //</span>
                  </p>
                  <div class="subc-block mt-3">
                    <button (click)="unlockComic('earn')" class="btn tv-btn-red text-center">
                      <p class="text-white">
                        <span> Earn Influence Points </span>
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
           </ng-container> -->

          </ng-container>
          <img class="mt-5" *ngIf="showFeedStory && !isSeriesPage" [src]="getImagePath('CONTENT_PLACEHOLDER')" />
        </ng-container>
      </div>
    </div>
    <div *ngIf="isLoadingHeader" class="loading-container">
      <img [src]="getImagePath('PAGE_TOP_PLACEHOLDER')" alt="Page loading">
    </div>
  </div>
</ng-container>

<!-- Moving this into bottom-bar component as this is the part of bottom bar -->
<!-- <app-panel-nav *ngIf="((isComicPage && !isSubscribePage) || storyPage) && giftsCounts"
  (doLike)="doLikefromComicPage($event)"
  [isLiked]="isLiked2"
  [commentCount]="commentCount" 
  [viewCount]="pageInfo?.data?.viewCount || viewCount" 
  [likeCount]="likeCount"
  [storyID]="storyID" 
  [giftedItems]="giftedItems" 
  [giftItemCount]="giftsCounts" 
  [isBottomCard]="true" 
  [storyData]="resp?.data?.data"
  [hideBottomSection]="true" 
  [actionType]="actionType">
</app-panel-nav> -->

<ng-container *ngIf="(errorMsg && fetchDone)">
  <app-data-not-found></app-data-not-found>
</ng-container>
<!-- <ng-container *ngIf="errorMsg">
  <app-header
    *ngIf="(currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser' || currentDevice == 'desktopBrowser') && !isIpad">
  </app-header>
</ng-container> -->
<ng-template #openInApp>
  <ng-container>
    <div class="open-app-container">
      <button type="button" class="btn-close" aria-label="Close" (click)="closeOpenInApp()">
        <img [src]="getImagePath('ICONS8_DELETE')" />
      </button>
      <div class="open-app-text">
        <h6>Tinyview is better on the app</h6>
        <p>Open this in the app to get the full experience. The app is FREE.</p>
      </div>
      <div class="btn-container">
        <button *ngIf="currentDevice == 'androidBrowser' || currentDevice == 'iosBrowser'" class="btn" aria-label="Open"
          (click)="openApp()">
          Switch to the app
        </button>
        <!-- <button *ngIf="currentDevice != 'androidBrowser' && currentDevice != 'iosBrowser'" class="btn" aria-label="Open"
          (click)="openModal(modal)">
          Send me the link
        </button> -->
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center">Send Me The Link</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img [src]="getImagePath('ICONS8_DELETE')" />
    </button>
  </div>
  <div class="modal-body">
    <div class="img-container">
      <img [src]="getImagePath('TINYVIEW_LOGO_ONLY_MARK')" alt="tinyview logo">
    </div>
    <div class="desc-container">
      <div class="desc-wrapper">
        <div class="title">
          Tinyview Comics
        </div>
        <div class="desc">
          You will receive a one-time text to download the app.
        </div>
      </div>
      <div class="form-container">
        <form [formGroup]="myform" (ngSubmit)="sendSMS()">
          <div class="form-group">
            <label for="phone">Phone Number:</label>
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true"
              [separateDialCode]="separateDialCode" name="phone" formControlName="phone">
            </ngx-intl-tel-input>
          </div>
          <div class="form-group">
            <button type="submit" class="btn app-btn" [disabled]="!myform.valid">
              <span *ngIf="!willShowLoader">Send Me The Link</span>
              <div class="loader-bar" *ngIf="willShowLoader"></div>
            </button>
          </div>
          <div class="sms-response" *ngIf="smsResponse != null">
            <span
              [ngClass]="{'success-response': smsResponse =='Link sent successfully.', 'error-response': smsResponse != 'Link sent successfully.' }">{{smsResponse}}</span>
          </div>
        </form>
      </div>
      <div class="footer-wrapper">
        <span>By providing your phone number, you agree to receive a one-time automated text message with a link to get
          the app. Standard messaging rates may apply.</span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #signinModal>
  <div>
      <app-action-popup [modalRef]="modalRef" [ctaList]="ctaList" [likedComic]="likedComic"></app-action-popup>
  </div>
</ng-template>
<ng-template #commentModal>
  <div class="action-container">
      <div class="text-center mb-2">
          <p>You need to complete your profile first.</p>
      </div>
      <div>
      <div class="btn-container pb-0">
          <button class="secondary-btn" aria-label="Open" (click)="commentModalRef.hide()">
              Cancel
          </button>
          &nbsp;
          <button class="btn tv-btn-red" aria-label="Open" (click)="goToEditProfilePage()">
              Edit Profile
              </button>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #actionmodal>
  <app-action-popup [likedComic]="likedComic" [modalRef]="actionModalRef"></app-action-popup>
</ng-template>

<script src='scroll-frame.js'></script>

<ng-template #shareModal>
  <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="shareRef.hide()" />
  <div class="heading">
      <h2 class="share-heading">Share it with friends</h2>
      <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
      <p class="share-sub-heading" *ngIf="isSubscriber">and help creators by spreading the word</p>
  </div>
  <div class="gift-items">
      <ng-container *ngFor="let share of shareConfig">
          <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
              <img class="share-img" [src]="share.image">
              <span class="share-title">{{ share.title }}</span>
          </div>
      </ng-container>
  </div>
  <div class="btn-container">
  </div>
</ng-template>
<ng-template #inviteFriendsModal>
  <app-invite-friends [isSubscribed]="false" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
</ng-template>

<!-- Bottom navigation here for desktop's series/comic/story page only, but not on subscribe page -->
<div *ngIf="(isSeriesPage || (isComicPage && !isSubscribePage) || storyPage || isHomePage) && !isMobileView"
  class="sticky-footer" [ngClass]="{'desktop-bottom-bar': isHomePage}">
  <app-bottom-nav-mobile></app-bottom-nav-mobile>
</div>
