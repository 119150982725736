import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../../environments/environment';
import { ComicService } from '../comic.service';
import { Panel } from '../../models/comic';
import { HttpClient } from '@angular/common/http';
import { flatMap, map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service'
import { CacheService } from '../cache.service';
import { ApiService } from '../api.service';
import { API_KEYS } from 'src/app/constants/common.constants';

const COMIC_IMG_API = `${environment.API_END_POINT}`;
const FIREBASE_API = `${environment.FIREBASE_API_ENDPOINT}`;

@Injectable({
  providedIn: 'root'
})
export class PanelService {

  constructor(
    private comicService: ComicService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cacheService: CacheService,
    private apiService: ApiService
  ) { }

  public createLinkPanel(ogImage: string, path: string, meta: { datetime: string, title: string, description: string, comments: string, rating?: string, exclusivity?: string, 'show-to'?: string }): Observable<Panel> {
    const url = this.getImgUrl(ogImage, path);
    try {
      return this.http.get(url, { responseType: 'arraybuffer' }).pipe(
        flatMap((resp) => {
          var file = new File([resp], ogImage, { type: "image/jpeg , image/png" });
          return forkJoin(this.getImageDimensions(file), this.getMd5Sum(file))
        }
        ),
        map(([dimensions, md5sum]) => {
          if (path.includes('/tinyview/comic-series-directory')) {
            path = '/'
          }
          return {
            image: `${path.replace(/\/$/, '')}/${ogImage.replace(/^\/|\/$/g, '')}`,
            height: dimensions.height,
            width: dimensions.width,
            md5sum: md5sum,
            action: path == '/' ? '/index.json' : path ? `${path}/index.json` : null,
            title: meta.title,
            description: meta.description,
            datetime: meta.datetime,
            comments: meta.comments,
            rating: meta.rating, // store default if not present handling remaining
            exclusivity: meta.exclusivity, // store default if not present handling remaining
            'show-to': meta['show-to'], // store default if not present handling remaining
          }
        })
      )
    } catch (e) {
      console.log('errorr', e.message)
    };

  }

  public createExternalLinkPanel(url: string,
    title: string,
    description: string,
    imageUrl: string, author: string, datetime: any, imageBase64: string): Observable<Panel> {
    return of({
      image: (imageUrl && (imageUrl.includes('http') || imageUrl.includes('https'))) ? imageUrl : null,
      action: url ? url.trim() : null,
      actionType: 'website',
      title: title ? title : null,
      description: description ? description : null,
      author: author ? author : null,
      datetime: datetime ? datetime : null,
      imageBase64: imageBase64 ? imageBase64 : null
    })
  }

  public getAllImageDimensions(files: File[]): Observable<{ width: number, height: number }[]> {
    const observables = [];
    files.forEach((file: File) => {
      observables.push(this.getImageDimensions(file));
    });
    return forkJoin(observables);
  }

  public getAllMd5Sums(files: File[]): Observable<string[]> {
    const observables = [];
    files.forEach((file: File) => {
      observables.push(this.getMd5Sum(file));
    });
    return forkJoin(observables);
  }

  public getImageDimensions(file: File): Observable<{ width: number, height: number }> {
    const res: Observable<{ width: number, height: number }> = new Observable(subscriber => {
      if (file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/png" && file.type != "image/jpeg , image/png") {
        subscriber.next({ width: 0, height: 0 });
        subscriber.complete();
      } else {
        var img = new Image();
        img.onload = () => {
          subscriber.next({ width: img.naturalWidth, height: img.naturalHeight });
          window.URL.revokeObjectURL(img.src);
          subscriber.complete();
        }
        img.src = window.URL.createObjectURL(file);
      }
    });

    return res;
  }

  public getMd5Sum(file: File): Observable<string> {
    const res: Observable<string> = new Observable(subscriber => {
      var reader = new FileReader();
      reader.onload = () => {
        const md5sum = Md5.hashAsciiStr((reader.result as string));
        subscriber.next(md5sum as string);
        subscriber.complete();
      }
      reader.readAsBinaryString(file);
    });
    return res;
  }

  public getImgUrl(image: string, seriesUrl: string, stopReplacingURL?: boolean) {
    if (seriesUrl == '/') {
      seriesUrl = '';
    }
    let path = COMIC_IMG_API;
    if (this.localStorageService.getItem('selectedMenuItem') === 'following' && image && image.indexOf('tinyview-fb-cover.jpg') > -1 && !stopReplacingURL) {
      image = image.replace('tinyview-fb-cover.jpg', 'following-banner.jpg');
    }
    if (image && this.isValidUrl(image) && (image.includes('http') || image.includes(COMIC_IMG_API) || image.includes('https://storage.googleapis.com/'))) {

      const CDN_BUC = environment.OLD_API_END_POINT;
      const ASSETS_BUC = environment.OLD_IMAGE_END_POINT;
      if (image.includes(CDN_BUC)) {
        image = image.replace(CDN_BUC, environment.API_END_POINT);
      } else if (image.includes(ASSETS_BUC)) {
        image = image.replace(ASSETS_BUC, environment.IMAGE_END_POINT);
      }
      return image;
    } else {
      return `${path}${this.comicService.resolvePath(seriesUrl, image)}`;
    }
  }

  // dupe
  public isValidUrl = urlString =>{
    // SSR check
    if (typeof window === "undefined") {
      return '';
    }
  	var a  = document.createElement('a');
   	a.href = urlString;
   	return (a.host && a.host != window.location.host);
  }

  async getScrapeURL(val: string, imgbs64: boolean) {
    const scrapeURL = await this.apiService.send(API_KEYS.SCRAPE_URL);
    return await scrapeURL({ text: val, user_agent: navigator.userAgent, imgbs64: imgbs64});
  }

  isPanelShownToCurrentUser(panelItem, purchasedSubID): boolean {
    let shouldAddPanel: boolean;
    if (panelItem.inAppProducts) {
      for (const key in panelItem.inAppProducts) {
        if (panelItem.inAppProducts.hasOwnProperty(key)) {
          const reqObject = panelItem.inAppProducts[key];
          const requirePurchaseKey = Object.keys(reqObject)[0];
          const needsPurchase = reqObject[requirePurchaseKey];
          shouldAddPanel = false;
          if (needsPurchase && purchasedSubID == requirePurchaseKey) {
            shouldAddPanel = true;
            break
          } else if (!needsPurchase && purchasedSubID != requirePurchaseKey) {
            shouldAddPanel = true;
          } else if (!needsPurchase && purchasedSubID == requirePurchaseKey) {
            shouldAddPanel = false
            break
          }
        }
      }
    } else {
      // If panel's json does not have inAppProducts, then show panel
      shouldAddPanel = true;
    }
    return shouldAddPanel;
  }

  isAllowedPanelVersion(panelItem): boolean {
    let shouldAddPanel: boolean;
    if (panelItem.versions && panelItem.versions.length > 0){
      const cacheLatestWebVersion = this.cacheService.get('latestWebVersion');
      const maxVersionObj = panelItem.versions.find(version => version.hasOwnProperty('maxVersion'));
      if (maxVersionObj) {
        (maxVersionObj.maxVersion*1) >= (cacheLatestWebVersion*1) ? shouldAddPanel = true : shouldAddPanel = false;
      } else {
        shouldAddPanel = true;
      }
    } else {
      shouldAddPanel = true;
    }
    return shouldAddPanel;
  }
}
