import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Location } from '@angular/common';
import { NewComicService } from "src/app/new-comic.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as firebase from "firebase";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastrService } from "ngx-toastr";
import { AUTH_TYPE } from "src/app/constants/common.constants";
import { UserService } from "src/app/services/user.service";
import { maskEmail } from "src/app/utilities/common.util";
import { getImage } from "src/app/constants/images.constants";
import { ComicService } from "src/app/services/comic.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() editProfileFlow: boolean;
  @Input() userDetails: any = {};
  profileForm: FormGroup;
  wordCount: number;
  userImage: string;
  isDropdownOpen: boolean;
  selectedOption = { label: 'Select option', value: '' };
  arrowDown = this.getImagePath('ICONS8_EXPAND_ARROW');
  arrowUp = this.getImagePath('ICONS8_COLLAPSE_ARROW');
  loadingButton: boolean;
  genderError: boolean;
  profilePicRef: BsModalRef;
  deletePicRef: BsModalRef;
  showLoader: boolean;
  file: File;
  last4DigitPhone: string;
  pronoun = [
    {
      label: "She/Her", value: "she",
    },
    {
      label: "He/Him", value: "he",
    },

    {
      label: "They/Them", value: "they",
    },
    {
      label: "Other", value: "other",
    },
    {
      label: "I prefer not to say", value: "i_prefer_not_to_say",
    }
  ]
  emailDisplayName: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private newComicService: NewComicService,
    private comicService: ComicService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    let user = firebase.auth().currentUser;
    this.emailDisplayName = user.email ? maskEmail(user.email): '';
    this.last4DigitPhone = this.userDetails.phoneNumber ? `x${this.userDetails.phoneNumber.substr(-4)}` : null;
    if (this.route.snapshot.queryParams.last4DigitPhone && !this.last4DigitPhone) {
      this.last4DigitPhone = this.route.snapshot.queryParams.last4DigitPhone;
    }
    if (this.editProfileFlow) {
      this.setEditFlowValues();
    } else {
      this.initializeProfileForm();
      if (this.route.snapshot.queryParams.emailVerificationState === 'success') {
        // TODO: Increase the toaster time, if needed.
        this.toastr.success('Your email address is verified.');
      }
    }
  }

  setEditFlowValues() {
    this.initializeProfileForm();
    if (this.userDetails) {
      const matchedPronoun = this.pronoun.find(p => p.value === this.userDetails.gender);
      this.selectedOption = matchedPronoun ? matchedPronoun : { label: 'Select option', value: '' };
      this.userImage = this.userDetails.userImage;
      this.countWords();
    }
  }

  initializeProfileForm() {
    this.profileForm = this.formBuilder.group({
      name: [this.userDetails.name || this.last4DigitPhone || this.emailDisplayName || '', [Validators.required]],
      link: [this.userDetails.link || ''],
      bio: [this.userDetails.bio || ''],
    });
  }

  showPreview(files: FileList) {
    this.file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.userImage = reader.result as string;
    }
    reader.readAsDataURL(this.file)
    this.profilePicRef.hide()
  }

  countWords() {
    const textValue = this.profileForm.get('bio').value;
    this.wordCount = textValue ? textValue.length : 0;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.genderError = false;
  }

  selectOption(option) {
    if (this.selectedOption.label.includes(option.label)) {
      return this.selectedOption = { label: 'Select option', value: '' };
    }
    this.selectedOption = option;
    this.isDropdownOpen = false;
  }

  validateField(fieldName: string) {
    if (fieldName === 'Select option') {
      this.genderError = true;
    } else {
      const field = this.profileForm.get(fieldName);
      if (field) {
        field.markAsTouched();
      }
    }
  }

  async updateProfileInfo() {
    this.loadingButton = true;
    await this.authService.userProfile([{
      "userName": this.profileForm.get('name').value,
      "displayName": this.profileForm.get('name').value,
      "aboutMe": this.profileForm.get('bio').value,
      "gender": this.selectedOption.value,
      "websiteLink": this.profileForm.get('link').value,
    }]).then(async (res) => {
      if (this.file && this.userImage) await this.authService.uploadImage(this.file.name, this.userImage);
      if (this.editProfileFlow) {
        // Edit Success toaster
        this.toastr.success('Your profile is updated successfully');
        const user = firebase.auth().currentUser;
        // check for signin
        const isSignedInUser = this.authService.isSignedInUser(user);
        if (isSignedInUser) {
          // Fetching profile details with phone number
          let authType: string;
          if (user.phoneNumber) authType = AUTH_TYPE.PHONE;
          if (user.email) authType = AUTH_TYPE.EMAIL;
          this.userService.getUserProfileByPhoneOrEmail(authType, user.phoneNumber || user.email).then((res) => {
            this.localStorageService.setItem('userData', JSON.stringify(res));
          })
        };
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['signup'], { queryParams: {success: true}});
      }
    }).catch((err) => {
      this.loadingButton = false;
      console.log('error on profile update');
    });
  }

  public goBack(): void {
    this.location.back();
  }

  openProfilePicOptionModal(template: TemplateRef<any>) {
    const isMobile = this.newComicService.isMobileView ? 'mobile-modal' : '';
    this.profilePicRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }
  openDeletePicConfirmationModal(template: TemplateRef<any>) {
    this.profilePicRef.hide();
    const isMobile = this.newComicService.isMobileView;
    this.deletePicRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-mdmobile-modal`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  async deleteProfilePic() {
    this.showLoader = true;
    await this.authService.removeProfileImage();
    this.userImage = '';
    this.showLoader = false;
    this.deletePicRef.hide();
  }

  clearLink() {
    this.profileForm.patchValue({ link: '' })
  }

  isFilledAndValid(controlName: string): boolean {
    const control = this.profileForm.get(controlName);
    return control && control.valid && control.value !== '';
  }


  isInvalid(controlName: string): boolean {
    const control = this.profileForm.get(controlName);
    return control && control.touched && control.invalid;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  getCDNImgPath(icon: string) {
    return this.comicService.getCDNImgUrl(icon);
  }
}
