<div *ngIf="buttonsArray.length !== 0">
  <div class="d-flex">
    <div *ngFor="let button of buttonsArray" class="button-wrapper">
      <span *ngIf="button.label" class="custom-button body-mini"> 
          <img *ngIf="button.icon" [src]="button.icon" alt="" class="button-icon">
          {{ button.label }}
      </span>
    </div>
    <div *ngIf="showDoubleTick" class="ml-auto mt-1">
      <img [src]="getImagePath('ICONS8_DOUBLE_TICK_100')" width="20px" height="20px" alt="Read">
    </div>
  </div>

</div>
