<div class="container" *ngIf="!error">
  <div class="row mt-1">
    <div class="col-auto mt-4 pt-2 custom-filter-margin">
       <h1>Totals Dashboard</h1>
      <div *ngIf="!isLoading && !tableError" class="mt-3 pt-1">
        <p class="mb-2 pb-1 text-start">{{calculatedFor}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="!tableError && !isLoading">
    <div class="my-3">
      <h2 class="mb-2">Subscriptions:</h2>
      <app-creator-stat-table [tableHead]="tableHead" [calculatedDailyDataView]="calculatedSubscriptionsDataView"></app-creator-stat-table>
    </div>
    <div class="my-3">
      <h2 class="mb-2">Signups:</h2>
      <app-creator-stat-table [tableHead]="tableHead" [calculatedDailyDataView]="calculatedSignupsDataView"></app-creator-stat-table>
    </div>
    <div class="my-3">
      <h2 class="mb-2">Alerts:</h2>
      <app-creator-stat-table [tableHead]="tableHead" [calculatedDailyDataView]="calculatedAlertsDataView"></app-creator-stat-table>
    </div>
  </div>
  <div class="text-center comments mt-5" *ngIf="tableError">
    {{tableError}}
  </div>
  <div class="loader-container" *ngIf="isLoading">
    <img class="loader-content" [src]="getImagePath('ICONS8_LOADING_V2')" alt="loader">
  </div>
</div>

<div class="container h3 text-center mt-5" *ngIf="error">
  {{error}}
</div>
