export const apiMap = {
  // Add other function names and their corresponding API endpoints here
  'NOTIFICATION_SCHEDULER': { url: '/user/creator/notification', method: 'POST', fbFunction:"notificationScheduler"},
  'EDIT_STORY': { url: '/story', method: 'PUT', fbFunction:"editStory"},
  'DELETE_STORY': { url: '/story', method: 'DELETE', fbFunction:"deleteStory"},
  'GET_DASHBOARD_DATA' : {url : '/dashboard/data', method: 'GET', fbFunction:"getDashboardData"},
  'GET_GIFTS_DASHBOARD_DATA_BY_DATE' : {url : '/dashboard/stats/gift/${date}', method: 'GET', fbFunction:"getDashboardGiftDataSingleDate"},
  'GET_DASHBOARD_INVITE_DATA' : {url : '/dashboard/invite', method: 'GET', fbFunction:"getDashboardInviteData"},
  'GET_REFFERER_DASHBOARD_DATA' : {url : '/dashboard/stats/referrer', method: 'GET', fbFunction:"getReferrerDashboardData"},
  'GET_CREATOR_DASHBOARD_DATA' : {url : '/dashboard/stats/creator', method: 'GET', fbFunction:"getCreatorDashboardData"},
  'GET_SERIES_CAROUSEL' : {url : '/story/carousel', method: 'GET', fbFunction:"getSeriesCarousel"},
  'STORY_FROM_COMIC_WRITER': { url: '/story/create', method: 'POST', fbFunction:"storyFromComicWriter" },
  'GET_MULTIPLE_PAGE_INFO': { url: '/story/pages', method: 'POST', fbFunction:"getMultiplePageInfo" },
  'CREATE_PORTAL_LINK' : {url : '/payment', method: 'POST', fbFunction:"createPortalLink"},
  'CREATE_CHECKOUT_LINK' : {url : '/payment/checkout', method: 'POST', fbFunction:"createCheckoutLink"},
  'GET_SUBSCRIPTION_METER_AGGREGATES' : {url : '/stats/subscriptions', method: 'GET', fbFunction:"getSubscriptionMeterAggregates"},
  'CANCEL_SUBSCRIPTION' : {url : '/payment/cancel-subscription', method: 'PUT', fbFunction:"cancelSubscription"},
  'UPDATE_SUBSCRIPTION' : {url : '/payment/subscription', method: 'PUT', fbFunction:"upgradeSubscription"},
  'MERGE_AUTHENTICATED_USER': { url: '/user/merge', method: 'POST' , fbFunction:"mergeAuthenticatedUser"},
  'SEND_TEXT_MESSAGE': { url: '/user/send-message', method: 'POST' , fbFunction:"sendTextMessage"},
  'UPDATE_USER_PROFILE': { url: '/user/profile', method: 'PUT' , fbFunction:"updateUserProfile"},
  'SCRAPE_URL' : {url : '/user/scrape', method: 'POST', fbFunction:"scrapeURL"},
  'UPDATE_PROFILE_PIC_URL': { url: '/user/profile/photo', method: 'POST', fbFunction:"uploadImage" },
  'GET_UNLOCK_COMIC_URLS': { url: '/story/unlock-urls', method: 'GET', fbFunction:"getUnlockComicURLs" },
  'UPDATE_FRIEND_REQUEST': { url: '/user/friend-req', method: 'PUT', fbFunction:"updateFriendRequest" },
  'GET_FRIEND_REQUEST': { url: '/user/get-friend-req', method: 'POST', fbFunction:"getFriendRequests" },
  'SEND_FRIEND_REQUEST': { url: '/user/friend-req', method: 'POST', fbFunction:"sendFriendRequest" },
  'GET_USER_PROFILE': { url: '/user/profile', method: 'GET', fbFunction:"getUserProfile" },
  'GET_ALL_GIFT_ITEM': { url: '/rewards/gift', method: 'GET', fbFunction:"getGiftItem" },
  'ADD_INFLUENCE_POINT': { url: '/rewards/redeem-points', method: 'POST', fbFunction:"addInfluencePoint" },
  'REDEEM_INFLUENCE_POINT': { url: '/rewards/redeem-points', method: 'PUT', fbFunction:"redeemInfluencePoint" },
  'GET_INFLUENCE_POINTS_FOR_ACTIONS': { url: '/rewards', method: 'GET', fbFunction:"getInfluencePoint" },
  'GET_ALL_COMICS': { url: '/story/comics', method: 'POST', fbFunction:"getAllComics" },
  'GET_NOTIFICATIONS': { url: '/user/get-notifications', method: 'POST', fbFunction:"getNotifications" },
  'GET_USER_FEED': { url: '/user/feed', method: 'POST', fbFunction:"getUserFeed" },
  'GET_SERIES_COMICS': { url: '/story/comics-list', method: 'POST', fbFunction:"getComics" },
  'RECORD_STORY_VIEW': { url: '/stats/view', method: 'PUT', fbFunction:"recordStoryView" },
  'FETCH_COMMENTS': { url: '/story/comment', method: 'GET', fbFunction:"fetchComments" },
  'ADD_COMMENT': { url: '/story/comment', method: 'POST', fbFunction:"addComment" },
  'LIKE_COMMENT': { url: '/story/comment/like', method: 'POST', fbFunction:"likeComment" },
  'DISLIKE_COMMENT': { url: '/story/comment/dislike', method: 'POST', fbFunction:"disLikeComment" },
  'EDIT_COMMENT': { url: '/story/comment', method: 'PUT', fbFunction:"editComment" },
  'DELETE_COMMENT': { url: '/story/comment', method: 'DELETE', fbFunction:"deleteComment" },
  'GET_MY_REPOST': { url: '/story/get-repost', method: 'POST', fbFunction:"getMyReposts" },
  'ADD_REPOST': { url: '/story/repost', method: 'POST', fbFunction:"addRepost" },
  'SEND_POST': { url: '/story/post', method: 'POST', fbFunction:"sendPost" },
  'EDIT_REPOST': { url: '/story/repost', method: 'PUT', fbFunction:"editRepost" },
  'DELETE_REPOST': { url: '/story/repost', method: 'DELETE', fbFunction:"deleteRepost" },
  'DELETE_SENT_POST': { url: '/story/post', method: 'DELETE', fbFunction:"deleteSentPost" },
  'GET_STORY_LIKES': { url: '/story/all-like', method: 'POST', fbFunction:"getStoryLikes" },
  'FETCH_STORY_LIKES': { url: '/story/like/all-users', method: 'GET', fbFunction:"fetchStoryLikes" },
  'LIKE_STORY': { url: '/story/like', method: 'POST', fbFunction:"likeStory" },
  'DISLIKE_STORY': { url: '/story/dislike', method: 'POST', fbFunction:"dislikeStory" },
  'GET_STORY_DETAILS': { url: '/story', method: 'GET', fbFunction:"getStory" },
  'FETCH_COMMENT_LIKES': { url: '/story/fetch-comment-like', method: 'POST', fbFunction:"fetchCommentLikes" },
  'FETCH_REPOSTED_USERS_LIST': { url: '/story/repost/fetch', method: 'POST', fbFunction:"fetchReposts" },
  'FETCH_WITH_WHOM_STORY_SHARED': { url: '/user/shared-with', method: 'GET', fbFunction:"getSharedByList" },
  'ADD_USER_DEVICE_TOKEN': { url: '/user/add-token', method: 'POST', fbFunction:"addUserDeviceToken" },
  'SUBSCRIBE_TO_ALERT': { url: '/story/follow', method: 'POST', fbFunction:"subscribeToAlert" },
  'ADD_NEW_TRANSACTION': { url: '/user/transaction', method: 'POST', fbFunction:"addNewTransaction" },
  'VALIDATE_TRANSACTION': { url: '/user/validate-transaction', method: 'POST', fbFunction:"validateTransaction" },
  'SET_REFERRER': { url: '/user/referrer', method: 'POST', fbFunction:"setReferrer" },
  'RECORD_PAGEVIEW': { url: '/stats/page-view', method: 'POST', fbFunction:"recordPageview" },
  'GET_PAGEVIEW_INFO': { url: '/story/info', method: 'GET', fbFunction:"getPageInfo" },
  'GET_USER_DETAILS': { url: '/user', method: 'GET', fbFunction:"getUserDetailsv2" },
  'UPDATE_USER_SETTINGS': { url: '/user/settings', method: 'PUT', fbFunction:"updateUserSettings" },
  'SIGN_OUT_USER': { url: '/user/sign-out', method: 'POST', fbFunction:"signOutUser" },
  'FLAG_COMMENT': { url: '/user/comment/flag', method: 'POST', fbFunction:"flagComment" },
  'BLOCK_USER': { url: '/user/block-comment', method: 'POST', fbFunction:"blockUser" },
  'PORTAL_LINK': { url: '/payment', method: 'POST', fbFunction:"createPortalLink" },
  'DELETE_USER_ACCOUNT': { url: '/user', method: 'DELETE', fbFunction:"deleteUser" },
  'GET_SUBSCRIBERS_PROGRESS': { url: '/stats/subscriptions', method: 'GET', fbFunction:"getSubscriptionMeterAggregates" },
  'GET_NAVIGATION': { url: '/story/navigate', method: 'GET', fbFunction:"getNavigation" },
  'REMOVE_PROFILE_IMAGE': { url: '/user/profile/photo', method: 'DELETE', fbFunction:"removeProfileImage" },
  'UPDATE_LAST_SEEN_NOTIFICATION_AT': { url: '/user/notifications', method: 'PUT', fbFunction:"updateLastSeenNotificationAt" },
  'ADD_EMAIL_FLOW_DATA': { url: '/user/add-email', method: 'POST', fbFunction:"addEmailFlowData" },
  'SET_NOTIFICATION_SETTINGS': { url: '/user/notifications', method: 'POST', fbFunction:"setNotificationSettings" },
  'HAS_COMIC_READ_ACCESS': { url: '/user/access/{pageUrl}', method: 'GET', fbFunction:"hasComicReadAccess" },
  'UNFRIEND': { url: '/user/friends/{friendUID}', method: 'DELETE', fbFunction:"removeFriend" },
  'GET_MY_FRIENDS': { url: '/user/friends/{userID}', method: 'GET', fbFunction:"getFriendshipStatus" },
  'GET_FRIENDSHIP_STATUS': { url: '/user/friend/{userID}', method: 'GET', fbFunction:"getFriendshipStatus" },
  'GET_EPISODES': { url: '/story/series-episode', method: 'POST', fbFunction:"getEpisodes" },
  'GET_EMAIL_FLOW_DATA': { url: '/user/data/{flowDataID}', method: 'GET', fbFunction:"getEmailFlowData" },
  'GET_APP_CONFIG': { url: '/user/app-config', method: 'GET', fbFunction:"getAppConfig" },
  'ADD_LOG': { url: '/story/log', method: 'POST', fbFunction:"log" },
};