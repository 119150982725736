<div class="tv-card payment-container" [class.card-border]="!isSubscribePage">
    <ng-container>
        <!-- *ngIf="( productIds.length ? (id == 'com.tinyview.subscription.artsupplies' ? false : subscriptionData?.inAppPurchase != id) : true)"> -->
        <div class="inner-block" [ngClass]="{'disabled-card': isExpired}">
            <div class="img-block flex-shrink-0">
                <!-- using prod cdn urls due to hardcoding of urls -->
                <img *ngIf="subscriptionData?.inAppPurchase == productIDs.pizza"
                    [src]="getImagePath('PIZZA')" class="image-size"/>
                <img *ngIf="subscriptionData?.inAppPurchase == productIDs.coffee"
                    [src]="getImagePath('COFFEE')" class="image-size" />
                <img *ngIf="subscriptionData?.inAppPurchase == productIDs.artsupplies"
                    [src]="getImagePath('ARTSUPPLIES')"
                    class="image-size" class="image-size"/>
                <img *ngIf="subscriptionData?.inAppPurchase == productIDs.bagel"
                    [src]="getImagePath('BAGEL')" class="image-size"/>
                <img *ngIf="subscriptionData?.inAppPurchase == productIDs.cookie"
                    [src]="getImagePath('COOKIE')" class="image-size"/>
            </div>
            <div>
                <h2>
                    {{subscriptionData.title}}
                </h2>
                <div class="p-bold comments">
                    {{subscriptionData.description}}
                </div>
                <p class="my-2" *ngIf="!isSubscribePage">{{subscriptionData.benefits}}</p>
                <p class="my-2">{{getRenewal(subscriptionData.renewal, subscriptionData?.inAppPurchase)}}</p>
                <p *ngIf="getExpiry(subscriptionData?.inAppPurchase)">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    {{getExpiry(subscriptionData?.inAppPurchase)}}
                </p>
                <div class="subc-block mt-2">
                    <button class="{{getPriceClass(subscriptionData?.inAppPurchase)}}"
                        (click)="checkOut(subscriptionData?.inAppPurchase, false, isCancellationAction)"
                        [ngClass]="{'disabled': checkForDisabling(subscriptionData?.inAppPurchase)}"
                        [disabled]="checkForDisabling(subscriptionData?.inAppPurchase)">
                        <span *ngIf="!subscriptionId">
                            {{ getPrice(subscriptionData?.inAppPurchase) }}
                        </span>
                        <span class="loader-bar" *ngIf="subscriptionId"></span>
                    </button>
                </div>
                <p *ngIf="getExpiry(subscriptionData?.inAppPurchase, true)" class="text-cancellation mt-2">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    {{getExpiry(subscriptionData?.inAppPurchase, true)}}
                </p>
            </div>
        </div>
    </ng-container>
</div>

<!-- Upgrade Confirmation Pop up -->
<ng-template #upgradeConfirmation let-upgradeConfirmation>
    <div class="modal-content content">
        <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')"
            (click)="closePlanChangeConfirmModal(upgradeModalRef)" />
        <label class="modal-header">{{ planChangeHeader[action] }}</label>
        <div class="text-dark">
            {{ getPlanChangeMsg(action) }}
        </div>
        <div class="btn-container">
            <button class="btn primary-btn" style="font-size: 14px;" (click)="checkOut(selectedID, true, action === 'Cancel')">
                {{ planChangeCtaText[action] }}
            </button>
            <button class="secondary-btn mt-0" (click)="closePlanChangeConfirmModal(upgradeModalRef)">{{ cancelCtaText[action] }}</button>
        </div>
    </div>
</ng-template>
