import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { PanelService } from '../services/panel/panel.service';
import { PurchasedItems } from '../models/comic';
import { productIDs } from '../constants/subscription.constants';
import { Router } from '@angular/router';
import { ACTIONS, ALLOWED_ACTION_TYPES, BROWSER, carouselType, subscribeURL, templateTypes, TINYVIEW } from '../constants/common.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { NewComicService } from '../new-comic.service';
import { isPlatformBrowser } from '@angular/common';
import { AppService } from '../services/app.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss', './../comic/comic.component.scss']
})
export class ProgressBarComponent implements OnInit {
  templateTypes = templateTypes;
  headerChapter: any = [];
  totalAggregates: number = 0;
  renewCount: number = 0;
  currentUrl: string;
  chapter: any = [];
  currentUserSubscriptions: any = [];
  currentDevice: string;
  url: string;
  showInAppProducts: boolean;
  headerPanelRes: any;
  public isMobileView: boolean;
  public isComicPage: boolean;
  subscribeURL = subscribeURL;
  @Input() progressBarData: any;
  topLevelSubscription: string;
  public isHomePage: boolean;
  public isDirectoryPage: boolean;
  accessAllowed: boolean;
  isSubscribePage: boolean;
  public actions = ACTIONS;
  renderImagePanel: boolean;

  constructor(
    private readonly comicService: ComicService,
    private readonly newComicService: NewComicService,
    public panelService: PanelService,
    private localStorageService: LocalStorageService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private appService: AppService,
    private readonly userService: UserService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.isComicPage = this.comicService.isComicPage();
      this.isHomePage = this.newComicService.isHomePage();
      this.isDirectoryPage = this.newComicService.isDirectoryPage();
      this.isSubscribePage = this.newComicService.isSubscribePage();
      if (window.innerWidth <= 991) {
        this.isMobileView = true;
      }

      this.checkUserComicAccess();

      this.currentDevice = this.comicService.getOperatingSystem();
      this.currentUrl = this.comicService.getCurrentUrl();
      this.comicService.productIDs$.subscribe(val => {
        this.currentUserSubscriptions = (val && JSON.parse(val)) || [];
        this.setChapters(this.headerPanelRes);
      })

      this.currentUserSubscriptions = this.localStorageService.getItem('productID') || [];
      this.getHeaderPage();
    }
  }

  public getHeaderPage() {
    if (!this.progressBarData) {
      this.comicService.getHeaderPanel().subscribe((res) => {
        this.headerPanelRes = res;
        this.setChapters(this.headerPanelRes);
      }, err => {
        console.log(err);
        this.chapter = [];
      });
    } else {
      const res = {
        comics: {
          panels: [this.progressBarData]
        }
      };
      this.headerPanelRes = res;
      this.setChapters(res, true);
    }

    this.url = ((this.currentUrl != '/') && (this.currentUrl != '/tinyview/comic-series-directory') && (this.currentUrl != subscribeURL)) ? this.currentUrl : '/';
    if (this.isSubscribePage) this.comicService.getSubscriptionMeterAggregates(this.comicService.formatSeriesName(this.url).replace('/footer.json', '')).then((data) => {
      if (data && data.data) {
        for (var i in data.data.details) {
          if (i == 'com.tinyview.subscriptions') {
            this.totalAggregates = data.data.totalAmount;
            this.renewCount = data.data.details[i].count ? data.data.details[i].count : '';
          }
        }
      }
    });
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image, this.currentUrl);
  }

  getTopLevelPurchasedSubscription() {
    if (this.currentUserSubscriptions && this.currentUserSubscriptions.length > 0) {
      if (this.currentUserSubscriptions.includes(productIDs.pizza)) {
        return productIDs.pizza;
      } else if (this.currentUserSubscriptions.includes(productIDs.artsupplies)) {
        return productIDs.artsupplies;
      } else if (this.currentUserSubscriptions.includes(productIDs.coffee)) {
        return productIDs.coffee;
      } else if (this.currentUserSubscriptions.includes(productIDs.bagel)) {
        return productIDs.bagel;
      } else if (this.currentUserSubscriptions.includes(productIDs.cookie)) {
        return productIDs.cookie;
      }
    }
    return null
  }

  public async setChapters(res: any, dontMergeProgressBarData?: boolean) {
    if (this.chapter.some(item => item.template === templateTypes.carousel)) return;
    if (!res || !res.comics || !res.comics.panels) return;
    if (!dontMergeProgressBarData && this.progressBarData && res && res.comics && res.comics.panels) {
      res.comics.panels.unshift(this.progressBarData);
    }
    this.chapter = [];
    const purchasedSubID = this.getTopLevelPurchasedSubscription();
    this.topLevelSubscription = this.getTopLevelPurchasedSubscription();
    for (const panelItem of res.comics.panels) {
      // Check for subscription
      const isPanelForCurrentUser = this.panelService.isPanelShownToCurrentUser(panelItem, purchasedSubID);
      // Check for max-version
      const isAllowedPanelVersion = this.panelService.isAllowedPanelVersion(panelItem);
      if (!isPanelForCurrentUser || !isAllowedPanelVersion) {
        continue;
      } else {
        this.showInAppProducts = true;
      }

      if (panelItem.inAppProducts && panelItem.template && (panelItem.template == "subscribers")) {
        this.showInAppProducts = true;
        if (panelItem.inAppProducts) {
          for (const key in panelItem.inAppProducts) {
            if (panelItem.inAppProducts.hasOwnProperty(key)) {
              const reqObject = panelItem.inAppProducts[key];
              const requirePurchaseKey = Object.keys(reqObject)[0];
              const needsPurchase = reqObject[requirePurchaseKey];
              this.showInAppProducts = false;
              if (needsPurchase && purchasedSubID == requirePurchaseKey) {
                this.showInAppProducts = true;
                break;
              } else if (!needsPurchase && purchasedSubID != requirePurchaseKey) {
                this.showInAppProducts = true;
              } else if (!needsPurchase && purchasedSubID == requirePurchaseKey) {
                this.showInAppProducts = false
                break;
              }
            }
          }
        }
      } else if (panelItem.template && (panelItem.template == "subscribers")) {
        this.showInAppProducts = true;
      }
      // stopping app chapter from getting inserted into array
      if (panelItem.actionType === 'appStore') {
        this.showInAppProducts = false;
      }

      // For some actions
      if (ACTIONS.includes(panelItem.action)) {
        this.showInAppProducts = true;
      }

      // For some actionType
      if (ALLOWED_ACTION_TYPES.includes(panelItem.actionType)) {
        this.renderImagePanel = true;
        this.showInAppProducts = true;
      }

      // Need to skip this panel if max version is present
      const checkForMaxVersion = !panelItem.versions || !panelItem.versions.find(version => version.maxVersion);

      if (this.showInAppProducts && checkForMaxVersion && panelItem.template != templateTypes.carousel) {
        // check to prevent panel items from pushing when we have 2 goal panels in index with different props
        if (!this.chapter.find(ch => ch.goal && (ch.goal == panelItem.goal))) {
          // check to prevent exact panel items from pushing again
          if (!this.chapter.some(item => JSON.stringify(item) === JSON.stringify(panelItem))) {
            this.chapter.push({
              ...panelItem
            })
          }
        }
      }
      if (panelItem.template && (panelItem.template == templateTypes.carousel) && (this.newComicService.isHomePage() || this.newComicService.isComicPage()) && checkForMaxVersion) {
        // To get list items for Continue Reading and Explore Other Series
        if (panelItem.carouselType === carouselType.continueReading) {
          const seriesWiseComicList = await this.appService.getSeriesCarousel({ type: '' });
          if (panelItem.showMax > 0 && seriesWiseComicList) seriesWiseComicList.splice(panelItem.showMax);
          panelItem['list'] = seriesWiseComicList;
        } else if (panelItem.carouselType === carouselType.otherSeries) {
          const seriesWiseComicList = await this.appService.getSeriesCarousel({ type: 'series' });
          const userData = await this.userService.getUserDetails();
          const seriesFollowed = userData.data.data.alerts;
          const remainingSeries = this.getUnfollowedSeries(seriesWiseComicList, seriesFollowed);

          if (panelItem.showMax > 0 && remainingSeries) remainingSeries.splice(panelItem.showMax);
          panelItem['list'] = remainingSeries;
        }
        this.chapter.push({
          ...panelItem
        })
      }
    }
  }

  public getUnfollowedSeries(allSeriesDetails, seriesFollowed) {

    return allSeriesDetails.filter(series => !seriesFollowed[series.series] && series.series !== TINYVIEW);
  }

  async navigate(buttonType: string) {
    if (buttonType && buttonType.toLowerCase() === 'share') {
      this.comicService.share();
      return;
    }
    this.router.navigate([subscribeURL]);
  }

  public showButton(cta: string): boolean {
    if (this.currentDevice === BROWSER.DESKTOP && cta && cta.toLowerCase() === 'share') {
      return false;
    }
    return true;
  }

  async checkUserComicAccess() {
    const accessAllowed = await this.comicService.checkUserComicAccess();
    if (accessAllowed.hasAllComicAccess) {
      this.accessAllowed = true;
      return;
    }
    this.accessAllowed = false;
  }
}
