<form class="d-flex" [formGroup]="form">
  <div class="mr-4">
    <div class="p-bold">Month <img [src]="getImagePath('ICONS8_EXPAND_ARROW')" alt="\/" class="chevron month"></div>
    <div class="custom-select date mt-1">
      <select formControlName="month" (change)="onMonthChange($event)">
        <option *ngFor="let m of getAvailableMonths()" [value]="m.number">{{ m.name }}</option>
      </select>
    </div>
  </div>
  <div class="ml-1 mr-4">
    <div class="p-bold">Year <img [src]="getImagePath('ICONS8_EXPAND_ARROW')" alt="\/" class="chevron year"></div>
    <div class="custom-select date mt-1">
      <select formControlName="year" (change)="onYearChange($event)">
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
      </select>
    </div>
  </div>
  <div class="ml-1 mr-4" *ngIf="!isReferralPage">
    <div class="p-bold">Series <img [src]="getImagePath('ICONS8_EXPAND_ARROW')" alt="\/" class="chevron series"></div>
    <div class="custom-select series mt-1">
      <select formControlName="series">
        <option *ngFor="let s of seriesToShow" [value]="s.value">{{ s.title }}</option>
      </select>
    </div>
  </div>
  <div class="ml-1 pb-1 d-flex flex-column justify-content-end">
    <a class="show-btn" (click)="prepareDataAndEmit()">
      <p *ngIf="!isLoading">Show</p>
      <div class="loader" *ngIf="isLoading"></div>
    </a>
  </div>
</form>