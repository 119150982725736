import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiMap } from '../constants/apiUrlMapper';
import * as firebase from "firebase/app";
import { CacheService } from './cache.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService
  ) { }

  private callFirebaseFunction(functionName: string) {
    return async (data: any = {}): Promise<any> => {
      // if Firebase functions are being used
      const firebaseFunctionName = apiMap[functionName]['fbFunction'];
      const firebaseFunction = firebase.functions().httpsCallable(firebaseFunctionName);
      return firebaseFunction(data);
    };
  }

  filterValidData(data: { [key: string]: any } | any[]): { [key: string]: any } | any[] {
    if (Array.isArray(data)) {
      // If the data is an array, map over each element and filter it
      return data.map(item => this.filterValidData(item));
    } else if (typeof data === 'object' && data !== null) {
      // If the data is an object, filter out invalid key-value pairs
      return Object.keys(data).reduce((filtered, key) => {
        const value = data[key];
        if (value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)) {
          filtered[key] = value;
        }
        return filtered;
      }, {} as { [key: string]: any });
    }
    return data;  // Return non-object data types unchanged (e.g., strings, numbers)
  }

  private callRestApi(method: string, url: string) {
    return async (data: any = {}): Promise<any> => {
      const authToken = await firebase.auth().currentUser.getIdToken(); // Retrieve the Firebase JWT token

      const headers = new HttpHeaders({
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      });
      let response: any;

      switch (method) {
        case 'GET':
          data = this.filterValidData(data);
          const queryParams = new URLSearchParams(data).toString();
          url = `${url}${queryParams ? '?' + queryParams : ''}`;
          response = await this.http.get(url, { headers }).toPromise();
          break;
        case 'POST':
          response = await this.http.post(url, { data }, { headers }).toPromise();
          break;
        case 'PUT':
          data = this.filterValidData(data);
          response = await this.http.put(url, { data }, { headers }).toPromise();
          break;
        case 'DELETE':
          data = this.filterValidData(data);
          const deleteParams = new HttpParams({ fromObject: data });
          response = await this.http.delete(url, { params: deleteParams, headers }).toPromise();
          break;
        default:
          throw new Error(`Unsupported method: ${method}`);
      }
      if (response && response.result) {
        response.data = response.result;
        delete response.result;
      }
      return response;
    };
  }

  public async send<T = any>(functionName: string): Promise<(data?: any) => Promise<T>> {
    const { url, method } = this.getApiDetails(functionName);
    return this.callRestApi(method, url);
  }

  private getApiDetails(functionName: string): { method: string, url: string } {
    const apiUrlMapper = apiMap;
    return {
      url: environment.API_BASE_URL + apiUrlMapper[functionName].url,
      method: apiUrlMapper[functionName].method
    };
  }
}