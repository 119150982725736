import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { ComicService } from '../services/comic.service';
import { PanelService } from '../services/panel/panel.service';
import { StoryFeedService } from '../services/story-feed.service';
import { LocalStorageService } from '../services/local-storage.service';
import { convertToTitleCase } from '../utilities/common.util';
import { getImage } from '../constants/images.constants';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-comic-toc',
  templateUrl: './comic-toc.component.html',
  styleUrls: ['./comic-toc.component.scss', './../comic/comic.component.scss']
})
export class ComicTocComponent implements OnInit {
  @Input() comicData;
  @Input() hideBottomSection?: boolean;
  @Input() isUpload: boolean = false;
  @Input() uploadURL: string = '';
  @Input() isRounded;
  @Output() modal? = new EventEmitter();
  @Output() clicked = new EventEmitter();
  @Input() indexOfelement?: any;
  @Input() isCreatorUI: boolean;
  nextRoute: string;
  currentUrl: string;
  currentDevice: string;
  renderDownloadAndroid: boolean;
  renderDownloadIos: boolean;
  renderDesktop: boolean;
  smsResponse: string;
  href: string;
  routePath: string;
  query: string;
  isFollow: boolean;
  followText: string;
  pageInfo: firebase.functions.HttpsCallableResult;
  action: any;
  comicDate: string;
  isLike: boolean;
  domainName: any;
  nextRouteForImage: any;
  isSeriesHomePage: boolean;
  comicAction: any;
  checkActionType: boolean;
  showShare: boolean = false;
  prodID: any;
  comic: boolean;
  storyPage: boolean;
  isComicPage: boolean;
  loadingStory: boolean;
  externalButtonText: string;
  showReadTicks: any;
  storyID: string;

  constructor(
    public feedService: StoryFeedService,
    private readonly router: Router,
    public readonly comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private localStorageService: LocalStorageService,
    public panelService: PanelService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.subscribedComic(this.comicData);
    this.domainName = this.comicData.actionType == "website" ? this.comicData.action.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : "tinyView.com";
    this.currentDevice = this.comicService.getOperatingSystem();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.isComicPage = this.comicService.isComicPage();
    this.storyPage = this.comicService.isStoryPage();
    this.checkActionType = this.checkAction(this.comicData);
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.comicDate = this.comicData.datetime ? this.comicService.formatDate(new Date(this.comicData.datetime)) : '';
    this.comicAction = this.comicService.getComicAction(this.comicData); // why is it two time here? line 70
    this.nextRoute = this.getNavigateUrl();
    if (this.nextRoute && this.nextRoute.indexOf('fn=') != -1) {
      this.query = this.nextRoute.slice(this.nextRoute.indexOf('fn=') + 3, this.nextRoute.length);
      this.routePath = this.nextRoute.slice(0, this.nextRoute.indexOf('?'));
    }
    this.nextRouteForImage = this.getImageNavigateUrl();
    if (this.nextRouteForImage && this.nextRouteForImage.indexOf('fn=') != -1) {
      this.query = this.nextRouteForImage.slice(this.nextRouteForImage.indexOf('fn=') + 3, this.nextRouteForImage.length);
      this.routePath = this.nextRouteForImage.slice(0, this.nextRouteForImage.indexOf('?'));
    }

    this.comicService.productIDs$.subscribe(val => {
      this.prodID = (val && JSON.parse(val)) || [];
      this.subscribedComic(this.comicData);
    })

    this.loadingStory = true;
    const storyMeta = {};
    if (this.comicData.actionType === 'website') {
      storyMeta['url'] = this.comicData.action || '';
      storyMeta['description'] = this.comicData.description || '';
      storyMeta['title'] = this.comicData.title || '';
      storyMeta['series'] = this.comicData.series || '';
    }

    if (this.comicData.series) {
      storyMeta['series'] = this.comicData.series;
    }

    // Don't call this on creator UI page
    if (!this.isCreatorUI) {
      let getStory;
      if (this.comicData && this.comicData.storyID) {
        getStory = this.feedService.getStoryByID(this.comicData.storyID)
      } else {
        getStory = this.feedService.getStory(this.comicData.action, this.comicData.image || '', storyMeta);
      }
      getStory.then(async (res) => {
        if (res && res.data && res.data.data) {
          const resData = res.data.data;
          const updatedData = await this.appService.updateComicDataWithConfig(resData);
          // We are using image, comments and title originally from JSON
          this.comicData = {
            ...updatedData,
            image: this.comicData.image,
            comments: this.comicData.comments,
            title: this.comicData.title,
          }
          this.showReadTicks = this.comicData.isRead;
          this.loadingStory = false;
          this.storyID = this.comicData.storyID || '';
        }
      }, err => {
        console.log(err);
        this.loadingStory = false;
      });
    }
    // check for video websites
    this.externalButtonText = this.comicService.isVideoWebsiteDomain(this.comicData.domain);
  }

  public subscribedComic(imageData) {
    if (isPlatformBrowser(this.platform)) {
      this.prodID = this.localStorageService.getItem('productID');
      if (this.isCreatorUI) {
        this.comic = true;
      } else if (imageData && imageData.inAppProducts) {
        for (const key in imageData.inAppProducts) {
          const reqObject = imageData.inAppProducts[key];
          const requirePurchaseKey = Object.keys(reqObject)[0];
          const needsPurchase = reqObject[requirePurchaseKey];
          this.comic = false;
          const productPurchased = (this.prodID || []).includes(requirePurchaseKey);
          if (needsPurchase && productPurchased) {
            this.comic = true;
            break;
          } else if (!needsPurchase && !productPurchased) {
            this.comic = true;
          } else if (!needsPurchase && productPurchased) {
            this.comic = false;
            break;
          }
        }
      } else {
        this.comic = true;
      }
    }
  }

  onImageError(event: Event) {
    (event.target as HTMLImageElement).src = this.getImagePath('MALE_USER');
  }

  public checkAction(comicData) {
    if (comicData.action == 'download-app-android' || comicData.action == 'download-app-ios' || comicData.action == 'share' || comicData.action == 'restore-purchases') {
      return true;
    } else {
      return false;
    }
  }

  getViewsForExternalLink($event?) {
    this.comicService.viewsForExternalLink(this.comicAction, 1, this.storyID);
    if ($event) $event.stopPropagation();
  }

  navigateExternalLinkToStoryPage() {
    if (!this.storyPage) {
      this.router.navigate(['story', this.storyID]);
    } else {
      this.openLinkInNewTab(this.comicAction);
    }
  }

  getImageAction() {
    const currentPage = this.localStorageService.getItem('selectedMenuItem');
    if (currentPage === 'series') {
      this.localStorageService.setItem('storyID', '');
    }
    if (this.checkActionType && !this.comicData.button) {
      this.navigateByActions()
    } else if (!this.isWebsite()) {
      this.onImageClick()
    }
  }

  public navigateByActions() {
    this.comicService.navigateByActions(this.comicData);
  }

  public getImageNavigateUrl(): string {
    const currentUrl = this.comicService.getCurrentUrl();
    if (this.comicData && !this.comicData.action) {
      return null;
    }
    const action = this.comicService.resolvePath(currentUrl, this.comicData.action);
    return this.comicService.resolveJsonInAction(action);
  }

  public isTinyView(): boolean {
    return this.comicData.actionType === 'tinyview';
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }

  public getImgUrl(data: any) {
    let seriesURL = this.currentUrl;
    if (this.currentUrl && this.currentUrl.startsWith('/edit;comic=')) {
      seriesURL = decodeURIComponent(seriesURL).replace('/edit;comic=', '');
    }
    return this.panelService.getImgUrl(data.image, seriesURL);
  }


  public getNavigateUrl(): string {
    if (this.comicData && !this.comicData.action) {
      return null;
    }
    const action = this.comicService.resolvePath(this.currentUrl, this.comicData.action);
    return this.comicService.resolveJsonInAction(action);
  }

  public openApp(): void {
    this.comicService.openInStore();
  }

  // public getSeparatorBorder(): Object {
  //   return this.comicService.getSeparatorBorder(this.comicData)
  // }

  // public getPanelBorder(): Object {
  //   let res = {};
  //   if (this.comicData.panelBorder == undefined || this.comicData.panelBorder == null) {
  //     return res;
  //   }
  //   res['border-color'] = this.comicData.panelBorder['border-color'];
  //   res['border-style'] = 'solid';
  //   res['border-left'] = 'none';
  //   res['border-right'] = 'none';
  //   res['margin'] = '10px 0px';
  //   res['border-bottom'] = !this.comicData.panelBorder['border-bottom'] ? 'none' : this.comicData.panelBorder['border-bottom'];
  //   res['border-top'] = !this.comicData.panelBorder['border-top'] ? 'none' : this.comicData.panelBorder['border-top'];
  //   res['border-width'] = typeof this.comicData.panelBorder['border-width'] == 'number' ? this.comicData.panelBorder['border-width'] + 'px' : this.comicData.panelBorder['border-width'];
  //   return res;
  // }

  public getImageBorder(): Object {
    return this.comicService.getImageBorder(this.comicData);
  }

  public onImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    let route = this.nextRoute.indexOf('fn=') != -1 ? this.routePath : this.nextRoute;
    if (this.query) {
      this.router.navigate([route], { queryParams: { fn: this.query } });
    } else {
      this.router.navigate([route]);
    }
    this.clicked.emit();
  }

  public getCommentAction() {
    if (this.isWebsite()) {
      this.openLinkInNewTab(this.comicAction);
    } else {
      this.getImageAction();
    }
  }

  openLinkInNewTab(link) {
    this.getViewsForExternalLink();
    window.open(link, '_blank');
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str);
    return firstLetters;
  }

  public getBadgeURL(badgeName: string) {
    return this.comicService.mapUserBadge(badgeName, 50);
  }

  public formatSeriesName(name: string) {
    const formattedResult = convertToTitleCase(name);
    return formattedResult;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  public showRead($event) {
    this.showReadTicks = $event.show;
  }
}
