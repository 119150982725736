import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NewComicService } from '../new-comic.service';
import { MANAGE_ALERTS_MAPPING, emailVerificationURL, phoneVerificationURL } from '../constants/common.constants';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { debounceTime } from 'rxjs/operators';
import { formatPhoneNumber } from '../utilities/user.util';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { getImage } from '../constants/images.constants';
import { ComicService } from '../services/comic.service';
import { convertToTitleCase } from '../utilities/common.util';

@Component({
  selector: 'app-manage-alerts',
  templateUrl: './manage-alerts.component.html',
  styleUrls: ['./manage-alerts.component.scss']
})
export class ManageAlertsComponent implements OnInit, OnDestroy {
  public alertsForm: FormGroup;

  public alertsConfig = [
    {
      key: '',
      id: 'push',
      show: true,
      subAlerts: [],
      disable: false
    },
    {
      key: '',
      subKey: '',
      verified: false,
      id: 'email',
      show: true,
      subAlerts: []
    },
    {
      key: '',
      id: 'sms',
      subKey: '',
      verified: false,
      show: false, // To Show/ Hide SMS settings
      subAlerts: []
    }
  ];

  public subAlertsConfig = [
    { // 0
      key: '',
      id: 'publishComic', // New content from series you follow
      show: false,
      channel: ['push', 'email', 'sms'],
      user: ['creator', 'non-creator']
    },
    { // 1
      key: '',
      id: 'giftUser', // A friend sends a gift
      show: false,
      channel: ['push'],
      user: ['creator', 'non-creator']
    },
    { // 2
      key: '',
      id: 'likeStory', // A friend likes
      show: false,
      channel: ['push'],
      user: ['creator', 'non-creator']
    },
    { // 3
      key: '',
      id: 'likeComment', // Someone likes your comment ||  A user likes your comment
      show: false,
      channel: ['push'],
      user: ['creator', 'non-creator']
    },
    { // 4
      key: '',
      id: 'commentOnStory', //A friend comments
      show: false,
      channel: ['push'],
      user: ['creator', 'non-creator']
    },
    { // 5
      key: '',
      id: 'friendActivity', // Friend activity
      show: false,
      channel: ['push', 'email', 'sms'],
      user: ['creator', 'non-creator']
    },
    { // 6
      key: '',
      id: 'influencePoints', // Influence points
      show: false,
      channel: ['push'],
      user: ['creator', 'non-creator']
    },
    { // 7
      key: '',
      id: 'commentOnStoryCreator', // A user comments
      show: false,
      channel: ['push'],
      user: ['creator']
    },
    { // 8
      key: '',
      id: 'giftCreator', // A user sends a gift
      show: false,
      channel: ['push', 'email', 'sms'],
      user: ['creator']
    },
    { // 9
      key: '',
      id: 'flagComment', // A user reports a comment
      show: false,
      channel: ['push', 'email', 'sms'],
      user: ['creator']
    },
    // {
    //   key: 'A friend likes your comment',
    //   id: 'friendLikesComment',
    //   show: false
    // },
  ]

  switchConfig = {
    value: false,
    height: 28,
    width: 48,
    margin: 1.5,
    speed: 300,
    color: {
      checked: '#35C759',
      unchecked: '#E8E8E8',
    },
    switchColor: {
      checked: '#fffff',
      unchecked: '#fffff',
    }
  };
  isMobile: boolean;
  formChangesSubscription: Subscription;
  userDetails: any;
  isEmailVerified: boolean;
  isUserDetailsLoading: boolean;
  isPhoneVerified: boolean;
  emaiVerificationSuccess: boolean;
  phoneVerificaitonSuccess: boolean;
  isSeriesCreator: boolean;
  modalRef: any;
  series: any;
  successToastDisplayed: boolean;
  comicHadBonusPanel: boolean;
  isSubscriber: boolean;

  constructor(
    private fb: FormBuilder,
    private newComicService: NewComicService,
    private userService: UserService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private readonly comicService: ComicService
  ) {
    if (this.route.snapshot.queryParams.series) {
      const series = this.route.snapshot.queryParams.series; 
      this.series = JSON.parse(series);
    }

    if (this.route.snapshot.queryParams.emailVerificationState === 'success') {
      this.emaiVerificationSuccess = true; 
      this.showVerificationSuccessToaster();
    }
    if (this.route.snapshot.queryParams.hasBonusPanel) {
      const comicHadBonusPanel = this.route.snapshot.queryParams.hasBonusPanel
      this.comicHadBonusPanel = JSON.parse(comicHadBonusPanel)
    }
    // if (this.route.snapshot.queryParams.phoneVerificationState === 'success') {
    //   this.toastr.success('Your phone number is verified.');
    //   this.phoneVerificaitonSuccess = true; 
    // }
  }

  ngOnInit() {
    this.isMobile = this.newComicService.isMobileView();
    this.alertsForm = this.fb.group({});
    this.getUserDetails();
    this.createAlertsConfig();
    this.createFormControls();

    this.formChangesSubscription = this.alertsForm.valueChanges
      .pipe(debounceTime(1000)) // Apply a 1000ms debounce
      .subscribe((change) => {
        if ((!this.isEmailVerified && change.email.email) || (!this.isPhoneVerified && change.sms.sms)) {
          return;
        }
        const notificationSettings = this.transformFormData(this.alertsForm.value);
        this.userService.setNotificationSettings(notificationSettings).then(result => {
        }, () => { })
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from form value changes to prevent memory leaks
    this.formChangesSubscription.unsubscribe();
  }

  showVerificationSuccessToaster(show?:boolean) {
    if (this.series.action && !show) return;
    const msg = `Your email address is verified.`;
    this.toastr.success(msg);
  }

  getUserDetails() {
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
    this.isUserDetailsLoading = true;
    this.userService.getUserDetails().then((data) => {
      this.userDetails = data && data.data && data.data.data;
      this.checkForEmailAndPhone();
      this.updateFormValues(this.alertsForm, this.userDetails.notificationSettings);
      this.disableNotificaitonChannel('push')
      this.isUserDetailsLoading = false;
    }, () => {
      this.isUserDetailsLoading = false;
    })
  }

  createAlertsConfig() {
    this.isSeriesCreator = this.localStorageService.getItem('isSeriesCreator') == true;
    const USER_TYPE = this.isSeriesCreator ? 'creator' : 'non-creator';
    this.alertsConfig = this.alertsConfig.map(alert => {
      if (MANAGE_ALERTS_MAPPING[alert.id]) {
        alert.key = MANAGE_ALERTS_MAPPING[alert.id];
        this.subAlertsConfig = this.subAlertsConfig.map(subAlert => {
          if (MANAGE_ALERTS_MAPPING[subAlert.id] && subAlert.user.includes(USER_TYPE) && subAlert.channel.includes(alert.id)) {
            subAlert.key = MANAGE_ALERTS_MAPPING[subAlert.id];
            subAlert.show = true;
          } else {
            subAlert.show = false;
          }
          return subAlert;
        })
      }
      alert.subAlerts = JSON.parse(JSON.stringify(this.subAlertsConfig));
      return alert;
    })
  }

  createFormControls() {
    this.alertsConfig.forEach(alert => {
      const alertGroup = this.fb.group({});
      alertGroup.addControl(alert.id, new FormControl(false));
      const subAlertGroup = this.fb.group({});
      alert.subAlerts.forEach(subAlert => {
        subAlertGroup.addControl(subAlert.id, new FormControl(false));
      });
      alertGroup.addControl('subAlerts', subAlertGroup);
      this.alertsForm.addControl(alert.id, alertGroup);
    });
  }

  public checkForEmailAndPhone() {
    this.alertsConfig = this.alertsConfig.map(alert => {
      if (alert.id === 'sms') {
        if (this.userDetails.phoneNumber) {
          alert['subKey'] = formatPhoneNumber(this.userDetails.phoneNumber);
          alert['verified'] = this.isPhoneVerified = true;
        }
      }
      if (alert.id === 'email') {
        if (this.userDetails.email) {
          alert['subKey'] = this.userDetails.email;
          alert['verified'] = this.isEmailVerified = true;
        }
      }
      return alert;
    });
  }

  public disableNotificaitonChannel(alertID: string) {
    const isPushDisabled = this.userDetails && this.userDetails.hasApp ? false : true;
    if (isPushDisabled) {
      this.alertsConfig.map(alert => {
        if (alert.id === alertID && isPushDisabled) {
          alert.disable = true;
          alert.subAlerts.forEach(subAlert => {
            subAlert.show = false;
          });
        }
      });
    }
  }

  public async toggleAlert(alertID: string, checkAuth: boolean) {
    const groupControl = this.alertsForm.get(alertID) as FormGroup;
    if (alertID === 'email' && !this.isEmailVerified && checkAuth && groupControl.value[alertID]) {
      this.router.navigate([emailVerificationURL], { queryParams: { series: JSON.stringify(this.series) }}); // [TODO]: For sms, 
      return;
    }
    if (alertID === 'sms' && !this.isPhoneVerified && checkAuth && groupControl.value[alertID]) {
      this.router.navigate([phoneVerificationURL]);
      return;
    }
    const isPushDisabled = this.userDetails && this.userDetails.hasApp ? false : true;
    const checkAlrertOn = ((groupControl.controls.email && groupControl.controls.email.value === true) || (groupControl.controls.push && groupControl.controls.push.value === true && !isPushDisabled));

    const userData = await this.userService.getUserDetails();
    const alerts = userData.data.data.alerts;
    const normalizedSeries = this.series && this.series.action.replace(/^\/|\/index\.json$/g, '');
    const isFollowingSeries = Object.keys(alerts).includes(normalizedSeries);
    if (checkAlrertOn && this.series && this.series.action && !isFollowingSeries) {
      this.followSeries();
    } else if (isFollowingSeries && this.emaiVerificationSuccess) {
      this.showVerificationSuccessToaster(true);
    } 
  }

  public async followSeries() {
    await this.comicService.addRemoveLike(this.series.action, true, true, {showToast: false, series: this.series} );
    if (this.successToastDisplayed) return;
    let message: string;
    if (this.emaiVerificationSuccess) {
      message = `Your email address is verified. You're following ${this.series.title} now.`
    } else if (this.comicHadBonusPanel && !this.isSubscriber) {
      message = `Thanks for following ${this.series.title}! You can read bonus panels of ${this.series.title} now.`;
    } else {
      message = `You are now following ${this.series.title}. Thank you!`
    }
    this.toastr.success(message);
    this.successToastDisplayed = true;
  }

  transformFormData(formData: any): any {
    const transformedData = {
      notificationSettings: {}
    };

    for (const key of Object.keys(formData)) {
      transformedData.notificationSettings[key] = {
        enabled: !!formData[key][key],
        events: {}
      };

      for (const eventKey of Object.keys(formData[key].subAlerts)) {
        transformedData.notificationSettings[key].events[eventKey] = !!formData[key].subAlerts[eventKey];
      }
    }

    return transformedData;
  }

  updateFormValues(form: FormGroup, values: any): void {
    for (const [key, value] of Object.entries<any>(values)) {
      const formGroup = form.get(key) as FormGroup;
      if (!formGroup) continue;

      if ('enabled' in value) {
        if (key === 'email' && this.emaiVerificationSuccess) value.enabled = true;
        if (key === 'sms' && this.phoneVerificaitonSuccess) value.enabled = true;
        formGroup.patchValue({ [key]: value.enabled }, { emitEvent: false });
        if (value.enabled) this.toggleAlert(key, false);
      }

      if ('events' in value && value.events) {
        const groupControl = form.get(key) as FormGroup;
        if (groupControl) {
          const subAlertsControl = groupControl.get('subAlerts') as FormGroup;
          if (subAlertsControl) {
            for (const [subSubKey, subSubValue] of Object.entries(value.events)) {
              const control = subAlertsControl.get(subSubKey);
              if (control instanceof FormControl) {
                control.patchValue(subSubValue, { emitEvent: false });
              }
            }
          }
        }
      }

      const subAlertsControl = formGroup.get('subAlerts') as FormGroup;
      if (subAlertsControl) {
        for (const [subKey, subControl] of Object.entries(subAlertsControl.controls)) {
          if (formGroup.get(subKey) instanceof FormControl) {
            formGroup.get(subKey).patchValue(subControl.value, { emitEvent: false });
          }
        }
      }
    }

    if (this.emaiVerificationSuccess || this.phoneVerificaitonSuccess) {
      const notificationSettings = this.transformFormData(this.alertsForm.value);
      this.userService.setNotificationSettings(notificationSettings);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-layout',
      ignoreBackdropClick: false
    }));
  }

  onOpenAppClick(template: TemplateRef<any>) {
    if (!this.isMobile) {
      this.openModal(template);
    } else {
      window.open('https://social.tinyview.com/install');
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
