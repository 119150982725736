<div class="container" *ngIf="configData.list && configData.list.length > 0">
  <div class="d-flex justify-content-between mx-3 p-1">
    <h1>{{configData.title}}</h1>
    <div *ngIf="configData.button" class="pt-1"><a class="body-mini" (click)="navigateTo(configData.button)">{{configData.button}}</a></div>
  </div>
  <div class="cdk-virtual-scroll">
    <cdk-virtual-scroll-viewport id="viewport" orientation="horizontal" itemSize="11" class="viewport">
      <ng-container *cdkVirtualFor="let toc of configData.list">
        <div *ngIf="toc.image" class="item">
          <img [src]="getImagePath(toc.image)" alt="Image" (click)="navigateToURL(toc)" [class.disabled]="toc.disable" class="toc-image" onError="getImagePath('PREVIEW_IMAGE_NOT_AVAILABLE')">
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
