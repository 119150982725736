<div class="container" *ngIf="!error">
  <div class="mt-1">
    <h1 class="mt-4 pt-2 mb-4 pb-3">Hi <span *ngIf="userName">, {{userName}}</span></h1>
  </div>
  <div class="row">
    <div class="col-auto custom-filter-margin">
      <app-series-filter (sendFilterData)="onDataReceived($event)" [isReferralPage]="true" [isLoading]="isLoading"></app-series-filter>
      <div *ngIf="!isLoading && !tableError" class="mt-5">
        <p class="mb-2 pb-1 text-start">{{calculatedFor}}</p>
      </div>
    </div>
  </div>
  <app-creator-stat-table *ngIf="!tableError && !isLoading" [tableHead]="tableHead" [calculatedDailyDataView]="calculatedDailyDataView" [monthlyAggregateView]="monthlyAggregateView"></app-creator-stat-table>
  <div class="text-center comments mt-5" *ngIf="tableError">
    {{tableError}}
  </div>
  <div class="loader-container" *ngIf="isLoading">
    <img class="loader-content" [src]="getImagePath('ICONS8_LOADING_V2')" alt="loader">
  </div>
</div>

<div class="container h3 text-center mt-5" *ngIf="error">
  {{error}}
</div>
