import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Subscription } from 'rxjs';
import { ComicService } from '../services/comic.service';
import { PanelService } from '../services/panel/panel.service';
import { StoryFeedService } from '../services/story-feed.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NewComicService } from '../new-comic.service';
import { convertToTitleCase } from '../utilities/common.util';
import { getImage } from '../constants/images.constants';

@Component({
  selector: 'app-comic-story',
  templateUrl: './comic-story.component.html',
  styleUrls: ['./comic-story.component.scss', './../comic/comic.component.scss']
})
export class ComicStoryComponent implements OnInit {
  date: any;
  likes: boolean = false;
  viewText: string;
  likeText: string;
  isLike: boolean;
  nextRoute: string;
  nextRouteForImage: string;
  currentUrl: string;
  userSubscription: Subscription;
  currentDevice: string;
  renderDownloadAndroid: boolean;
  renderDownloadIos: boolean;
  renderDesktop: boolean;
  smsResponse: string;
  href: string;
  routePath: string;
  query: string;
  domainName: string;
  pageInfo: firebase.functions.HttpsCallableResult;
  @Input() record: number;
  @Input() indexOfelement: any;
  @Input() comicData;
  @Output() clicked = new EventEmitter();
  isHomePage: boolean;
  checkActionType: boolean;
  comicAction: any;
  showShare: boolean = false;
  arr: number[];
  diffDate: any
  showString: any;
  latestDate: any;
  prodID: any;
  storyPage: boolean;
  isCacheData: any;
  refTypeCondition: boolean;
  comicDate: string;
  isMobile: boolean;
  externalButtonText: string;
  showReadTicks: boolean;
  storyID: string;

  constructor(
    public feedService: StoryFeedService,
    public panelService: PanelService,
    private readonly router: Router,
    public comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private localStorageService: LocalStorageService,
    private newComicService: NewComicService
  ) { }

  ngOnInit() {
    this.isMobile = this.newComicService.isMobileView();
    this.isCacheData = this.comicService.cacheComic[this.currentUrl]
    var dateTime = new Date(this.comicData.datetime ? this.comicData.datetime : '');
    this.date = this.comicData.createdAt ? this.comicService.formatDate(dateTime) : '';
    this.storyID = this.comicData.storyID || '';
    this.viewText = this.comicData.viewCount == 1 ? 'View' : 'Views';
    this.likeText = this.comicData.likeCount == 1 ? 'Like' : 'Likes';
    this.domainName = this.comicData.actionType == "website" ? this.comicData.action.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : "tinyView.com";
    this.checkActionType = this.checkAction(this.comicData);
    this.comicAction = this.comicService.getComicAction(this.comicData);
    this.nextRoute = this.getNavigateUrl();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.storyPage = this.newComicService.isStoryPage();
    this.isHomePage = this.newComicService.isHomePage();
    if (this.nextRoute && this.nextRoute.indexOf('fn=') != -1) {
      this.query = this.nextRoute.slice(this.nextRoute.indexOf('fn=') + 3, this.nextRoute.length);
      this.routePath = this.nextRoute.slice(0, this.nextRoute.indexOf('?'));
    }
    this.nextRouteForImage = this.getImageNavigateUrl();
    if (this.nextRouteForImage && this.nextRouteForImage.indexOf('fn=') != -1) {
      this.query = this.nextRouteForImage.slice(this.nextRouteForImage.indexOf('fn=') + 3, this.nextRouteForImage.length);
      this.routePath = this.nextRouteForImage.slice(0, this.nextRouteForImage.indexOf('?'));
    }
    let obj = this.comicData.timings
    if (obj) {
      // Set default values to 0 if properties are not present
      obj.lastComment = obj.lastComment || 0;
      obj.lastRepost = obj.lastRepost || 0;
      obj.lastLiked = obj.lastLiked || 0;
      obj.lastSent = obj.lastSent || 0;

      // Find the latest date
      if (obj.lastComment > obj.lastRepost && obj.lastComment > obj.lastLiked && obj.lastComment > obj.lastSent) {
        this.latestDate = obj.lastComment
      } else if (obj.lastRepost > obj.lastComment && obj.lastRepost > obj.lastLiked && obj.lastRepost > obj.lastSent) {
        this.latestDate = obj.lastRepost
      } else if (obj.lastLiked > obj.lastComment && obj.lastLiked > obj.lastRepost && obj.lastLiked > obj.lastSent) {
        this.latestDate = obj.lastLiked
      } else if (obj.lastSent > obj.lastComment && obj.lastSent > obj.lastRepost && obj.lastSent > obj.lastLiked) {
        this.latestDate = obj.lastSent
      }
    }
    this.diffDate = this.comicService.getDataDiff(this.latestDate);
    if (obj && obj.lastComment == this.latestDate && this.comicData.commentedBy) {
      const timeDuration = this.comicService.getDataDiff(this.latestDate)
      this.showString = timeDuration !== 'Just now' ? timeDuration + ' ago・' + this.comicData.commentedBy : timeDuration + '・' + this.comicData.commentedBy;
    } else if (obj && obj.lastRepost == this.latestDate && this.comicData.repostString) {
      const timeDuration = this.comicService.getDataDiff(this.latestDate)
      this.showString = timeDuration !== 'Just now' ? timeDuration + ' ago・' + this.comicData.repostString : timeDuration + '・' + this.comicData.repostString;
    } else if (obj && obj.lastLiked == this.latestDate && this.comicData.likedBy) {
      const timeDuration = this.comicService.getDataDiff(this.latestDate)
      this.showString = timeDuration !== 'Just now' ? timeDuration + ' ago・' + this.comicData.likedBy : timeDuration + '・' + this.comicData.likedBy;
    } else if (obj && obj.lastSent == this.latestDate && this.comicData.sentString) {
      const timeDuration = this.comicService.getDataDiff(this.latestDate)
      this.showString = timeDuration !== 'Just now' ? timeDuration + ' ago・' + this.comicData.sentString : timeDuration + '・' + this.comicData.sentString;
    } else if (this.comicData.publishedBy) {
      const timeDuration = this.comicService.getDataDiff(this.comicData.createdAt);
      this.showString = timeDuration !== 'Just now' ? timeDuration + ' ago・' + this.comicData.publishedBy : timeDuration + '・' + this.comicData.publishedBy;
    }

    this.refTypeCondition = ['SENT', 'GROUP_SENT', 'REPOST'].includes(this.comicData.refType);
    this.comicDate = this.comicService.formatDate(this.comicData.datetime || this.comicData.createdAt);

    // check for video websites
    this.externalButtonText = this.comicService.isVideoWebsiteDomain(this.comicData.domain);
  }



  public checkAction(comicData) {
    if (comicData.action == 'download-app-android' || comicData.action == 'download-app-ios' || comicData.action == 'share' || comicData.action == 'restore-purchases') {
      return true;
    } else {
      return false;
    }
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str);
    return firstLetters;
  }

  getImageAction() {
    const currentPage = this.localStorageService.getItem('selectedMenuItem');
    if (currentPage === 'home') {
      this.localStorageService.setItem('storyID', this.comicData.storyID);
    } else if (currentPage === 'following') {
      this.localStorageService.setItem('storyID', this.comicData.myFeedID);
    }

    if (this.checkActionType && !this.comicData.button) {
      this.navigateByActions()
    } else if (!this.isWebsite()) {
      this.onUserImageClick()
    }
  }

  public navigateByActions() {
    this.comicService.navigateByActions(this.comicData);
  }

  public isTinyView(): boolean {
    return this.comicData.actionType === 'tinyview';
  }

  public isWebsite(): boolean {
    return this.comicData.actionType === 'website';
  }

  getViewsForExternalLink() {
    this.comicService.viewsForExternalLink(this.comicAction, 1, this.storyID);
  }

  navigateExternalLinkToStoryPage() {
    if (!this.storyPage) {
      this.router.navigate(['story', this.storyID]);
    } else {
      this.openLinkInNewTab(this.comicAction);
    }
  }

  public getNavigateUrl(): string {
    if (this.comicData && !this.comicData.action) {
      return null;
    }
    if (this.comicData.user && this.comicData.user.action) {
      const action = this.comicService.resolvePath(this.currentUrl, this.comicData.user.action);
      return this.comicService.resolveJsonInAction(action);
    }
  }

  public getImageNavigateUrl(): string {
    if (this.comicData && !this.comicData.action) {
      return null;
    }
    const action = this.comicService.resolvePath(this.currentUrl, this.comicData.action);
    return this.comicService.resolveJsonInAction(action);
  }

  onImageError(event: Event) {
    (event.target as HTMLImageElement).src = this.getImagePath('MALE_USER');
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image, '/' + data.series, true);
  }

  public onImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp;
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    let route = this.nextRoute.indexOf('fn=') != -1 ? this.routePath : this.nextRoute;
    if (this.query) {
      this.router.navigate([route], { queryParams: { fn: this.query } });
    } else {
      this.router.navigate([route]);
    }
    this.clicked.emit();
  }

  public onUserImageClick(): void {
    if (isPlatformBrowser(this.platform)) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
    }
    if (this.isWebsite()) {
    } else {
      let route = this.nextRouteForImage.indexOf('fn=') != -1 ? this.routePath : this.nextRouteForImage;
      if (this.query) {
        this.router.navigate([route], { queryParams: { fn: this.query } });
      } else {
        this.router.navigate([route]);
      }
    }

    this.clicked.emit();
  }

  // public getPanelBorder(): Object {
  //   let res = {};
  //   if (this.comicData.panelBorder == undefined || this.comicData.panelBorder == null) {
  //     return res;
  //   }
  //   res['border-color'] = this.comicData.panelBorder['border-color'];
  //   res['border-style'] = 'solid';
  //   res['border-left'] = 'none';
  //   res['border-right'] = 'none';
  //   res['margin'] = '10px 0px';
  //   res['border-bottom'] = !this.comicData.panelBorder['border-bottom'] ? 'none' : this.comicData.panelBorder['border-bottom'];
  //   res['border-top'] = !this.comicData.panelBorder['border-top'] ? 'none' : this.comicData.panelBorder['border-top'];
  //   res['border-width'] = typeof this.comicData.panelBorder['border-width'] == 'number' ? this.comicData.panelBorder['border-width'] + 'px' : this.comicData.panelBorder['border-width'];
  //   return res;
  // }

  // public getSeparatorBorder(): Object {
  //   return this.comicService.getSeparatorBorder(this.comicData);
  // }

  public getImageBorder(): Object {
    return this.comicService.getImageBorder(this.comicData);
  }

  public getBadgeURL(badgeName: string) {
    return this.comicService.mapUserBadge(badgeName, 50);
  }

  public getCommentAction() {
    if (this.isWebsite()) {
      this.openLinkInNewTab(this.comicAction);
    } else {
      this.getImageAction();
    }
  }

  openLinkInNewTab(link) {
    this.getViewsForExternalLink();
    window.open(link, '_blank');
  }

  public formatSeriesName(name: string) {
    const formattedResult = convertToTitleCase(name);
    return formattedResult;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  public showRead($event) {
    this.showReadTicks = $event.show;
  }
}
