<div class="header d-flex px-2 justify-content-between">
  <a (click)="goToHome()" aria-current="page">
    <img [src]="getImagePath('TINYVIEW_MARK_AND_LOGO')" class="tv-logo" alt="tv-logo">
  </a>
  <div class="d-flex flex-row">
    <p class="custom-p-style custom-p-border" *ngIf="dashboardMenuView">
      <u>
        <a (click)="goToHome()">Home</a>
      </u>
    </p>
    <p *ngFor="let menuItem of dashboardMenuView; let i = index" class="custom-p-style" [class.custom-p-border]="i + 1 !== dashboardMenu.length">
        <u>
          <a [routerLink]="menuItem.redirectURL">{{menuItem.title}}</a>
        </u>
    </p>
    <button class="btn-secondary my-auto" (click)="signOut()">
      <p>Sign Out</p>
    </button>
  </div>
</div>

<router-outlet></router-outlet>
