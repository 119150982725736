import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SeriesGiftsViewData } from "src/app/adapters/daily-creator-data";
import { getImage } from "src/app/constants/images.constants";

@Component({
  selector: 'app-creator-stat-table',
  templateUrl: './creator-stat-table.component.html',
  styleUrls: ['./creator-stat-table.component.scss', '../dashboard-stats/dashboard-stats.component.scss']
})

export class CreatorStatTableComponent implements OnInit {
  @Input() calculatedDailyDataView;
  @Input() monthlyAggregateView;
  @Input() tableHead;
  modalRef: BsModalRef;
  public giftModalData: SeriesGiftsViewData;
  public isSubHeadRow: boolean;


  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.isSubHeadRow = this.hasSubHead(this.tableHead);
  }

  hasSubHead(data) {
    for ( let item in data) {
      const temp = data[item];
      if (temp.hasOwnProperty('subHead')) {
        if (temp.subHead === undefined) {
          return false;
        } else {
        return true;
        }
      }
    }
  }

  openModal(template: TemplateRef<any>, data?: SeriesGiftsViewData) {
    if (data && !data.value) {
      return;
    }

    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: false
    }));
    this.giftModalData = data;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
