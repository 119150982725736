import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import { ComicService } from '../services/comic.service';
import { StoryFeedService } from '../services/story-feed.service';
import * as util from '../utilities/common.util';
import { LocalStorageService } from '../services/local-storage.service';
import { traceStates } from '../constants/common.constants';
import { NewComicService } from '../new-comic.service';
import { getImage } from '../constants/images.constants';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() storyID: string;
  @Input() commentCount: number;
  @Input() showComment: boolean;
  @Input() anonymousUser: any;
  @Input() storyData: any;
  @Input() record?: number;
  @Output() updatedCommentCount: EventEmitter<number> = new EventEmitter<number>();

  public storyPageCommentLimit = 100;
  doShowComments: boolean = false;
  commentArray: any = [];
  public commentList = [];
  subComments = [];
  isAddReply: boolean;
  showReply: boolean = false;
  index: any;
  showMore: string;
  subCommentsCount: any;
  commentID: any;
  allComments: any = [];
  finalCommentsList: any = [];
  param: string;
  showUserNamesRef: any;
  myform: FormGroup;
  userPhoneNum: string;
  userName: string;
  userId: string;
  commentId: string;
  modalRef: any;
  currentUrl: string;
  isStoryPage: boolean;
  isPostComment: boolean;
  refType: any;
  placeholder: string = "Write a public comment...";
  userDetails: any;
  commentModalRef: any;
  isComicPage: boolean;
  currentUserName: string = 'G U';
  currentUserPhotoURL: string;
  isMobileView: boolean;
  edit_comment_modalRef: any;
  commentInfo: any;
  userData: any;
  comment_id: string;
  showOption: boolean;
  isEditComment: boolean = false;
  deletedComment: string = 'Delete';
  isDeletedComment: boolean = true;
  isAdmin: boolean = false;
  isTyping: boolean;
  user: firebase.User;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'my-modal'
  };
  isDirectoryPage: boolean;
  currentDevice: string;
  comment_popup_modalRef: any;
  shouldDisabled: boolean = false;
  commentCountDeleted: number = 0;
  ctaList: Array<string>;
  showPrevComments: boolean;
  defaultRecords: number;
  areMoreComments: boolean;
  lastCommentID: string;
  loadingMoreComments: boolean;
  isSeriesPage: boolean;

  constructor(
    public afAuth: AngularFireAuth,
    private modalService: BsModalService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private route: ActivatedRoute, public authService: AuthService,
    public feedService: StoryFeedService,
    public comicService: ComicService,
    public newComicService: NewComicService,
    private localStorageService: LocalStorageService,
  ) {
    this.isStoryPage = this.newComicService.isStoryPage();
    this.currentDevice = this.comicService.getOperatingSystem();
  }

  async ngOnInit() {
    this.isSeriesPage = await this.comicService.isSeriesPage();
    this.defaultRecords = this.isStoryPage ? this.storyPageCommentLimit : this.isSeriesPage ? 1 : (this.record || 2);
    this.showPrevComments = this.allComments.length > this.defaultRecords || this.commentCount > this.defaultRecords || this.storyData.commentCount > this.defaultRecords;
    this.isComicPage = this.comicService.isComicPage();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.isDirectoryPage = this.newComicService.isDirectoryPage();
    this.myform = new FormGroup({
      comment: new FormControl('', [])
    });
    if (isPlatformBrowser(this.platform)) {
      this.anonymousUser = util.isAnonymousUser();
      this.isAdmin = this.localStorageService.getItem('isAdmin');
      this.config.class = this.currentDevice == 'desktopBrowser' ? "my-modal modal-md" : "my-modal2 modal-md";
    }

    if (this.isComicPage || this.isStoryPage || this.isDirectoryPage) {
      if (!this.anonymousUser) {
        this.comicService.getUserDetails().then(user => {
          this.userData = user;
          this.currentUserName = user.data.displayName || 'A U';
          this.currentUserPhotoURL = user.data.photoURL;
        });
      }

      if (window.innerWidth <= 991) {
        this.isMobileView = true;
      }
      this.storyID = this.storyData && this.storyData.storyID;
      this.commentCount = this.storyData && this.storyData.commentCount;
    }
    this.commentAPIs();

    const $this = this;
    const user = firebase.auth().currentUser;
    $this.user = user;
    if ($this.isComicPage) {
      $this.storyID = $this.storyData && $this.storyData.storyID;
      $this.commentCount = $this.storyData && $this.storyData.commentCount;
      $this.commentAPIs();
    }
  }

  onClick() {
    return false;
  }

  public async commentAPIs() {
    this.showComments();
    if (this.storyID) {
      this.comicService.$userDetails.subscribe(user => {
        this.userData = user;
        if (!this.anonymousUser) {
          if (!this.userData.data.permissions.includes("comment")) {
            this.placeholder = 'You are not allowed to post comments.';
            this.shouldDisabled = true;
          }
        }
      });

      this.refType = this.storyData.refType;
      if (this.storyData.refType && !this.shouldDisabled) {
        this.placeholder = this.storyData.refType == 'SENT' ? 'Write a private comment...' :
          (this.storyData.refType == 'REPOST' || this.storyData.refType == 'GROUP_SENT') ? "Write a comment for all freinds..." : "Write a public comment...";
      }
    }
  }

  private showComments() {
    const order = (this.newComicService.isHomePage() || this.newComicService.isComicPage() || this.isStoryPage || this.isDirectoryPage || this.isSeriesPage) ? 'desc' : 'asc';
    this.param = this.route.snapshot.paramMap.get('ID');
    let record;
    if (['', '/'].includes(this.currentUrl) || this.isComicPage || this.isDirectoryPage) {
      record = 3;
    }

    if (this.isSeriesPage) {
      record = 1;
    }

    if (this.isStoryPage) {
      record = this.storyPageCommentLimit;
    }

    this.feedService.getComments(this.storyID, record, '', order).then(res => {
      // for home page, series page, comic page, story page handling
      if (![traceStates.STATE_UNSPECIFIED, traceStates.COMPLETED].includes(this.comicService.pageLoadTrace && this.comicService.pageLoadTrace.state)) {
        this.comicService.pageLoadTrace.stop();
      }

      this.allComments = []
      res.data.data.map(res => {
        if (res.user && res.user.image) {
          res.user.image = this.comicService.getCDNImgUrl(res.user.image);
        }
        res.isExpanded = false;
        res.showViewMore = false;

        // Check if the comment is longer than 4 lines (or based on character count)
        if (this.isLongComment(res.commentText)) {
          res.showViewMore = true; // Show "view more" link
          res.truncatedText = this.truncateComment(res.commentText); // Store truncated version
        } else {
          res.isExpanded = true;
        }

        if (res.hasSubComment) {
          res.showReply = true;
          res.subComments.map(res => {
            if (this.isLongComment(res.commentText)) {
              res.showViewMore = true;
              res.truncatedText = this.truncateComment(res.commentText);
            }
            else {
              res.isExpanded = true;
            }
            if (res.user && res.user.image) {
              res.user.image = this.comicService.getCDNImgUrl(res.user.image);
            }
          })
        }
        res.commentType && res.commentType === 'system' ? res.isSystemComment = true : res.isSystemComment = false;
        this.allComments.push(res);
      });

      this.showPrevComments = this.isStoryPage ? (this.commentCount > this.defaultRecords) : (this.allComments.length > this.defaultRecords);
      if (this.param || (this.isComicPage && !this.isMobileView)) {
        this.commentList = this.allComments;
      } else {
        if (this.commentCount <= 2) {
          this.commentList = [];
          this.commentList = this.allComments;
        } else {
          this.commentList = [];
          if (this.allComments[this.allComments.length - 2]) {
            this.commentList.push(this.allComments[this.allComments.length - 2]);
          }
          if (this.allComments[this.allComments.length - 1]) {
            this.commentList.push(this.allComments[this.allComments.length - 1]);
          }
        }
      }

      if (['', '/'].includes(this.currentUrl)) {
        this.commentList = [];
        this.commentList = this.allComments && this.allComments.slice(0, this.defaultRecords);
      } else if (this.isComicPage || this.isDirectoryPage) {
        this.commentList = [];
        this.commentList = this.allComments && this.allComments.length && this.allComments.reverse().slice(-this.defaultRecords);
      } else if (this.isStoryPage) {
        this.commentList = this.allComments && this.allComments.length && this.allComments.reverse() || [];
        // this.lastCommentID = this.allComments && this.allComments.length && this.allComments[this.allComments.length - 1].commentId;
        this.lastCommentID = this.allComments && this.allComments.length ? this.allComments[0].commentId : undefined;
      }

      // Check 1: We need to check if displayed comments are less than total comments of story
      // Check 2: Safe check if there are replies
      this.areMoreComments = !this.isStoryPage || (this.commentList.length ? (this.commentCount > this.commentList.length && (this.commentList.length % this.storyPageCommentLimit === 0)) : false);
    }, err => {
      console.log(err);
    });
  }

  private isLongComment(text: string): boolean {
    const charLimit = 130; // Example char limit, you can adjust
    return text.length > charLimit;
  }

  // ToDo: Udit: move this logic of truncation in a service to use later
  private truncateComment(text: string): string {
    const charLimit = 130; // Same limit used to determine if it's long
    return text.slice(0, charLimit) + '...'; // Truncate and add ellipsis
  }

  toggleViewMore(comment: any): void {
    comment.isExpanded = !comment.isExpanded;
  }

  public showAllComments(commentModal) {
    if (this.storyID) {
      if (this.storyData && this.storyData.commentCount) {
        if (isPlatformBrowser(this.platform)) {
          var sp = window.scrollY;
          this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
          this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        }
        this.userDetails = this.comicService.userDetails.data;
        this.ctaList = this.comicService.getCTAList();
        if (this.anonymousUser || this.ctaList.includes('incompleteProfile')) {
          var sp = window.scrollY;
          this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
          this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
          this.router.navigate(['story', this.storyID]);
        } else if (this.userDetails.userName && this.userDetails.gender && (this.userDetails.phoneNumber || this.userDetails.email)) {
          this.router.navigate(['story', this.storyID]);
        }
      }
    }

    if (this.isStoryPage && this.lastCommentID && !this.loadingMoreComments) {
      this.loadingMoreComments = true;
      const order = 'desc';
      this.feedService.getComments(this.storyID, this.storyPageCommentLimit, this.lastCommentID, order).then(res => {
        let allComments = [];
        res.data.data.map(res => {
          if (res.user && res.user.image) {
            res.user.image = this.comicService.getCDNImgUrl(res.user.image);
          }
          if (res.hasSubComment) {
            res.showReply = true;
          }
          res.commentType && res.commentType === 'system' ? res.isSystemComment = true : res.isSystemComment = false;
          allComments.push(res);
        });

        this.showPrevComments = this.isStoryPage ? (this.commentCount > this.defaultRecords) : (this.allComments.length > this.defaultRecords);

        if (this.isStoryPage) {
          allComments = allComments && allComments.length && allComments.reverse() || [];
          this.commentList = [...allComments, ...this.commentList];
          this.lastCommentID = allComments.length ? allComments[0].commentId : undefined;
        }

        // Check 1: We need to check if displayed comments are less than total comments of story
        // Check 2: Safe check if there are replies
        this.areMoreComments = !this.isStoryPage || (this.commentList.length ? (allComments.length && this.commentCount > this.commentList.length && (this.commentList.length % this.storyPageCommentLimit === 0)) : false);
        this.loadingMoreComments = false;
      }, err => {
        console.log(err);
        this.loadingMoreComments = false;
      });
    }
  }

  public showReplies(indexOfelement) {
    if (this.commentList[indexOfelement].showReply) {
      this.commentList[indexOfelement].showReply = false;
    } else {
      this.commentList[indexOfelement].showReply = true;
    }
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str)
    return firstLetters;
  }

  public doLikeComment(comment, subComment) {
    if (subComment) {
      if (subComment.isLiked) {
        this.feedService.doDislikeComment(comment.storyId, comment.commentId, subComment.subCommentId)
        if (!subComment.reactionsCount) {
          subComment.reactionsCount = 0
        }
        subComment.isLiked = false;
        subComment.reactionsCount--;
      } else {
        this.feedService.doLikeComment(comment.storyId, comment.commentId, subComment.subCommentId);
        if (!subComment.reactionsCount) {
          subComment.reactionsCount = 0
        }
        subComment.isLiked = true;
        subComment.reactionsCount++;
      }
    } else {
      if (comment.isLiked) {
        this.feedService.doDislikeComment(comment.storyId, comment.commentId, '')
        if (!comment.reactionsCount) {
          comment.reactionsCount = 0
        }
        comment.isLiked = false;
        comment.reactionsCount--;
      } else {
        this.feedService.doLikeComment(comment.storyId, comment.commentId, '');
        if (!comment.reactionsCount) {
          comment.reactionsCount = 0
        }
        comment.isLiked = true;
        comment.reactionsCount++;
      }
    }
  }

  openUserCommentModal(template: TemplateRef<any>) {
    this.showUserNamesRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-md",
      ignoreBackdropClick: true
    }));
  }

  public async postComments(modal, commentModal) {
    this.showOption = true;
    this.ctaList = this.comicService.getCTAList();
    if (this.ctaList.length) {
      if (this.ctaList.includes('signin')) this.openModal(modal, false);
      if (this.ctaList.includes('incompleteProfile')) this.openCommentModal(commentModal);
      if (this.ctaList.includes('subscribe')) this.openModal(modal, false);
      this.isTyping = false;
    } else {
      const commentText = this.myform.get('comment').value;
      if (commentText) {
        this.isTyping = false;
        this.isPostComment = true;
        const truncatedText = this.truncateText(commentText, 130); // Truncate after 100 characters
        const showViewMore = commentText.length > 130; // Show 'view more' if comment is longer

        if (this.isEditComment) {
          const id = this.comment_id ? this.comment_id : this.commentInfo.commentId;
          let commentIndex;
          this.commentList.map((data, i) => {
            if (this.commentInfo.commentId && (data.commentId == this.commentInfo.commentId)) {
              data.commentText = commentText;
              commentIndex = i;
            } else if (data.subComments) {
              data.subComments.map((sComment: any) => {
                if (this.commentInfo.subCommentId && (sComment.subCommentId == this.commentInfo.subCommentId)) {
                  sComment.commentText = commentText;
                  commentIndex = i;
                  sComment.truncatedText = this.truncateText(commentText, 130); // Truncate subcomment
                  sComment.showViewMore = commentText.length > 130; // Show view more for subcomment
                }
              });
            }
          });
          this.isPostComment = false;
          this.myform.get('comment').reset();
          if (this.isStoryPage) {
            setTimeout(() => {
              this.scrollToComment(`cmt${commentIndex}`);
            }, 500);
          }
          this.myform.get('comment').reset();
          this.userName = '';
          await this.feedService.editComment(this.commentInfo.storyId, id, this.commentInfo.subCommentId, commentText);
          this.isEditComment = false;
        } else {
          this.comicService.commentCounts$.next(0);
          const data = this.comicService.userDetails.data;
          let commentId = this.commentId;
          this.commentId = '';

          let comment_res = {
            data: {
              data: {
                commentId: '',
                subCommentId: ''
              }
            }
          };

          let usercommentId = comment_res.data.data.commentId;
          let commentIndex = this.commentList.length;
          let userSubCommentId = comment_res.data.data.subCommentId;
          this.isPostComment = false;
          const commentObj = {
            isExpanded: false, // Initially collapsed
            truncatedText: truncatedText,
            commentText: commentText,
            showViewMore: showViewMore, // Show 'view more' only if comment exceeds the limit
            user: {
              image: data.photoURL ? this.comicService.getCDNImgUrl(data.photoURL) : data.photoURL,
              name: data.displayName,
              badges: data.badges,
              userId: this.user.uid
            },
            updatedAt: new Date().getTime(),
            storyId: this.storyID,
            isLiked: false,
            commentId: usercommentId,
            subCommentId: userSubCommentId,
            hideCommentActions: true
          };
          if (commentId) {
            const mainComment = this.commentList.find(value => value.commentId == commentId);
            this.commentList.map((res, i) => {
              if (res.commentId == mainComment.commentId) {
                res.showReply = false;
                commentIndex = i;
              }
            });
            if (mainComment && !mainComment.hasSubComment) {
              mainComment["subComments"] = [];
              mainComment.hasSubComment = true;
            }
            commentObj.commentId = undefined;
            mainComment.subComments.push(commentObj);
          } else {
            commentObj.subCommentId = undefined;
            this.commentList.push(commentObj);
          }
          this.myform.get('comment').reset();
          this.userName = '';
          if (this.isStoryPage) {
            setTimeout(() => {
              this.scrollToComment(`cmt${commentIndex}`);
            }, 500);
          }
          try {
            comment_res = await this.feedService.addComments(this.storyID, commentText, commentId);
            this.commentCount += 1;
            this.emitCommentCount(this.commentCount);
            if (commentId) {
              this.commentList.map(c => {
                if (c.commentId == commentId) {
                  c.subComments[c.subComments.length - 1].subCommentId = comment_res.data.data.subCommentId;
                  c.subComments[c.subComments.length - 1].hideCommentActions = false;
                }
              });
            } else {
              this.commentList[this.commentList.length - 1].commentId = comment_res.data.data.commentId;
              this.commentList[this.commentList.length - 1].hideCommentActions = false;
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
    }
  }

  private truncateText(text: string, length: number): string {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + '...';
  }

  public doReply(data, commentModal, modal, userName = null) {
    this.isEditComment = false;
    this.isAddReply = true;
    this.ctaList = this.comicService.getCTAList();
    if (this.ctaList.length) {
      if (this.ctaList.includes('signin')) this.openModal(modal, false);
      if (this.ctaList.includes('incompleteProfile')) this.openCommentModal(commentModal);
      if (this.ctaList.includes('subscribe')) this.openModal(modal, false);
      return;
    }
    if (this.param) {
      this.userName = userName || data.user.name;
      this.commentId = data.commentId;
      // window.scrollTo(0, document.body.scrollTop);
    } else {
      this.userDetails = this.comicService.userDetails.data;
      if (this.anonymousUser || this.ctaList.includes('incompleteProfile')) {
        var sp = window.scrollY;
        this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
        this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        this.router.navigate(['story', this.storyID]);
      } else if (this.userDetails.userName && this.userDetails.gender && (this.userDetails.phoneNumber || this.userDetails.email)) {
        this.router.navigate(['story', this.storyID]);
      } else {
        this.openCommentModal(commentModal);
      }
    }
  }

  openCommentModal(template: TemplateRef<any>) {
    this.commentModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-layout",
      ignoreBackdropClick: true
    }));
  }

  openEditCommentModal(template: TemplateRef<any>, commentInfo: any, commentId: string) {
    this.commentInfo = commentInfo;
    this.userName = '';
    this.deletedComment = 'Delete';
    if (this.user && (this.user.uid == this.commentInfo.user.userId)) {
      this.showOption = true;
    } else if (this.commentInfo.user.userId) {
      this.showOption = false;
    }
    this.comment_id = commentId ? commentId : commentInfo.commentId; // commentId for subcomments..
    this.edit_comment_modalRef = this.modalService.show(template, this.config);
  }

  openModal(template: TemplateRef<any>, isLike) {
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }

  public goToSignInPage() {
    this.router.navigate(['/edit-profile']).then(() => {
      this.commentModalRef.hide();
    })
  }

  /* To copy any Text */
  public copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public async deleteComments(template) {
    this.comment_popup_modalRef = this.modalService.show(template, this.config);
    this.edit_comment_modalRef.hide();
  }

  public async deleteCommentPopup(storyId: string, commentId: string, subCommentId: string) {
    let reduceCommentCount = 1;
    const commentIndex = this.commentList.findIndex(c => c.commentId === commentId);
    const commentObj = this.commentList[commentIndex];
    if (subCommentId && commentObj.subComments && commentObj.subComments.length) {
      const subCommentIndex = commentObj.subComments.findIndex(sc => sc.subCommentId === subCommentId);
      this.commentList[commentIndex].subComments.splice(subCommentIndex, 1);
    } else {
      if (commentObj.subComments && commentObj.subComments.length) {
        reduceCommentCount = commentObj.subComments.length + 1;
      }
      this.commentList.splice(commentIndex, 1);
    }

    this.comment_popup_modalRef.hide();
    this.comicService.commentCounts$.next(reduceCommentCount);
    await this.feedService.deleteComment(storyId, commentId, subCommentId);
    this.commentCount -= reduceCommentCount;
    this.emitCommentCount(this.commentCount);
  }

  public editComments() {
    this.isEditComment = true;
    window.scrollTo(0, this.isComicPage ? document.body.scrollTop : document.body.scrollHeight);
    this.myform.get('comment').setValue(this.commentInfo.commentText);
    this.edit_comment_modalRef.hide();
    this.isTyping = true;
  }

  public getBadgeURL(badgeName: string) {
    return this.comicService.mapUserBadge(badgeName, 50);
  }

  public onInputChange(event: any) {
    // Check if there is any input value to determine if the user is typing
    this.isTyping = event.target.value.length > 0;
  }

  public emitCommentCount(commentCount) {
    this.updatedCommentCount.emit(commentCount);
  }

  public cancelReply() {
    this.isAddReply = false;
    this.commentId = '';
  }

  handleImageError(event: Event) {
    const targetImage = event.target as HTMLImageElement;
    targetImage.classList.remove('image-border');
    targetImage.src = this.getImagePath("MALE_USER");
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  // Method to scroll to a specific comment by ID
  scrollToComment(commentId: string): void {
    const element = document.getElementById(commentId);
    if (element && this.isMobileView) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      // using different scrolling technique, because scrollIntoView was scrolling fixed bottom bars also on desktop view
      this.newComicService.triggerMiddleColumnScroll();
    }
  }

  getCDNImgPath(img: string) {
    return this.comicService.getCDNImgUrl(img);
  }
}
