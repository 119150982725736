import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { getImage } from "src/app/constants/images.constants";
import { NewComicService } from "src/app/new-comic.service";
import { AuthService } from "src/app/services/auth.service";
import { LoginService } from "src/app/services/login.service";

@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss']
})
export class DobComponent implements OnInit {
  userDobForm: FormGroup;
  showMonthInput = false;
  showDayInput = false;
  currentYear = new Date().getFullYear();
  loadingButton: boolean;
  isoCountryCode: any;
  phoneNumber: any;
  email: string;
  message: string;
  comicSeries: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private router: Router,
    private newComicService: NewComicService,
    private renderer: Renderer2,
    private el: ElementRef,
    private loginService: LoginService
  ) {
    this.userDobForm = this.fb.group({
      birthYear: [
        null,
        [Validators.required, Validators.min(1900), Validators.max(this.currentYear)],
      ],
      birthMonth: [null, [Validators.min(1), Validators.max(12)]],
      birthDay: [null, [Validators.min(1), Validators.max(31)]],
    });
  }

  ngOnInit(): void {
    this.message = this.route.snapshot.queryParams.message;
    this.comicSeries = this.route.snapshot.queryParams.comic;
    // Retrieve user data from newComicService
    const userData = this.newComicService.getUserData();
    // Set form values if user data exists
    if (userData && userData.dob) {
      this.userDobForm.patchValue({
        birthYear: userData.dob.year,
        birthMonth: userData.dob.month,
        birthDay: userData.dob.day,
      });
    }

    if (
      this.route.snapshot.queryParams.isoCountryCode
      && this.route.snapshot.queryParams.phoneNumber
    ) {
      this.isoCountryCode = this.route.snapshot.queryParams.isoCountryCode;
      this.phoneNumber = this.route.snapshot.queryParams.phoneNumber;
    }
    if (this.route.snapshot.queryParams.email) this.email = this.route.snapshot.queryParams.email
  }

  validateAge(event: Event) {
    const birthYearControl = this.userDobForm.get('birthYear');
    if (birthYearControl.value && birthYearControl.value.length < 4) return;
    const birthYear = this.userDobForm.get('birthYear').value;
    const birthMonth = this.userDobForm.get('birthMonth').value || 1;
    const birthDay = this.userDobForm.get('birthDay').value || 1;

    const today = new Date();
    const minimumBirthDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
    const minimumBirthMonth = today.getMonth() + 1;

    const selectedBirthDate = new Date(birthYear, birthMonth - 1, birthDay);

    if (selectedBirthDate < minimumBirthDate) {
      this.showMonthInput = (birthYear == today.getFullYear() - 13) && (this.userDobForm.get('birthYear').value);
      this.showDayInput = (this.showMonthInput) && (this.userDobForm.get('birthMonth').value) && (birthMonth == today.getMonth() + 1);
    }

    if (this.showMonthInput && (minimumBirthMonth < birthMonth)) {
      this.showDayInput = false;
    }

    if (this.showDayInput) {
      this.userDobForm.get('birthDay').setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
    } else {
      this.userDobForm.get('birthDay').setValue(null);
      this.userDobForm.get('birthDay').setErrors(null);
      this.userDobForm.get('birthDay').clearValidators();
    }

    if (this.showMonthInput) {
      this.userDobForm.get('birthMonth').setValidators([Validators.required, Validators.min(1), Validators.max(12)]);
    } else {
      this.userDobForm.get('birthMonth').setValue(null);
      this.userDobForm.get('birthMonth').setErrors(null);
      this.userDobForm.get('birthMonth').clearValidators();
    }

    this.userDobForm.updateValueAndValidity();

    if (selectedBirthDate > minimumBirthDate && selectedBirthDate < today) {
      if (!this.getBirthYearErrorMessage() && !this.getBirthMonthErrorMessage() && !this.getBirthDayErrorMessage()) this.userDobForm.setErrors({ ageInvalid: true });
    } else {
      this.userDobForm.setErrors(null);
    }
    if (this.userDobForm.hasError('ageInvalid')) {
      const inputElement = event.target as HTMLInputElement;
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
    }
  }

  getBirthYearErrorMessage() {
    const birthYearControl: any = this.userDobForm.get('birthYear');
    const inputElement = birthYearControl.nativeElement;
    if (birthYearControl.hasError('required') && (birthYearControl.dirty || birthYearControl.touched)) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Year is required.';
    }

    if ((birthYearControl.hasError('min') || birthYearControl.hasError('max')) && birthYearControl.value && birthYearControl.value.toString().length > 3 && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Please enter a valid 4-digit year number.';
    }
    if (birthYearControl && birthYearControl.value && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'filled-and-valid');
      this.renderer.removeClass(inputElement, 'invalid-input');
    }
    return '';
  }

  getBirthMonthErrorMessage() {
    const birthMonthControl: any = this.userDobForm.get('birthMonth');
    const inputElement = birthMonthControl.nativeElement;
    if (!birthMonthControl.dirty) return '';
    if (birthMonthControl.hasError('required') && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Month is required.';
    }

    if ((birthMonthControl.hasError('min') || birthMonthControl.hasError('max')) && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Please enter a valid numeric month.';
    }

    if (birthMonthControl && birthMonthControl.value && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'filled-and-valid');
      this.renderer.removeClass(inputElement, 'invalid-input');
    }

    return '';
  }

  getBirthDayErrorMessage() {
    const birthDayControl: any = this.userDobForm.get('birthDay');
    const inputElement = birthDayControl.nativeElement;
    if (!birthDayControl.dirty) return '';
    if (birthDayControl.hasError('required') && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Day is required.';
    }

    if ((birthDayControl.hasError('min') || birthDayControl.hasError('max')) && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'invalid-input');
      this.renderer.removeClass(inputElement, 'filled-and-valid');
      return 'Please enter a valid numeric day.';
    }

    if (birthDayControl && birthDayControl.value && !this.userDobForm.hasError('ageInvalid')) {
      this.renderer.addClass(inputElement, 'filled-and-valid');
      this.renderer.removeClass(inputElement, 'invalid-input');
    }

    return '';
  }

  isFilledAndValid(controlName: string): boolean {
    const control = this.userDobForm.get(controlName);
    return control && control.touched && control.valid && control.value !== '';
  }

  isInvalid(controlName: string): boolean {
    const control = this.userDobForm.get(controlName);
    return control && control.touched && control.invalid;
  }

  updateDoB() {
    this.loadingButton = true;
    const userData = {
      dob: { "year": this.userDobForm.get('birthYear').value, "month": this.userDobForm.get('birthMonth').value, "day": this.userDobForm.get('birthDay').value },
    }
    this.newComicService.setUserData(userData);
    this.authService.userProfile([{
      dob: userData.dob
    }]).then((res) => {
      let queryParams = this.comicSeries ? { message: this.message, comic: this.comicSeries }: {}
      if (this.phoneNumber) {
        queryParams['isoCountryCode'] = this.isoCountryCode;
        queryParams['phoneNumber'] = this.phoneNumber.replace(/\s+/g, '');
      }
      if (this.email) {
        queryParams['email'] = this.email;
        this.loginService.navigateTo('email', 'SIGNUP', queryParams);
      } else {
        this.loginService.navigateTo('phone/number', 'SIGNUP', queryParams);
      }
    }).catch((err) => {
      this.loadingButton = false;
      console.log('error on date of birth update');
    });
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
