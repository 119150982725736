import { getImage } from '../constants/images.constants';

export const shareConfig = [
  {
    title: 'Messages',
    action: 'openSMS',
    image: getImage('APPLE_MESSAGES_100'),
    androidImage: getImage('GOOGLE_MESSAGES_100'),
    show: true
  },
  {
    title: 'WhatsApp',
    action: 'openWhatsApp',
    image: getImage('WHATSAPP_100'),
    show: true
  },
  {
    title: 'Instagram',
    action: 'openInstagram',
    image: getImage('INSTAGRAM_100'),
    show: true
    // show: !this.isDesktop
  },
  {
    title: 'Facebook',
    action: 'openFacebook',
    image: getImage('FACEBOOK_100'),
    show: true
  },
  {
    title: 'X',
    action: 'openTwitter',
    image: getImage('ICONS8_TWITTERX_DARK_100_CROPPED'),
    show: true
  },
  {
    title: 'Copy link',
    action: 'copyLink',
    image: getImage('COPY_LINK_PINK_100'),
    show: true
  },
  // 6th index: More option, if the index is changed then make sure to map the correct index in panel-nav.component.ts
  {
    title: 'More',
    action: 'openSystemDefaultShare',
    image: getImage('OPTIONS_100'),
    show: true
  }
];

export const INFLUENCE_PAGE = {
  title: 'Influence Points',
  image: 'https://cdn.tinyview.com/tinyview/influence-points/preview.jpg',
  image2: 'https://cdn.tinyview.com/tinyview/influence-points/invite-and-share.jpg',
  description: `Can't subscribe but still want to support creators? Help us spread the word to your friends & family and earn Influence Points. Then use your points to unlock bonus panels and subscribers-only comics.`,
  url: '/tinyview/influence-points',
  previewImg: 'https://cdn.tinyview.com/tinyview/influence-points/preview.jpg',
  profilePic: 'https://cdn.tinyview.com/tinyview/tinyview-profile.jpg'
}
