import { Component } from "@angular/core";
import { DailyDataCalculation } from "src/app/adapters/daily-creator-data";
import { TOTALS_DASHBOARD_COLUMNS } from "src/app/constants/common.constants";
import { getImage } from "src/app/constants/images.constants";
import { DashboardService } from "src/app/services/dashboard.service";
import { UserService } from "src/app/services/user.service";
import { totalsDashboardViewTable } from "src/app/utilities/common.util";

interface Filter {
  dashboardType: string;
};

@Component({
  selector: 'app-totals-dashboard',
  templateUrl: './totals-dashboard.component.html',
  styleUrls: ['./totals-dashboard.component.scss']
})
export class TotalsDashboardComponent {
  currentDate = new Date();
  userName: string;
  isLoading = false;
  tableError: string;
  calculatedFor: string;

  public tableHead = TOTALS_DASHBOARD_COLUMNS;

  public totalsDashboardData = {
    subscriptions: [],
    signups: [],
    alerts: []
  };
  calculatedSubscriptionsDataView = [];
  calculatedSignupsDataView = [];
  calculatedAlertsDataView = [];
  error: string;
  constructor(
    private dashboardService: DashboardService,
    private readonly userService: UserService
  ) {
    this.getUserDetail();
    this.getTableData();
  }

  private async getUserDetail() {
    const username = await this.userService.getUserDetails();

    this.userName = username && username.data && username.data.data.displayName;
  }

  private getTableData() {
    const filter: Filter = {
      dashboardType: "total"
  }
    this.loadTotalsData(filter);
  }
  
  private setViewData() {
    this.calculatedSubscriptionsDataView = [];
    this.calculatedSignupsDataView = [];
    this.calculatedAlertsDataView = [];

    this.totalsDashboardData.subscriptions.forEach(data => {
      const mapped = totalsDashboardViewTable(data);
      mapped && this.calculatedSubscriptionsDataView.push(mapped);
    });    
    this.totalsDashboardData.signups.forEach(data => {
      const mapped = totalsDashboardViewTable(data);
      mapped && this.calculatedSignupsDataView.push(mapped);
    });    
    this.totalsDashboardData.alerts.forEach(data => {
      const mapped = totalsDashboardViewTable(data);
      mapped && this.calculatedAlertsDataView.push(mapped);
    });
  }

  private async loadTotalsData(filters) {
    this.totalsDashboardData = {
      subscriptions: [],
      signups: [],
      alerts: []
    };
    this.tableError = '';
    this.isLoading = true;
    try {
      const resp = await this.dashboardService.getDashboardData(filters);
      const noDataCondition = resp.data &&
        (
          (resp.data.subscriptions &&
            (resp.data.subscriptions.total == null || resp.data.subscriptions.revenue == null)
          ) ||
          (resp.data.signups &&
            (resp.data.signups.total == null || resp.data.signups.phone == null || resp.data.signups.email == null)
          ) ||
          (resp.data.alerts &&
            (resp.data.alerts.push == null || resp.data.alerts.email == null || resp.data.alerts.sms == null)
          )
        );
      if (noDataCondition) {
        this.tableError = 'No data available yet.';
        this.isLoading = false;
        return;
      }

      this.totalsDashboardData.subscriptions.push({ matrixName: 'Subscribers', total: this.getCount(resp.data.subscriptions.total)})
      this.totalsDashboardData.subscriptions.push({ matrixName: 'Monthly Revenue', total: this.getAmount(resp.data.subscriptions.revenue)})

      this.totalsDashboardData.signups.push({ matrixName: 'Total  Signups', total: this.getCount(resp.data.signups.total)})
      this.totalsDashboardData.signups.push({ matrixName: 'Phone Signups', total: this.getCount(resp.data.signups.phone)})
      this.totalsDashboardData.signups.push({ matrixName: 'Email Signups', total: this.getCount(resp.data.signups.email)})

      this.totalsDashboardData.alerts.push({ matrixName: 'App Alerts', total: this.getCount(resp.data.alerts.push)})
      this.totalsDashboardData.alerts.push({ matrixName: 'Email Alerts', total: this.getCount(resp.data.alerts.email)})
      this.totalsDashboardData.alerts.push({ matrixName: 'SMS Alerts', total: this.getCount(resp.data.alerts.sms)})

      this.setViewData();
      this.isLoading = false;
      const dataUntil = this.getDateForData(this.currentDate);
      this.calculatedFor = this.dashboardService.getMessageForDate(dataUntil, true).displayValue;
    } catch (error) {
      this.isLoading = false;
      this.error = 'Unable to load Totals matrices data, please refresh the page!';
    }
  }

  getCount(value) {
    return {
      displayValue: Number(value).toLocaleString(),
      value: Number(value)
    }
  }

  getAmount(referralAmt) {
    return {
      displayValue: `$${DailyDataCalculation.formatNumberToUSFormat(referralAmt, true, 0)}`,
      value: referralAmt
    }
  }

  getDateForData(dataDate): number {
    const today = new Date();
    const dataString = new Date(dataDate);
    const month = dataString.getMonth();
    const currentMonth = today.getMonth();

    if (month === currentMonth) {
      const previousDay = new Date(today);
      previousDay.setDate(today.getDate() - 1);
      return previousDay.getTime();
    } else {
      return dataDate;
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
