<div class="comment-container" [ngClass]="{'mt-15': commentList?.length && showPrevComments && isComicPage && !isComicPage}">

    <!-- <div *ngIf="isComicPage" class="separator">
        <div class="cus-border"></div>
        <div *ngIf="!isMobileView" class="comment"><b>Comments</b></div>
    </div> -->

    <div *ngIf="commentList.length !== 0" [ngClass]="{'order-3': isComicPage}" class="comment-section" 
        [class.ml-2]="currentUrl === '/'" [class.mx-3]="!isStoryPage && !isDirectoryPage && !isComicPage && currentUrl !== '/'"
        [class.pb-180]="isStoryPage && !isMobileView">
        <p class="previous-comment" (click)="showAllComments(commentModal)"
            *ngIf="showPrevComments">
            <span *ngIf="!doShowComments && areMoreComments">See previous comments...</span>
            <!-- <span *ngIf="doShowComments">Hide comments...</span> -->
        </p>

        <div *ngFor="let list of commentList;let indexOfelement=index;">
            <div id="cmt{{indexOfelement}}">
                <div class="tv-comments" style="padding-top: 10px;"
                    [ngStyle]="{'margin': isStoryPage ? '5px 20px' : '5px 0px' }">
                    <div>
                        <img *ngIf="list?.user?.image" [src]="list?.user?.image" class="user-img-comment image-border" 
                            [class.ml-0]="isStoryPage" (error)="handleImageError($event)"/>
                        <div *ngIf="!list?.user?.image" class="img-placeholder" [class.ml-0]="isStoryPage">
                            <div class="body-mini">{{getFirstLetters(list.user.name)}}</div>
                        </div>
                    </div>
                    <img *ngIf="!list.isSystemComment" [src]="getImagePath('ICONS8_SORT_LEFT_100')" height="46px" width="20px" alt="beak" class="beak">

                    <div class="user-data" [class.system-comments-bg]="list.isSystemComment" [class.custom-margin]="isStoryPage">
                        <span class="p-bold" *ngIf="!list.isSystemComment">{{list.user.name}}</span>
                        <span class="user-badges" *ngIf="!list.isSystemComment">
                            <ng-container *ngIf="list.user.badges && list.user.badges[0]">
                                <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(list.user.badges[0])">
                            </ng-container>
                        </span>
                        <p>
                            {{ list.isExpanded ? list.commentText : list.truncatedText }}
                            <a *ngIf="list.showViewMore" (click)="toggleViewMore(list)">
                              <strong>{{ list.isExpanded ? ' view less' : 'view more' }}</strong>
                            </a>
                          </p>  
                    </div>
                </div>
                <div *ngIf="list.hideCommentActions" [ngClass]="{'ml-15': isComicPage}" class="processing">
                    <span style="font-weight: 400;margin-right: 5px;"><p>Posting...</p></span>
                </div>
                <div *ngIf="!list.hideCommentActions" class="comment-bottom-sec"
                    [ngStyle]="{'margin': isStoryPage ? '0px 20px 0px 80px' : '0px 0px 0px 60px' }">
                    <p>
                        <span class="body-mini">{{comicService.getDataDiff(list.updatedAt)}}</span>
                        <!-- <ng-container  *ngIf="!anonymousUser"> -->
                        <span (click)="doLikeComment(list,'')"
                            class="show-like body-mini" >{{list.isLiked?'Liked':'Like'}}</span>
                        <span class="show-like body-mini"
                            (click)="doReply(list,commentModal, modal, null)">Reply</span>
                        <!-- </ng-container> -->
                        <button
                            (click)="isStoryPage ? openEditCommentModal(edit_comment_modal,list,'') : showAllComments(commentModal)"
                            class="p-bold bg-white" *ngIf="!list.isSystemComment">
                            &#183;&#183;&#183;
                        </button>
                    </p>
                    <div class="like-count pl-0">
                        <span class="reaction-count" *ngIf="list.reactionsCount"><a
                                (click)="openUserCommentModal(userModal)">{{list.reactionsCount}}</a></span>
                        <img class="like-icon" *ngIf="list.isLiked && list.reactionsCount" [src]="getImagePath('RED_HEART')" (click)="openUserCommentModal(userModal)" />
                        <img class="like-icon" *ngIf="!list.isLiked && list.reactionsCount"
                        [src]="getImagePath('LIKE_ICON')"   (click)="openUserCommentModal(userModal)" />
                    </div>
                </div>
                <button class="reply-btn" (click)="showReplies(indexOfelement)"
                    *ngIf="list.subComments && list.subComments.length && list?.hasSubComment">
                    <span *ngIf="list?.showReply">{{list.subComments.length}} {{list.subComments.length == 1 ? 'Reply' :
                        'Replies'}}</span>
                    <span *ngIf="!list?.showReply">Hide Replies</span>
                </button>

                <div *ngIf="list?.subComments && !list.showReply" class="sub-comments">
                    <div *ngFor="let comment of list?.subComments"
                        [ngStyle]="{'margin': isStoryPage ? '5px 20px' : '0px' }">
                        <div class="tv-comments replies">
                            <div>
                                <img *ngIf="comment?.user?.image" [src]="comment?.user?.image" class="user-img-reply image-border"
                                (error)="handleImageError($event)"/>
                                <div *ngIf="!comment?.user?.image" class="img-reply-placeholder image-border">
                                    <p>{{getFirstLetters(comment.user.name)}}</p>
                                </div>
                            </div>
                                <img [src]="getImagePath('ICONS8_SORT_LEFT_100')" height="46px" width="20px" alt="beak" class="beak">
                                <div class="user-data">
                                    <span class="p-bold">{{comment.user.name}}</span>
                                    <span class="user-badges">
                                        <ng-container *ngIf="comment.user.badges && comment.user.badges[0]">
                                            <img alt="badge-icon" class="badge-icon" [src]="getBadgeURL(comment.user.badges[0])">
                                        </ng-container>
                                    </span>
                                    <p >
                                        {{ comment.isExpanded ? comment.commentText : comment.truncatedText }}
                                        <a *ngIf="comment.showViewMore" (click)="toggleViewMore(comment)">
                                          <strong>{{ comment.isExpanded ? 'view less' : 'view more' }}</strong>
                                        </a>
                                        <!-- {{comment.commentText}} -->
                                    </p>  
                                </div>
                        </div>

                        <div *ngIf="comment.hideCommentActions" class="processing" [ngClass]="isComicPage ? 'ml-4px' : 'ml-15' ">
                            <span style="font-weight: 400;margin-right: 5px;">Posting...</span>
                        </div>

                        <div *ngIf="!comment.hideCommentActions" class="comment-bottom-sec"
                            [ngStyle]="{'margin': isStoryPage ? '0px 0px 0px 54px' : '0px 0px 0px 54px', 'margin-left': isComicPage ? '25px' : '54px' }">
                            <div>
                                <p class="show-time body-mini d-inline-flex">{{comicService.getDataDiff(comment.updatedAt)}}</p>
                                <!-- <ng-container  *ngIf="!anonymousUser"> -->
                                <p class="show-like body-mini d-inline-flex" [class.pr-0]="isMobileView"
                                    (click)="doLikeComment(list,comment)">{{comment.isLiked?'Liked':'Like'}}</p>
                                <p class="show-like body-mini d-inline-flex" [class.pr-0]="isMobileView"
                                    (click)="doReply(list,commentModal, modal, comment?.user?.name)">Reply</p>
                                <!-- </ng-container> -->
                                <button
                                    (click)="isStoryPage ? openEditCommentModal(edit_comment_modal,comment,list.commentId) : showAllComments(commentModal)"
                                    class="show-like body-mini show-dotted" [class.pr-0]="isMobileView" [ngStyle]="{'padding-left': isComicPage ? '6px': '10px'}">
                                    <p class="p-bold bg-white">&#183;&#183;&#183;</p>
                                </button>
                            </div>
                            <div class="like-count col-3 col-md-2 text-center pl-0 p-lg-0">
                                <span class="reaction-count" *ngIf="comment.reactionsCount"><a
                                        (click)="openUserCommentModal(userModal)">{{comment.reactionsCount}}</a></span>
                                <img class="like-icon" *ngIf="comment?.isLiked && comment.reactionsCount"
                                (click)="openUserCommentModal(userModal)"  [src]="getImagePath('RED_HEART')" />
                                <img class="like-icon" *ngIf="!comment?.isLiked && comment.reactionsCount"
                                (click)="openUserCommentModal(userModal)"  [src]="getImagePath('LIKE_ICON')" />
                            </div>

                        </div>
                        <ng-template #userModal>
                            <app-gift-items *ngIf="comment.subCommentId" [subCommentId]="comment.subCommentId"
                                [commentId]="list.commentId" [showUserNamesRef]="showUserNamesRef" [storyID]="storyID">
                            </app-gift-items>
                        </ng-template>
                    </div>
                </div>
                <ng-template #userModal>
                    <app-gift-items *ngIf="list.commentId" [subCommentId]=" " [commentId]="list.commentId"
                        [showUserNamesRef]="showUserNamesRef" [storyID]="storyID"> </app-gift-items>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="param" [ngClass]="{'comic-page-textarea': isComicPage}" class="reply-input">
        <ng-container *ngTemplateOutlet="replyComment"></ng-container>
    </div>
</div>

<ng-template #modal>
    <div>
        <app-action-popup [modalRef]="modalRef" [ctaList]="ctaList"></app-action-popup>
    </div>
</ng-template>

<ng-template #edit_comment_modal >
    <div class="edit-container">
        <div class="block" *ngIf="showOption" style="border-top-left-radius: 12px;
            border-top-right-radius: 12px;">
            <div class="inner-block" (click)="editComments()">Edit </div>
        </div>
        <div class="block" *ngIf="showOption || isAdmin" [ngStyle]="{'border-top-left-radius':!showOption ? '12px' : '0px',
            'border-top-right-radius':!showOption ? '12px' : '0px'}">
            <div class="inner-block" (click)="deleteComments(commentPopupModal);">
                {{deletedComment}}
            </div>
        </div>
        <div class="block" [ngStyle]="{'border-top-left-radius':!isAdmin ? '12px' : '0px',
            'border-top-right-radius':!isAdmin ? '12px' : '0px'}">
            <div class="inner-block" (click)="copyText(commentInfo.commentText); edit_comment_modalRef.hide()">Copy
            </div>
        </div>
        <!-- <div class="block">
                <div class="inner-block" (click)="edit_comment_modalRef.hide()">Report Comment</div>
            </div> -->
        <div class="block" style="border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            ">
            <div class="inner-block" style="border-bottom: none;" (click)="edit_comment_modalRef.hide();">Cancel</div>
        </div>
    </div>
</ng-template>

<ng-template #commentPopupModal>
    <div class="delete-alert-heading">
        <p>Please confirm.</p>
        <p>Are you sure you want to delete the comment?</p>
    </div>
    <div>
        <div class="btn-container">
            <button class="secondary-btn" aria-label="Open" (click)="comment_popup_modalRef.hide()">
                Cancel
            </button>
            &nbsp;
            <button class="btn" aria-label="Open" (click)="deleteCommentPopup(commentInfo.storyId, comment_id, commentInfo.subCommentId)">
                Delete
            </button>
        </div>
    </div>
</ng-template>

<ng-template #commentModal>
    <div class="action-container">
        <div class="text-center mb-2">
            <p>You need to complete your profile first.</p>
        </div>
        <div>
        <div class="btn-container pb-0">
            <button class="secondary-btn" aria-label="Open" (click)="commentModalRef.hide()">
                Cancel
            </button>
            &nbsp;
            <button class="btn tv-btn-red" aria-label="Open" (click)="goToSignInPage()">
                Edit Profile
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #replyComment>
    <div class="show-username" *ngIf="userName && isAddReply" [ngClass]="{'border-0': isComicPage}">
        <p>Replying to {{userName}}</p>
        <p>&#183;</p>
        <a><p (click)="cancelReply()">Cancel</p></a>
    </div>
    <div class="post-comments" [ngClass]="{'mx-0 mb-0': isComicPage}">
        <div *ngIf="isComicPage || isStoryPage" class="comment-section mr-2 mb-1" [ngClass]="{'mr-3': currentUserPhotoURL}">
            <div class="tv-comments">
                <div [ngClass]="{'img-32': currentUserPhotoURL}">
                    <img *ngIf="currentUserPhotoURL" [src]="getCDNImgPath(currentUserPhotoURL)" class="user-img-comment image-border"
                        [class.ml-0]="isStoryPage" (error)="handleImageError($event)"/>
                    <div *ngIf="!currentUserPhotoURL" class="img-placeholder" [class.ml-0]="isStoryPage">
                        <div class="body-mini">{{getFirstLetters(currentUserName)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="com-1" [ngClass]="{'mr-6': isComicPage}">
            <form [formGroup]="myform">
                <textarea (input)="onInputChange($event)" type="text" [ngClass]="{'text-area': isComicPage}"
                    [placeholder]="placeholder" class="comment-textarea" formControlName="comment" [readonly]="shouldDisabled"></textarea>
            </form>
        </div>
        <div *ngIf="!isComicPage" class="com-2">
            <a (click)="shouldDisabled ? onClick() : postComments(modal, commentModal)" ><img [src]="isTyping ? getImagePath('POST_COMMENT_ICON') : getImagePath('ICONS8_SENT_48')"
                     class="post_comment" [ngStyle]="shouldDisabled && {'filter': 'grayscale(1)' , 'cursor': 'not-allowed'}"   />
                <!-- <div class="loader-bar" *ngIf="isPostComment"></div> -->
            </a>
        </div>
    </div>
    <div *ngIf="isComicPage">
        <div class="send-btn">
            <a (click)="postComments(modal, commentModal)"><img src="https://www.linkpicture.com/q/post_comment_icon_2.png"
                    class="post_comment mt-0" [ngStyle]="shouldDisabled && {'filter': 'grayscale(1)' , 'cursor': 'not-allowed'}" />
                <!-- <div class="loader-bar ml-0 mt-0" *ngIf="isPostComment"></div> -->
            </a>
        </div>
    </div>
</ng-template>
