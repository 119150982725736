import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { getImage } from "src/app/constants/images.constants";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

const DOMAIN_NAME = environment.DOMAIN_NAME;

@Component({
  selector: 'app-email-landing-page',
  templateUrl: './email-landing-page.component.html',
  styleUrls: ['./email-landing-page.component.scss']
})

export class EmailLandingPageComponent {
  currentFlow: string = 'alerts';
  navigationConfig = {
    fail: {
      signup: 'signup/email',
      signin: 'signin/email',
      alerts: 'verification/email'
    },
    success: {
      signup: 'signup/profile',
      signin: 'signin-success',
      alerts: 'manage-alerts'
    }
  }
  emailID: string;
  uuid: string;
  emailLink: string;
  redirectionType: string;
  series: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private platform: object,
    private readonly router: Router,
  ) {
    this.initVerification();
    if (isPlatformBrowser(this.platform)) {
      this.checkForVerifyEmailHistory();
    }
  }

  // To avoid back propagation, to show unsuccessful email-verification screen
  checkForVerifyEmailHistory() {
    if (window) {
      window.history.replaceState({}, "", "");
    }
  }

  public async initVerification() {
    let userID: string = this.activatedRoute.snapshot.queryParamMap.get('uuid');
    let emailLink: string = DOMAIN_NAME + this.router.url;

    if (userID) {
      try {
        const data = await this.authService.emailAuthorization(emailLink, userID);
        this.currentFlow = data.currentFlow;
        this.redirectionType = data.redirectionType;
        this.series = data.series;
        this.navigateTo('success');
      } catch (error) {
        this.emailID = error.email;
        this.currentFlow = error.currentFlow;
        this.navigateTo('fail');
      }
    }
  }
  navigateTo(state: string) {
    const uriSignin = this.navigationConfig[state].signin;
    const uriSignup = this.navigationConfig[state].signup;
    const uriAlerts = this.navigationConfig[state].alerts;

    const email = state === 'fail' ? this.emailID : ''
    switch (this.currentFlow) {
      case 'signin':
        this.router.navigate([uriSignin], { queryParams: { emailVerificationState: state, email, redirectionType: this.redirectionType } });
        break;
      case 'signup':
        this.router.navigate([uriSignup], { queryParams: { emailVerificationState: state, email } });
        break;
      case 'alerts':
      case 'merge':
        const queryParams = { emailVerificationState: state };
        if (this.series.title !== '') queryParams['series'] = JSON.stringify(this.series);
        this.router.navigate([uriAlerts], { queryParams });
        break;
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
