<div class="mx-2 px-2 my-2 py-1 total-stats">
  <div>
    <p class="body-mini">Tinyview Revenue <img [src]="getImagePath('ICONS8_INFORMATION_50')" class="mb-1 ml-1" alt="info" width="16px" height="16px" ngbTooltip="Total revenue for Tinyview" tooltipClass="custom-tooltip-tv-revenue"></p>
    <h1 class="m-0 tinyview-stats">{{tinyViewAggregates.tinyViewRevenue}}</h1>
  </div>
  <div class="separator"></div>
  <div>
    <p class="body-mini">Tinyview Pageviews <img [src]="getImagePath('ICONS8_INFORMATION_50')" class="mb-1 ml-1" alt="info" width="16px" height="16px" ngbTooltip="Total pageviews across Tinyview" tooltipClass="custom-tooltip-tv-pageviews"></p>
    <h1 class="m-0 tinyview-stats">{{tinyViewAggregates.tinyViewPageViews}}</h1>
  </div>
  <div class="separator"></div>
  <div>
    <p class="body-mini">Series Revenue</p>
    <h1 class="m-0">{{tinyViewAggregates.seriesRevenue}}</h1>
  </div>
</div>