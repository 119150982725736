import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth.service';
import { NewComicService } from "../new-comic.service";
import { LocalStorageService } from "../services/local-storage.service";
import { getImage } from "../constants/images.constants";

@Component({
  selector: 'app-creators-dashboard',
  templateUrl: './creators-dashboard.component.html',
  styleUrls: ['./creators-dashboard.component.scss']
})
export class CreatorsDashboardComponent implements AfterViewInit {
  public isMainDashboard: boolean;
  public isTotalsDashboard: boolean;
  public isCreatorDashboard: boolean;
  dashboardLinkText: string;
  isTVAdminOrAnalyst: boolean;
  isReferralDashboard: boolean;
  dashboardMenu = [
    { title: 'Series Dashboard', redirectURL: '/dashboard/creators', toShow: false}
  ];
  dashboardMenuView = [];
  isAnalysts: boolean;
  tinyviewAdmin: boolean;
  isSeriesCreator: boolean;
  isTopReferrer: boolean;
  constructor(
    private readonly router: Router,
    private authService: AuthService,
    private newComicService: NewComicService,
    private localStorageService: LocalStorageService
  ) {
    this.isMainDashboard = this.newComicService.isMainDashboard();
    this.isTotalsDashboard = this.newComicService.isTotalsDashboard();
    this.isCreatorDashboard = this.newComicService.isCreatorDashboard();
    this.isReferralDashboard = this.newComicService.isReferralDashboard();
    this.isTopReferrer = this.newComicService.isTopReferrer();
    this.setUserRoles();
  }

  setUserRoles() {
    this.isAnalysts = this.localStorageService.getItem('isTinyviewAnalyst') == true;
    this.tinyviewAdmin = this.localStorageService.getItem('tinyviewAdmin') == true;
    this.isSeriesCreator = this.localStorageService.getItem('isSeriesCreator') == true;
  }

  ngAfterViewInit() {
    this.setDashboardMenu();
  }
// Note:
//      'Main Dashboard' is exclusive to TVAdmin (or Analysts),
//      'Referral Revenue' is exclusive to Creators,
//      'Series Dashboard' is common to both TVAdmin (or Analysts) and Creators.
  private setDashboardMenu() {
    if (this.dashboardMenu.length === 1) {
      if (this.isSeriesCreator) {
        this.dashboardMenu.push({ title: 'Referral Revenue', redirectURL: '/dashboard/referrals', toShow: !this.isReferralDashboard},)
      }
      if (this.tinyviewAdmin || this.isAnalysts) {
        this.dashboardMenu.unshift({ title: 'Main Dashboard', redirectURL: '/dashboard', toShow: !this.isMainDashboard});
        this.dashboardMenu.push({ title: 'Totals Dashboard', redirectURL: '/dashboard/totals', toShow: !this.isTotalsDashboard});
        this.dashboardMenu.push({ title: 'Top Referrers', redirectURL: '/dashboard/top-referrer', toShow: !this.isTopReferrer},)

      }
    }

    this.dashboardMenu = this.dashboardMenu.filter(item => {
      if (item.title === 'Main Dashboard') {
        item.toShow = !this.isMainDashboard;
      }  
      if (item.title === 'Totals Dashboard') {
        item.toShow = !this.isTotalsDashboard;
      }
      if (item.title === 'Series Dashboard') {
        item.toShow = !this.isCreatorDashboard;
      }
      if (item.title === 'Referral Revenue') {
        item.toShow = !this.isReferralDashboard;
      }
      if (item.title === 'Top Referrers') {
        item.toShow = !this.isTopReferrer;
      }
      return item.toShow === true;
    });
    this.dashboardMenuView = this.dashboardMenu;
  }

  async signOut() {
    await this.authService.signOut();
  }

  public goToHome() {
    this.router.navigate(['']);
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
