import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.scss']
})
export class DataNotFoundComponent implements OnInit {
  urlSubscribe: Subscription;
  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private loggerService: LoggerService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    try {
      const loggerData = {
        fullURL: window.location.href,
        localStorageData: {
          referred_by: this.localStorageService.getItem('referred_by'),
          invite_friends_data: this.localStorageService.getItem('invite_friends_data'),
          utm_data: this.localStorageService.getItem('utm_data'),
          UserVisit: this.localStorageService.getItem('UserVisit'),
          og_image: this.localStorageService.getItem('og_image'),
          finalUrl: this.localStorageService.getItem('finalUrl'),
          userData: this.localStorageService.getItem('userData'),
        }
      };
      this.loggerService.logEvent('404', 'PageNotFound', loggerData, new Date().getTime());
      console.log('404 component logger data', loggerData);
    } catch (error) {
      console.log('404 component error', error);
    }

    this.urlSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (isPlatformBrowser(this.platform)) {
          // window.location.reload(); // it was causing the redirection back to wrong url
        }
      } else if (event instanceof NavigationEnd) {
      }
    });
			
   }

  onNavigate() {
    this.router.navigate(['']).then(()=>{
      // location.reload(); // why it was required?
    })
  }
  ngOnDestroy() {
    if (this.urlSubscribe) {
      this.urlSubscribe.unsubscribe();
    }
  }
}
