import { Component, Input } from "@angular/core";
import { getImage } from "src/app/constants/images.constants";

@Component({
  selector: 'app-page-view-revenue',
  templateUrl: './page-view-revenue.component.html',
  styleUrls: ['./page-view-revenue.component.scss', '../dashboard-stats/dashboard-stats.component.scss']
})

export class PageViewRevenueComponent {
  @Input('tinyViewAggregates') tinyViewAggregates: any;

  getImagePath(icon: string) {
    return getImage(icon);
  }
 }
