import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { API_KEYS } from '../constants/common.constants';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StoryFeedService {
  private comicAction = new Subject<string>();
  private getAllComicsPromise: Promise<any> | null = null;
  private getStoryPromises: Map<string, Promise<any>> = new Map();
  private getComicsPromises: Map<string, Promise<any>> = new Map();
  // private getStoryByIDPromise: Promise<any> | null = null;

  constructor(
    private apiService: ApiService
  ) { }

  emitComicAction(data: string): void {
    this.comicAction.next(data);
  }

  loadComicAction(): Subject<string> {
    return this.comicAction;
  }

  getUserFeed(value: string, count: number): Observable<any> {
    return from(this.apiService.send(API_KEYS.GET_USER_FEED).then(userFeed =>
      userFeed({
        "startAfter": value,
        "records": count
      })
    ));
  }

  async getStory(action: string, image?: string, storyMeta?: any, generateNewResponse?: boolean): Promise<any> {
    if (this.getStoryPromises.has(action) && !generateNewResponse) {
      return this.getStoryPromises.get(action)!;
    }
    // Create a new promise for the API call
    const storyPromise = new Promise(async (resolve, reject) => {
      try {
        const getStory = await this.apiService.send(API_KEYS.GET_STORY_DETAILS);

        const reqData: any = { action };
        if (image) reqData['image'] = image;
        if (storyMeta && storyMeta.series) reqData['series'] = storyMeta.series;
        if (storyMeta) {
          reqData['url'] = storyMeta.url;
          reqData['title'] = storyMeta.title;
          reqData['description'] = storyMeta.description;
          reqData['series'] = storyMeta.series;
        }

        const response = await getStory(reqData); // Make the API call
        resolve(response); // Resolve the promise with the response
      } catch (error) {
        console.error('Error in getStory:', error);
        reject(error); // Reject the promise if an error occurs
      } 
    });

    this.getStoryPromises.set(action, storyPromise);
    // Reset the promise after completion
    setTimeout(() => {
      this.getStoryPromises.clear();
    }, 1000);
    // Return the shared promise
    return storyPromise;
  }


  async getSeriesPageFeeds(value: any, series: any, count: number, onlyUnread = false): Promise<any> {
    if (this.getComicsPromises.has(value)) {
      return this.getComicsPromises.get(value);
    }
    const comicsPromise = new Promise(async (resolve, reject) => {
      try {
        const seriesPageFeeds = await this.apiService.send(API_KEYS.GET_SERIES_COMICS);
        const response = await seriesPageFeeds({
          series: series.replace('/', ''), // Remove slashes from series name
          startAfter: value,
          records: count,
          onlyUnread,
        });
        resolve(response);
      } catch (error) {
        console.error('Error in getSeriesPageFeeds:', error);
      }
    });

    this.getComicsPromises.set(value, comicsPromise);
    // Reset the promise after completion
    setTimeout(() => {
      this.getComicsPromises.clear();
    }, 1000);
    // Return the shared promise
    return comicsPromise;
  }

  async doLikeStory(id: any) {
    const getLikes = await this.apiService.send(API_KEYS.LIKE_STORY);
    return getLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async doDislikeStory(id: any) {
    const getDisLikes = await this.apiService.send(API_KEYS.DISLIKE_STORY);
    return getDisLikes({
      "storyID": id,
      "reactionType": "LIKE"
    })
  }

  async getAllStoryLikes(ids: any) {
    const getAllLikes = await this.apiService.send(API_KEYS.GET_STORY_LIKES);
    return getAllLikes({
      "storyIDs": ids
    })
  }

  getAllComics(ids: any, count: number): Observable<any> {
    if (!this.getAllComicsPromise) {
      // Create a new promise for the API call if not already in progress
      this.getAllComicsPromise = new Promise(async (resolve, reject) => {
        try {
          const getAllComics = await this.apiService.send(API_KEYS.GET_ALL_COMICS);
          const res = await getAllComics({
            "startAfter": ids,
            "records": count,
            "likeInfo": 1
          });
          resolve(res); // Resolve the promise with the API response
        } catch (error) {
          console.error('Error in getAllComics:', error);
          reject(error); // Reject the promise if an error occurs
        }
      });
  
      // Reset the promise after 3 seconds
      setTimeout(() => {
        this.getAllComicsPromise = null;
      }, 1000);
    }
  
    // Convert the promise to an observable and return
    return from(this.getAllComicsPromise);
  }

  public async editStory(editedPanels: any) {
    const editStory = await this.apiService.send(API_KEYS.EDIT_STORY);
    return editStory(editedPanels)
  }

  public async deleteStory(deletedPanels: any) {
    const deleteStory = await this.apiService.send(API_KEYS.DELETE_STORY);
    return deleteStory(deletedPanels)
  }

  public async getFreinds() {
    const getFreinds = await this.apiService.send(API_KEYS.GET_MY_FRIENDS);
    return getFreinds()
  }

  public async getComments(storyId: string, records: number, commentId: string, order = 'asc') {
    const getComment = await this.apiService.send(API_KEYS.FETCH_COMMENTS);
    return getComment({
      "storyID": storyId,
      "records": records,
      "startAfter": commentId,
      "sortingOrder": order
    });
  }

  async doLikeComment(storyID: string, commentID: string, subCommentID: string) {
    const likeComment = await this.apiService.send(API_KEYS.LIKE_COMMENT);
    return likeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async userLikedComments(commentID: string, subCommentID: string, startAfterID?: string) {
    const fetchCommentLikes = await this.apiService.send(API_KEYS.FETCH_COMMENT_LIKES);
    return fetchCommentLikes({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "records": 10,
      "startAfter": startAfterID || ""
    })
  }

  async doDislikeComment(storyID: string, commentID: string, subCommentID: string) {
    const disLikeComment = await this.apiService.send(API_KEYS.DISLIKE_COMMENT);
    return disLikeComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID
    })
  }

  async addComments(storyID: string, commentText: string, commentID: string) {
    const addComments = await this.apiService.send(API_KEYS.ADD_COMMENT);
    return addComments({
      "storyID": storyID,
      "commentText": commentText,
      "commentID": commentID
    })
  }

  async deleteComment(storyID: string, commentID: string, subCommentID: string) {
    const deleteComment = await this.apiService.send(API_KEYS.DELETE_COMMENT);
    return deleteComment({
      "storyID": storyID,
      "commentID": commentID,
      "subCommentID": subCommentID ? subCommentID : null
    })
  }

  async editComment(storyID: string, commentID: string, subCommentID: string, commentText: string) {
    const editComment = await this.apiService.send(API_KEYS.EDIT_COMMENT);
    return editComment({
      "commentID": commentID,
      "subCommentID": subCommentID,
      "storyID": storyID,
      "commentText": commentText
    })
  }

  async getStoryByID(storyID: string): Promise<any> {
    if (this.getStoryPromises.has(storyID)) {
      return this.getStoryPromises.get(storyID)!;
    }
    // Create a new promise for the API call
    const storyPromise = new Promise(async (resolve, reject) => {
      try {
        const getStoryByID = await this.apiService.send(API_KEYS.GET_STORY_DETAILS);
        const response = await getStoryByID({ storyID }); // Make the API call
        resolve(response); // Resolve the promise with the response
      } catch (error) {
        console.error('Error in getStoryByID:', error);
        reject(error); // Reject the promise if an error occurs
      }
    });
    this.getStoryPromises.set(storyID, storyPromise);
    // Reset the promise after completion
    setTimeout(() => {
      this.getStoryPromises.clear();
    }, 1000);
    // Return the shared promise
    return storyPromise
  }

  async getStoryLikes(storyID: string, startAfterID: string, reactionType: string) {
    const getStoryLikes = await this.apiService.send(API_KEYS.FETCH_STORY_LIKES);
    return getStoryLikes({
      "storyID": storyID,
      "records": 100,
      "startAfter": startAfterID,
      "reactionType": reactionType
    })
  }
}
