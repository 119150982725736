import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelNavService {
  // Observable for panel-nav inputs
  private panelNavState = new Subject<any>();
  panelNavState$ = this.panelNavState.asObservable();

  // Method to update panel-nav state
  setPanelNavState(state: any) {
    this.panelNavState.next(state);
  }

  // Observable for the like event
  private likeEvent = new Subject<any>();
  likeEvent$ = this.likeEvent.asObservable();

  // Method to emit the like event
  emitLike(event: any) {
    this.likeEvent.next(event);
  }
}
