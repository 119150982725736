import { Component } from "@angular/core";
import { DailyReferralAdaptor, TopReferrerAdaptor } from "src/app/adapters/daily-creator-data";
import { TOP_REFERRER_DASHBOARD_COLUMNS } from "src/app/constants/common.constants";
import { getImage } from "src/app/constants/images.constants";
import { DashboardService } from "src/app/services/dashboard.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";
import { topReferrerViewTable } from "src/app/utilities/common.util";

interface Filter {
  startDate: string;
  type: string;
};

@Component({
  selector: 'app-top-referrer-dashboard',
  templateUrl: './top-referrer-dashboard.component.html',
  styleUrls: ['./top-referrer-dashboard.component.scss']
})
export class TopReferrerDashboardComponent {
  currentDate = new Date();
  userName: string;
  isLoading = false;
  tableError: string;
  monthlyAggregate = {};
  calculatedFor: string;

  public tableHead = TOP_REFERRER_DASHBOARD_COLUMNS;

  public calculatedReffererData = [];
  calculatedDailyDataView = [];
  monthlyAggregateView: {};
  error: string;
  constructor(
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private readonly userService: UserService
  ) {
    this.getUserDetail();
    this.getTableData();
  }

  private async getUserDetail() {
    const username = await this.userService.getUserDetails();

    this.userName = username && username.data && username.data.data.displayName;
  }

  private getTableData() {
    const fromDate = `${this.currentDate.getFullYear()}-${(this.currentDate.getMonth() + 1).toString().padStart(2, '0')}-01`;
    const filter: Filter = {
      startDate: fromDate,
      type: 'top_influencers'
    };
    this.loadDailyData(filter);
  }
  private setViewData() {
    this.calculatedDailyDataView = [];
    this.monthlyAggregateView = {};
    this.calculatedReffererData.sort((a, b) => b.referralAmt.value - a.referralAmt.value);
    this.calculatedReffererData.forEach(data => {
      const mapped = topReferrerViewTable(data);
      mapped && this.calculatedDailyDataView.push(mapped);
    });
    this.monthlyAggregateView = topReferrerViewTable(this.monthlyAggregate, true);
  }

  public onDataReceived(data: any) {
    const filter: Filter = {
      startDate: data.from,
      type: 'top_influencers'
    };
    this.loadDailyData(filter);
  }

  private loadDailyData(filters) {
    this.monthlyAggregate = {};
    this.calculatedReffererData = [];
    this.tableError = '';
    this.isLoading = true;
    this.dashboardService.getReferrerDashboardData(filters).then( resp => {
      const dataDate = Object.keys(resp.data.monthWiseRevenue)[0];
      const dataForMonth = Object.values(resp.data.monthWiseRevenue)[0];
      const noDataCondition = (dataForMonth && Object.keys(dataForMonth) && !Object.keys(dataForMonth).length) || (dataForMonth && Object.keys(dataForMonth) && !Object.keys(dataForMonth).length) || !dataForMonth || !dataForMonth;
      if (noDataCondition) {
        this.tableError = 'No referrer found for selected month.';
        return this.isLoading = false;
      }
      const referrers = dataForMonth['referrer'];
      for( const [referrerUID, referrerData] of Object.entries(referrers)) {
        const data = referrerData as { displayName: string, totalReferral: number, totalReferralAmount: number };
        const formattedReferrerData = new TopReferrerAdaptor(data);
        this.calculatedReffererData.push(formattedReferrerData);
      }
      const aggregateData = {
        displayName: 'Total',
        totalReferral: dataForMonth['totalReferral'],
        totalReferralAmount: dataForMonth['totalReferralAmount']
      }
      this.monthlyAggregate = new TopReferrerAdaptor(aggregateData);
      // this.calculatedReffererData = this.dashboardService.sortArrayByDate(this.calculatedReffererData);
      this.setViewData();
      this.isLoading = false;
      const dataUntil = this.getDateForData(parseInt(dataDate));
      this.calculatedFor = this.dashboardService.getMessageForDate(dataUntil).displayValue;
    }
    ).catch( err => {
      this.isLoading = false;
      this.error = 'Unable to load top referrers data, please refresh the page!';
    })
  }

  getDateForData(dataDate): number {
    const today = new Date();
    const dataString = new Date(dataDate);
    const month = dataString.getMonth();
    const currentMonth = today.getMonth();

    if (month === currentMonth) {
      const previousDay = new Date(today);
      previousDay.setDate(today.getDate() - 1);
      return previousDay.getTime();
    } else {
      return dataDate;
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
