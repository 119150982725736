<div class="open-in-app py-2" [ngClass]="{'border-rounded pl-3 pr-2': !isTopBanner, 'px-4': isTopBanner}" *ngIf="showBanner">
  <!-- Message -->
  <div class="my-auto mr-auto" *ngIf="bannerDetails.message">
    <div class="toast-text body-mini" [innerHTML]="bannerDetails.message"></div>
  </div>

  <div class="my-auto toast-content" *ngIf="isOpenAppBanner && !bannerDetails.message">
    <img alt="icon" class="tv-icon mr-2" [src]="getImagePath('TINYVIEW_MARK_AND_LOGO')">
  </div>
  <!-- Button -->
  <div class="ml-3 ml-sm-5 text-nowrap ml-auto my-auto" [class.mr-2]="!isTopBanner" *ngIf="bannerDetails.button.action">
    <button class="body-mini ml-2" [ngClass]="{'banner-loader': showLoader, 'mx-2': showLoader}" (click)="onAction(bannerDetails.button.action)">
      <p class="text-white body-mini" *ngIf="!showLoader">{{ bannerDetails.button.text }}</p>
      <div class="loader-bar-btn spinner" *ngIf="showLoader"></div>
    </button>
  <!-- Cross-btn -->
    <a *ngIf="isTopBanner" (click)="closeBanner(bannerDetails.priority)" class="text-white ml-3"><img [src]="getImagePath('ICONS8_DELETE')" class="toast-text-png" alt="x" width="12px" height="12px"></a>
  </div>
</div>