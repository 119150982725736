import { Component, OnInit, TemplateRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { BsModalService } from 'ngx-bootstrap/modal';
import { getImage } from 'src/app/constants/images.constants';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { FriendService } from 'src/app/services/friend.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-see-friends',
  templateUrl: './see-friends.component.html',
  styleUrls: ['./see-friends.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss']
})
export class SeeFriendsComponent implements OnInit {
  isAnonymousUser: boolean;
  isLoadingButton: boolean;
  isInviteCard = true;
  isLoading: boolean;
  friendList = [];
  isSubscribed: boolean;
  userHasComicAccess = false;
  UID: string;
  unfriendRef: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-sm modal-dialog'
  };
  unfriendUserName: string;
  unfriendUID: string;
  whyInviteImage = `${environment.API_END_POINT}/tinyview/app/why-invite-friends.jpg`;
  influenceCards = [];
  inviteSubTitle = {
    nonSubscriber: '',
    subscriber: '',
  }
  isMobile: boolean;
  inviteFriendsRef: any;
  constructor(
    private comicService: ComicService,
    private newComicService: NewComicService,
    private friendService: FriendService,
    private afAuth: AngularFireAuth,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
  ) {
    this.checkSubscriber();
    this.preparePageContent();
  }

  ngOnInit() {
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymousUser = isAnonymousUser();
  }

  private async preparePageContent() {
    await this.checkUserComicAccess();
    if (this.isAnonymousUser === true) {
      this.influenceCards.push({
        title: 'Sign In',
        subTitle: 'Once you sign in, you can access your account from anywhere - app or mobile/desktop browser.',
        image: this.getImagePath('ICONS8_SIGN_IN'),
        btnText: 'Sign In',
        show: this.isAnonymousUser,
        action: 'signIn'
      });
    } else {
      this.isLoading = true;
      this.getFriendList();
    }
    this.influenceCards.push({
      title: 'Invite Friends',
      subTitle: this.isSubscribed ? this.inviteSubTitle.subscriber : this.inviteSubTitle.nonSubscriber,
      image: this.getImagePath('ICONS8_ADD_USER_GROUP_MAN'),
      btnText: 'Invite Friends',
      disabled: false,
      show: false,
      action: 'inviteFriends'
    })
  }

  private checkSubscriber() {
    // CHECKING IF USER IS SUBSCRIBED OR NOT
    let prodID = this.localStorageService.getItem('productID');
    // let isSubscribedUser = false;
    if (prodID) {
      const prodIDs: Array<string> = prodID;
      prodIDs.forEach(pid => {
        if (!pid.toLowerCase().includes('gifts')) this.isSubscribed = true;
      });
    }
  }

  private async getFriendList() {
    this.isLoading = true;
    const currentUserUID = firebase.auth() && firebase.auth().currentUser && firebase.auth().currentUser.uid;
    this.friendList = await this.friendService.getFriendsByUserID(this.UID || currentUserUID);
    this.friendList = this.friendList.map(friend => {
      if (friend && friend.userProfilePic) {
        friend.userProfilePic = this.comicService.getCDNImgUrl(friend.userProfilePic);
      }
      return friend;
    })
    this.isLoading = false;
  }

  openUnfriendConfirmationModal(template: TemplateRef<any>, item: any) {
    this.unfriendUID = item.uid;
    this.unfriendUserName = item.name;
    this.unfriendRef = this.modalService.show(template, this.config);
  }

  closeInviteFriendCard() {
    this.isInviteCard = false;
  }

  async unfriendConfirm(friendUID) {
    this.isLoadingButton = true;
    const res = await this.friendService.removeFriendByFriendID(friendUID);
    if (res.status === '200') {
      location.reload();
    } else {
      location.reload();
    }
  }

  closeUnfriendConfirmationModal() {
    this.unfriendRef.hide();
  }
  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str)
    return firstLetters;
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymousUser ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  async checkUserComicAccess() {
    const accessAllowed = await this.comicService.checkUserComicAccess();
    if (accessAllowed.hasAllComicAccess) {
      this.userHasComicAccess = true;
    } else {
      this.userHasComicAccess = false;
    }
    this.inviteSubTitle = {
      nonSubscriber: `Know when a friend likes or comments on a comic. Invite friends and earn 5 influence points for every invite accepted!`,
      subscriber: 'Know when a friend likes or comments on a comic.',
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
