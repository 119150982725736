import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComicService } from '../services/comic.service';
import { NewComicService } from '../new-comic.service';
import { isAnonymousUser } from '../utilities/common.util';
import { getImage } from '../constants/images.constants';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss']
})
export class InviteFriendsComponent implements OnInit {
  @Input() isSubscribed: any;
  @Input() receiverPhoneNumber?: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter;

  isDesktop: boolean;
  isAndroid: boolean;
  isWindows: boolean;
  shareConfig: ({ title: string; action: string; image: string; androidImage: string; show: boolean; } | { title: string; action: string; image: string; show: boolean; androidImage?: undefined; })[];
  isAnonymous: boolean;
  loggedInUserName: string;
  accessAllowed: boolean;

  constructor(
    private readonly comicService: ComicService,
    private readonly newComicService: NewComicService
  ) { }

  async ngOnInit() {
    this.isDesktop = this.newComicService.isDesktop();
    this.isAndroid = this.newComicService.isAndroid();
    this.isWindows = this.newComicService.isWindows();
    this.shareConfig = this.comicService.getShareItems(this.isDesktop, this.isAndroid, this.isWindows);
    this.isAnonymous = isAnonymousUser();
    this.loggedInUserName = this.comicService.userDetails && this.comicService.userDetails.data && this.comicService.userDetails.data.displayName || '';
    if (this.comicService.homePagePreview.image) {
      this.comicService.setMetaData(this.comicService.homePagePreview);
    } else {
      this.comicService.getNewsFeedChapters('').subscribe(() => {
        this.comicService.setMetaData(this.comicService.homePagePreview);
      });
    }
    await this.checkUserComicAccess();
  }

  public manualShare(share) {
    this.comicService.inviteFriends(share.action, this.loggedInUserName, this.isDesktop, this.isAndroid, this.receiverPhoneNumber);
    this.closeModal.emit();
  }

  async checkUserComicAccess() {
    const accessAllowed = await this.comicService.checkUserComicAccess();
    if (accessAllowed.hasAllComicAccess) {
      this.accessAllowed = true;
      return;
    }
    this.accessAllowed = false;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
