<ng-container *ngIf="!isAnonymous">
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="closeModal.emit()" />
    <div class="heading">
        <h2 class="share-heading">Invite Friends</h2>
        <p class="share-sub-heading" *ngIf="!isSubscribed">
            Know when a friend likes or comments on a comic. Earn 5 influence points for every invite accepted by friends.
        </p>
        <p class="share-sub-heading" *ngIf="isSubscribed">
            See likes and comments of your friends
        </p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let share of shareConfig">
            <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
                <img class="share-img" [src]="share.image">
                <span class="share-title">{{ share.title }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
</ng-container>

<ng-container *ngIf="isAnonymous">
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="closeModal.emit()" />
    <div class="action-container p-3">
        <p class="text-center mb-3">
            <span>You must be signed in to invite friends.</span>
        </p>
        <div class="btn-container pb-0 text-center">
            <button class="btn tv-btn-light mr-2" aria-label="Open" (click)="closeModal.emit()">
                <p>Cancel</p>
            </button>
            <button class="btn tv-btn-red" aria-label="Open" (click)="closeModal.emit()" [routerLink]="['/signin/phone/number']">
                <span>Sign In</span>
            </button>
            &nbsp;
        </div>
    </div>
</ng-container>
